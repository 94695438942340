(function (t) {
// fr
t.add("gender", "Civilit\u00e9", "export", "fr");
t.add("male", "Monsieur", "export", "fr");
t.add("female", "Madame", "export", "fr");
t.add("before_work", "Avant d\u2019aller au travail", "export", "fr");
t.add("lunch", "A la pause d\u00e9jeuner", "export", "fr");
t.add("after_work", "Apr\u00e8s le travail", "export", "fr");
t.add("weekend", "Le week-end", "export", "fr");
t.add("walk", "A pied", "export", "fr");
t.add("bike", "A v\u00e9lo ou en trotinette", "export", "fr");
t.add("public", "En transport en commun", "export", "fr");
t.add("car", "En v\u00e9hicule motoris\u00e9", "export", "fr");
t.add("relax", "Vous d\u00e9tendre", "export", "fr");
t.add("move", "Se bouger", "export", "fr");
t.add("romp", "Se d\u00e9fouler", "export", "fr");
t.add("health", "Entretenir votre sant\u00e9", "export", "fr");
t.add("amuse", "Vous amuser", "export", "fr");
t.add("friends", "Faire des rencontres", "export", "fr");
t.add("development", "Le d\u00e9veloppement physique", "export", "fr");
t.add("colleagues", "Partager un bon moment entre coll\u00e8gues", "export", "fr");
t.add("low", "Faible", "export", "fr");
t.add("high", "Elev\u00e9", "export", "fr");
t.add("never", "Jamais", "export", "fr");
t.add("occasional", "Occasionnel", "export", "fr");
t.add("often", "Souvent", "export", "fr");
t.add("very_often", "Tr\u00e8s souvent", "export", "fr");
t.add("occasionally", "Occasionnel", "export", "fr");
})(Translator);
