(function (t) {
// sl
t.add("This value should be false.", "Vrednost bi morala biti nepravilna (false).", "validators", "sl");
t.add("This value should be true.", "Vrednost bi morala biti pravilna (true).", "validators", "sl");
t.add("This value should be of type {{ type }}.", "Vrednost mora biti naslednjega tipa {{ type }}.", "validators", "sl");
t.add("This value should be blank.", "Vrednost mora biti prazna.", "validators", "sl");
t.add("The value you selected is not a valid choice.", "Vrednost, ki ste jo izbrali, ni veljavna mo\u017enost.", "validators", "sl");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Izbrati morate vsaj {{ limit }} mo\u017enost.|Izbrati morate vsaj {{ limit }} mo\u017enosti.|Izbrati morate vsaj {{ limit }} mo\u017enosti.|Izbrati morate vsaj {{ limit }} mo\u017enosti.", "validators", "sl");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Izberete lahko najve\u010d {{ limit }} mo\u017enost.|Izberete lahko najve\u010d {{ limit }} mo\u017enosti.|Izberete lahko najve\u010d {{ limit }} mo\u017enosti.|Izberete lahko najve\u010d {{ limit }} mo\u017enosti.", "validators", "sl");
t.add("One or more of the given values is invalid.", "Ena ali ve\u010d podanih vrednosti ni veljavnih.", "validators", "sl");
t.add("This field was not expected.", "To polje ni bilo pri\u010dakovati.", "validators", "sl");
t.add("This field is missing.", "To polje manjka.", "validators", "sl");
t.add("This value is not a valid date.", "Ta vrednost ni veljaven datum.", "validators", "sl");
t.add("This value is not a valid datetime.", "Ta vrednost ni veljaven datum in \u010das.", "validators", "sl");
t.add("This value is not a valid email address.", "Ta vrednost ni veljaven e-po\u0161tni naslov.", "validators", "sl");
t.add("The file could not be found.", "Datoteke ni mogo\u010de najti.", "validators", "sl");
t.add("The file is not readable.", "Datoteke ni mogo\u010de prebrati.", "validators", "sl");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Datoteka je prevelika ({{ size }} {{ suffix }}). Najve\u010dja dovoljena velikost je {{ limit }} {{ suffix }}.", "validators", "sl");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Mime tip datoteke je neveljaven ({{ type }}). Dovoljeni mime tipi so {{ types }}.", "validators", "sl");
t.add("This value should be {{ limit }} or less.", "Ta vrednost bi morala biti {{ limit }} ali manj.", "validators", "sl");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Ta vrednost je predolga. Morala bi imeti {{ limit }} znak ali manj.|Ta vrednost je predolga. Morala bi imeti {{ limit }} znaka ali manj.|Ta vrednost je predolga. Morala bi imeti {{ limit }} znake ali manj.|Ta vrednost je predolga. Morala bi imeti {{ limit }} znakov ali manj.", "validators", "sl");
t.add("This value should be {{ limit }} or more.", "Ta vrednost bi morala biti {{ limit }} ali ve\u010d.", "validators", "sl");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Ta vrednost je prekratka. Morala bi imeti {{ limit }} znak ali ve\u010d.|Ta vrednost je prekratka. Morala bi imeti {{ limit }} znaka ali ve\u010d.|Ta vrednost je prekratka. Morala bi imeti {{ limit }} znake ali ve\u010d.|Ta vrednost je prekratka. Morala bi imeti {{ limit }} znakov ali ve\u010d.", "validators", "sl");
t.add("This value should not be blank.", "Ta vrednost ne bi smela biti prazna.", "validators", "sl");
t.add("This value should not be null.", "Ta vrednost ne bi smela biti nedefinirana (null).", "validators", "sl");
t.add("This value should be null.", "Ta vrednost bi morala biti nedefinirana (null).", "validators", "sl");
t.add("This value is not valid.", "Ta vrednost ni veljavna.", "validators", "sl");
t.add("This value is not a valid time.", "Ta vrednost ni veljaven \u010das.", "validators", "sl");
t.add("This value is not a valid URL.", "Ta vrednost ni veljaven URL.", "validators", "sl");
t.add("The two values should be equal.", "Ti dve vrednosti bi morali biti enaki.", "validators", "sl");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Datoteka je prevelika. Najve\u010dja dovoljena velikost je {{ limit }} {{ suffix }}.", "validators", "sl");
t.add("The file is too large.", "Datoteka je prevelika.", "validators", "sl");
t.add("The file could not be uploaded.", "Datoteke ni bilo mogo\u010de nalo\u017eiti.", "validators", "sl");
t.add("This value should be a valid number.", "Ta vrednost bi morala biti veljavna \u0161tevilka.", "validators", "sl");
t.add("This file is not a valid image.", "Ta datoteka ni veljavna slika.", "validators", "sl");
t.add("This is not a valid IP address.", "To ni veljaven IP naslov.", "validators", "sl");
t.add("This value is not a valid language.", "Ta vrednost ni veljaven jezik.", "validators", "sl");
t.add("This value is not a valid locale.", "Ta vrednost ni veljavna lokalnost.", "validators", "sl");
t.add("This value is not a valid country.", "Ta vrednost ni veljavna dr\u017eava.", "validators", "sl");
t.add("This value is already used.", "Ta vrednost je \u017ee uporabljena.", "validators", "sl");
t.add("The size of the image could not be detected.", "Velikosti slike ni bilo mogo\u010de zaznati.", "validators", "sl");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "\u0160irina slike je pre\u0161iroka ({{ width }}px). Najve\u010dja dovoljena \u0161irina je {{ max_width }}px.", "validators", "sl");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "\u0160irina slike je premajhna ({{ width }}px). Najmanj\u0161a predvidena \u0161irina je {{ min_width }}px.", "validators", "sl");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "Vi\u0161ina slike je prevelika ({{ height }}px). Najve\u010dja dovoljena vi\u0161ina je {{ max_height }}px.", "validators", "sl");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "Vi\u0161ina slike je premajhna ({{ height }}px). Najmanj\u0161a predvidena vi\u0161ina je {{ min_height }}px.", "validators", "sl");
t.add("This value should be the user's current password.", "Ta vrednost bi morala biti trenutno uporabnikovo geslo.", "validators", "sl");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Ta vrednost bi morala imeti to\u010dno {{ limit }} znak.|Ta vrednost bi morala imeti to\u010dno {{ limit }} znaka.|Ta vrednost bi morala imeti to\u010dno {{ limit }} znake.|Ta vrednost bi morala imeti to\u010dno {{ limit }} znakov.", "validators", "sl");
t.add("The file was only partially uploaded.", "Datoteka je bila le delno nalo\u017eena.", "validators", "sl");
t.add("No file was uploaded.", "Nobena datoteka ni bila nalo\u017eena.", "validators", "sl");
t.add("No temporary folder was configured in php.ini.", "Za\u010dasna mapa ni nastavljena v php.ini.", "validators", "sl");
t.add("Cannot write temporary file to disk.", "Za\u010dasne datoteke ni bilo mogo\u010de zapisati na disk.", "validators", "sl");
t.add("A PHP extension caused the upload to fail.", "PHP raz\u0161iritev je vzrok, da nalaganje ni uspelo.", "validators", "sl");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Ta zbirka bi morala vsebovati {{ limit }} element ali ve\u010d.|Ta zbirka bi morala vsebovati {{ limit }} elementa ali ve\u010d.|Ta zbirka bi morala vsebovati {{ limit }} elemente ali ve\u010d.|Ta zbirka bi morala vsebovati {{ limit }} elementov ali ve\u010d.", "validators", "sl");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Ta zbirka bi morala vsebovati {{ limit }} element ali manj.|Ta zbirka bi morala vsebovati {{ limit }} elementa ali manj.|Ta zbirka bi morala vsebovati {{ limit }} elemente ali manj.|Ta zbirka bi morala vsebovati {{ limit }} elementov ali manj.", "validators", "sl");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Ta zbirka bi morala vsebovati to\u010dno {{ limit }} element.|Ta zbirka bi morala vsebovati to\u010dno {{ limit }} elementa.|Ta zbirka bi morala vsebovati to\u010dno {{ limit }} elemente.|Ta zbirka bi morala vsebovati to\u010dno {{ limit }} elementov.", "validators", "sl");
t.add("Invalid card number.", "Neveljavna \u0161tevilka kartice.", "validators", "sl");
t.add("Unsupported card type or invalid card number.", "Nepodprti tip kartice ali neveljavna \u0161tevilka kartice.", "validators", "sl");
t.add("This is not a valid International Bank Account Number (IBAN).", "To ni veljavna mednarodna \u0161tevilka ban\u010dnega ra\u010duna (IBAN).", "validators", "sl");
t.add("This value is not a valid ISBN-10.", "Neveljavna vrednost po ISBN-10.", "validators", "sl");
t.add("This value is not a valid ISBN-13.", "Neveljavna vrednost po ISBN-13.", "validators", "sl");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Neveljavna vrednost po ISBN-10 ali po ISBN-13.", "validators", "sl");
t.add("This value is not a valid ISSN.", "Neveljavna vrednost ISSN.", "validators", "sl");
t.add("This value is not a valid currency.", "Ta vrednost ni veljavna valuta.", "validators", "sl");
t.add("This value should be equal to {{ compared_value }}.", "Ta vrednost bi morala biti enaka {{ compared_value }}.", "validators", "sl");
t.add("This value should be greater than {{ compared_value }}.", "Ta vrednost bi morala biti ve\u010dja od {{ compared_value }}.", "validators", "sl");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Ta vrednost bi morala biti ve\u010dja ali enaka {{ compared_value }}.", "validators", "sl");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Ta vrednost bi morala biti identi\u010dna {{ compared_value_type }} {{ compared_value }}.", "validators", "sl");
t.add("This value should be less than {{ compared_value }}.", "Ta vrednost bi morala biti manj\u0161a od {{ compared_value }}.", "validators", "sl");
t.add("This value should be less than or equal to {{ compared_value }}.", "Ta vrednost bi morala biti manj\u0161a ali enaka {{ compared_value }}.", "validators", "sl");
t.add("This value should not be equal to {{ compared_value }}.", "Ta vrednost ne bi smela biti enaka {{ compared_value }}.", "validators", "sl");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Ta vrednost ne bi smela biti identi\u010dna {{ compared_value_type }} {{ compared_value }}.", "validators", "sl");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "Razmerje slike je preveliko ({{ ratio }}). Najve\u010dje dovoljeno razmerje je {{ max_ratio }}.", "validators", "sl");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "Razmerje slike je premajhno ({{ ratio }}). Najmanj\u0161e pri\u010dakovano razmerje je {{ min_ratio }}.", "validators", "sl");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "Slika je kvadrat ({{ width }}x{{ height }}px). Kvadratne slike niso dovoljene.", "validators", "sl");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "Slika je le\u017ee\u010de usmerjena ({{ width }}x{{ height }}px). Le\u017ee\u010de usmerjene slike niso dovoljene.", "validators", "sl");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "Slika je pokon\u010dno usmerjena ({{ width }}x{{ height }}px). Pokon\u010dno usmerjene slike niso dovoljene.", "validators", "sl");
t.add("An empty file is not allowed.", "Prazna datoteka ni dovoljena.", "validators", "sl");
t.add("The host could not be resolved.", "Gostitelja ni bilo mogo\u010de prepoznati.", "validators", "sl");
t.add("This value does not match the expected {{ charset }} charset.", "Ta vrednost se ne ujema s pri\u010dakovanim naborom znakov {{ charset }}.", "validators", "sl");
t.add("This is not a valid Business Identifier Code (BIC).", "To ni veljavna identifikacijska koda podjetja (BIC).", "validators", "sl");
t.add("Error", "Napaka", "validators", "sl");
t.add("This form should not contain extra fields.", "Ta obrazec ne sme vsebovati dodatnih polj.", "validators", "sl");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Nalo\u017eena datoteka je prevelika. Prosimo, poizkusite nalo\u017eiti manj\u0161o.", "validators", "sl");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "CSRF vrednost je napa\u010dna. Prosimo, ponovno po\u0161ljite obrazec.", "validators", "sl");
})(Translator);
