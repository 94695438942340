(function (t) {
// uk
t.add("This value should be false.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043f\u043e\u0432\u0438\u043d\u043d\u043e \u0431\u0443\u0442\u0438 \u041d\u0456.", "validators", "uk");
t.add("This value should be true.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043f\u043e\u0432\u0438\u043d\u043d\u043e \u0431\u0443\u0442\u0438 \u0422\u0430\u043a.", "validators", "uk");
t.add("This value should be of type {{ type }}.", "\u0422\u0438\u043f \u0437\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043f\u043e\u0432\u0438\u043d\u0435\u043d \u0431\u0443\u0442\u0438 {{ type }}.", "validators", "uk");
t.add("This value should be blank.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043f\u043e\u0432\u0438\u043d\u043d\u043e \u0431\u0443\u0442\u0438 \u043f\u0443\u0441\u0442\u0438\u043c.", "validators", "uk");
t.add("The value you selected is not a valid choice.", "\u041e\u0431\u0440\u0430\u043d\u0435 \u0432\u0430\u043c\u0438 \u0437\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043d\u0435\u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u0435.", "validators", "uk");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "\u0412\u0438 \u043f\u043e\u0432\u0438\u043d\u043d\u0456 \u043e\u0431\u0440\u0430\u0442\u0438 \u0445\u043e\u0447\u0430 \u0431 {{ limit }} \u0432\u0430\u0440\u0456\u0430\u043d\u0442.|\u0412\u0438 \u043f\u043e\u0432\u0438\u043d\u043d\u0456 \u043e\u0431\u0440\u0430\u0442\u0438 \u0445\u043e\u0447\u0430 \u0431 {{ limit }} \u0432\u0430\u0440\u0456\u0430\u043d\u0442\u0438.|\u0412\u0438 \u043f\u043e\u0432\u0438\u043d\u043d\u0456 \u043e\u0431\u0440\u0430\u0442\u0438 \u0445\u043e\u0447\u0430 \u0431 {{ limit }} \u0432\u0430\u0440\u0456\u0430\u043d\u0442\u0456\u0432.", "validators", "uk");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "\u0412\u0438 \u043f\u043e\u0432\u0438\u043d\u043d\u0456 \u043e\u0431\u0440\u0430\u0442\u0438 \u043d\u0435 \u0431\u0456\u043b\u044c\u0448\u0435 \u043d\u0456\u0436 {{ limit }} \u0432\u0430\u0440\u0456\u0430\u043d\u0442\u0456\u0432.", "validators", "uk");
t.add("One or more of the given values is invalid.", "\u041e\u0434\u043d\u0435 \u0430\u0431\u043e \u043a\u0456\u043b\u044c\u043a\u0430 \u0437\u0430\u0434\u0430\u043d\u0438\u0445 \u0437\u043d\u0430\u0447\u0435\u043d\u044c \u0454 \u043d\u0435\u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u0456.", "validators", "uk");
t.add("This field was not expected.", "\u0426\u0435 \u043f\u043e\u043b\u0435 \u043d\u0435 \u043e\u0447\u0456\u043a\u0443\u0454\u0442\u044c\u0441\u044f.", "validators", "uk");
t.add("This field is missing.", "\u0426\u0435 \u043f\u043e\u043b\u0435 \u043d\u0435 \u0432\u0438\u0441\u0442\u0430\u0447\u0430\u0454.", "validators", "uk");
t.add("This value is not a valid date.", "\u0414\u0430\u043d\u0435 \u0437\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043d\u0435 \u0454 \u0432\u0456\u0440\u043d\u043e\u044e \u0434\u0430\u0442\u043e\u044e.", "validators", "uk");
t.add("This value is not a valid datetime.", "\u0414\u0430\u043d\u0435 \u0437\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u0434\u0430\u0442\u0438 \u0442\u0430 \u0447\u0430\u0441\u0443 \u043d\u0435\u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u0435.", "validators", "uk");
t.add("This value is not a valid email address.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u0430\u0434\u0440\u0435\u0441\u0438 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u043e\u0457 \u043f\u043e\u0448\u0442\u0438 \u043d\u0435\u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u0435.", "validators", "uk");
t.add("The file could not be found.", "\u0424\u0430\u0439\u043b \u043d\u0435 \u0437\u043d\u0430\u0439\u0434\u0435\u043d\u043e.", "validators", "uk");
t.add("The file is not readable.", "\u0424\u0430\u0439\u043b \u043d\u0435 \u0447\u0438\u0442\u0430\u0454\u0442\u044c\u0441\u044f.", "validators", "uk");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "\u0424\u0430\u0439\u043b \u0437\u0430\u043d\u0430\u0434\u0442\u043e \u0432\u0435\u043b\u0438\u043a\u0438\u0439 ({{ size }} {{ suffix }}). \u0414\u043e\u0437\u0432\u043e\u043b\u0435\u043d\u0438\u0439 \u043c\u0430\u043a\u0441\u0438\u043c\u0430\u043b\u044c\u043d\u0438\u0439 \u0440\u043e\u0437\u043c\u0456\u0440 {{ limit }} {{ suffix }}.", "validators", "uk");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "MIME-\u0442\u0438\u043f \u0444\u0430\u0439\u043b\u0443 \u043d\u0435\u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u0438\u0439 ({{ type }}). \u0414\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u0456 MIME-\u0442\u0438\u043f\u0438 \u0444\u0430\u0439\u043b\u0456\u0432 {{ types }}.", "validators", "uk");
t.add("This value should be {{ limit }} or less.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043f\u043e\u0432\u0438\u043d\u043d\u043e \u0431\u0443\u0442\u0438 {{ limit }} \u0430\u0431\u043e \u043c\u0435\u043d\u0448\u0435.", "validators", "uk");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u0437\u0430\u043d\u0430\u0434\u0442\u043e \u0434\u043e\u0432\u0433\u0435. \u041f\u043e\u0432\u0438\u043d\u043d\u043e \u0431\u0443\u0442\u0438 \u0440\u0456\u0432\u043d\u0435 {{ limit }} \u0441\u0438\u043c\u0432\u043e\u043b\u0443 \u0430\u0431\u043e \u043c\u0435\u043d\u0448\u0435.|\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u0437\u0430\u043d\u0430\u0434\u0442\u043e \u0434\u043e\u0432\u0433\u0435. \u041f\u043e\u0432\u0438\u043d\u043d\u043e \u0431\u0443\u0442\u0438 \u0440\u0456\u0432\u043d\u0435 {{ limit }} \u0441\u0438\u043c\u0432\u043e\u043b\u0430\u043c \u0430\u0431\u043e \u043c\u0435\u043d\u0448\u0435.|\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u0437\u0430\u043d\u0430\u0434\u0442\u043e \u0434\u043e\u0432\u0433\u0435. \u041f\u043e\u0432\u0438\u043d\u043d\u043e \u0431\u0443\u0442\u0438 \u0440\u0456\u0432\u043d\u0435 {{ limit }} \u0441\u0438\u043c\u0432\u043e\u043b\u0430\u043c \u0430\u0431\u043e \u043c\u0435\u043d\u0448\u0435.", "validators", "uk");
t.add("This value should be {{ limit }} or more.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043f\u043e\u0432\u0438\u043d\u043d\u043e \u0431\u0443\u0442\u0438 {{ limit }} \u0430\u0431\u043e \u0431\u0456\u043b\u044c\u0448\u0435.", "validators", "uk");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u0437\u0430\u043d\u0430\u0434\u0442\u043e \u043a\u043e\u0440\u043e\u0442\u043a\u0435. \u041f\u043e\u0432\u0438\u043d\u043d\u043e \u0431\u0443\u0442\u0438 \u0440\u0456\u0432\u043d\u0435 {{ limit }} \u0441\u0438\u043c\u0432\u043e\u043b\u0443 \u0430\u0431\u043e \u0431\u0456\u043b\u044c\u0448\u0435.|\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u0437\u0430\u043d\u0430\u0434\u0442\u043e \u043a\u043e\u0440\u043e\u0442\u043a\u0435. \u041f\u043e\u0432\u0438\u043d\u043d\u043e \u0431\u0443\u0442\u0438 \u0440\u0456\u0432\u043d\u0435 {{ limit }} \u0441\u0438\u043c\u0432\u043e\u043b\u0430\u043c \u0430\u0431\u043e \u0431\u0456\u043b\u044c\u0448\u0435.|\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u0437\u0430\u043d\u0430\u0434\u0442\u043e \u043a\u043e\u0440\u043e\u0442\u043a\u0435. \u041f\u043e\u0432\u0438\u043d\u043d\u043e \u0431\u0443\u0442\u0438 \u0440\u0456\u0432\u043d\u0435 {{ limit }} \u0441\u0438\u043c\u0432\u043e\u043b\u0430\u043c \u0430\u0431\u043e \u0431\u0456\u043b\u044c\u0448\u0435.", "validators", "uk");
t.add("This value should not be blank.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043d\u0435 \u043f\u043e\u0432\u0438\u043d\u043d\u043e \u0431\u0443\u0442\u0438 \u043f\u0443\u0441\u0442\u0438\u043c.", "validators", "uk");
t.add("This value should not be null.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043d\u0435 \u043f\u043e\u0432\u0438\u043d\u043d\u043e \u0431\u0443\u0442\u0438 null.", "validators", "uk");
t.add("This value should be null.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043f\u043e\u0432\u0438\u043d\u043d\u043e \u0431\u0443\u0442\u0438 null.", "validators", "uk");
t.add("This value is not valid.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043d\u0435\u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u0435.", "validators", "uk");
t.add("This value is not a valid time.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u0447\u0430\u0441\u0443 \u043d\u0435\u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u0435.", "validators", "uk");
t.add("This value is not a valid URL.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f URL \u043d\u0435\u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u0435.", "validators", "uk");
t.add("The two values should be equal.", "\u041e\u0431\u0438\u0434\u0432\u0430 \u0437\u0430\u043d\u0447\u0435\u043d\u043d\u044f \u043f\u043e\u0432\u0438\u043d\u043d\u0456 \u0431\u0443\u0442\u0438 \u043e\u0434\u0438\u043d\u0430\u043a\u043e\u0432\u0438\u043c\u0438.", "validators", "uk");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "\u0424\u0430\u0439\u043b \u0437\u0430\u043d\u0430\u0434\u0442\u043e \u0432\u0435\u043b\u0438\u043a\u0438\u0439. \u041c\u0430\u043a\u0441\u0438\u043c\u0430\u043b\u044c\u043d\u0438\u0439 \u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u0438\u0439 \u0440\u043e\u0437\u043c\u0456\u0440 {{ limit }} {{ suffix }}.", "validators", "uk");
t.add("The file is too large.", "\u0424\u0430\u0439\u043b \u0437\u0430\u043d\u0430\u0434\u0442\u043e \u0432\u0435\u043b\u0438\u043a\u0438\u0439.", "validators", "uk");
t.add("The file could not be uploaded.", "\u0424\u0430\u0439\u043b \u043d\u0435 \u043c\u043e\u0436\u043b\u0438\u0432\u043e \u0437\u0430\u0432\u0430\u043d\u0442\u0430\u0436\u0438\u0442\u0438.", "validators", "uk");
t.add("This value should be a valid number.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043c\u0430\u0454 \u0431\u0443\u0442\u0438 \u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u0438\u043c \u0447\u0438\u0441\u043b\u043e\u043c.", "validators", "uk");
t.add("This file is not a valid image.", "\u0426\u0435\u0439 \u0444\u0430\u0439\u043b \u043d\u0435 \u0454 \u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u0438\u043c \u0444\u043e\u0440\u043c\u0430\u0442\u043e\u043c \u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u043d\u044f.", "validators", "uk");
t.add("This is not a valid IP address.", "\u0426\u0435 \u043d\u0435\u043a\u043e\u0440\u0435\u043a\u0442\u043d\u0430 IP \u0430\u0434\u0440\u0435\u0441\u0430.", "validators", "uk");
t.add("This value is not a valid language.", "\u0426\u0435 \u043d\u0435\u043a\u043e\u0440\u0435\u043a\u0442\u043d\u0430 \u043c\u043e\u0432\u0430.", "validators", "uk");
t.add("This value is not a valid locale.", "\u0426\u0435 \u043d\u0435\u043a\u043e\u0440\u0435\u043a\u0442\u043d\u0430 \u043b\u043e\u043a\u0430\u043b\u0456\u0437\u0430\u0446\u0456\u044f.", "validators", "uk");
t.add("This value is not a valid country.", "\u0426\u0435 \u043d\u0435\u043a\u043e\u0440\u0435\u043a\u0442\u043d\u0430 \u043a\u0440\u0430\u0457\u043d\u0430.", "validators", "uk");
t.add("This value is already used.", "\u0426\u0435 \u0437\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u0432\u0436\u0435 \u0432\u0438\u043a\u043e\u0440\u0438\u0441\u0442\u043e\u0432\u0443\u0454\u0442\u044c\u0441\u044f.", "validators", "uk");
t.add("The size of the image could not be detected.", "\u041d\u0435 \u0432\u0434\u0430\u043b\u043e\u0441\u044f \u0432\u0438\u0437\u043d\u0430\u0447\u0438\u0442\u0438 \u0440\u043e\u0437\u043c\u0456\u0440 \u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u043d\u044f.", "validators", "uk");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "\u0428\u0438\u0440\u0438\u043d\u0430 \u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u043d\u044f \u0437\u0430\u043d\u0430\u0434\u0442\u043e \u0432\u0435\u043b\u0438\u043a\u0430 ({{ width }}px). \u041c\u0430\u043a\u0441\u0438\u043c\u0430\u043b\u044c\u043d\u043e \u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u0430 \u0448\u0438\u0440\u0438\u043d\u0430 {{ max_width }}px.", "validators", "uk");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "\u0428\u0438\u0440\u0438\u043d\u0430 \u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u043d\u044f \u0437\u0430\u043d\u0430\u0434\u0442\u043e \u043c\u0430\u043b\u0430 ({{ width }}px). \u041c\u0456\u043d\u0456\u043c\u0430\u043b\u044c\u043d\u043e \u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u0430 \u0448\u0438\u0440\u0438\u043d\u0430 {{ min_width }}px.", "validators", "uk");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "\u0412\u0438\u0441\u043e\u0442\u0430 \u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u043d\u044f \u0437\u0430\u043d\u0430\u0434\u0442\u043e \u0432\u0435\u043b\u0438\u043a\u0430 ({{ height }}px). \u041c\u0430\u043a\u0441\u0438\u043c\u0430\u043b\u044c\u043d\u043e \u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u0430 \u0432\u0438\u0441\u043e\u0442\u0430 {{ max_height }}px.", "validators", "uk");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "\u0412\u0438\u0441\u043e\u0442\u0430 \u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u043d\u044f \u0437\u0430\u043d\u0430\u0434\u0442\u043e \u043c\u0430\u043b\u0430 ({{ height }}px). \u041c\u0456\u043d\u0456\u043c\u0430\u043b\u044c\u043d\u043e \u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u0430 \u0432\u0438\u0441\u043e\u0442\u0430 {{ min_height }}px.", "validators", "uk");
t.add("This value should be the user's current password.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043c\u0430\u0454 \u0431\u0443\u0442\u0438 \u043f\u043e\u0442\u043e\u0447\u043d\u0438\u043c \u043f\u0430\u0440\u043e\u043b\u0435\u043c \u043a\u043e\u0440\u0438\u0441\u0442\u0443\u0432\u0430\u0447\u0430.", "validators", "uk");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043f\u043e\u0432\u0438\u0438\u043d\u043e \u0431\u0443\u0442\u0438 \u0440\u0456\u0432\u043d\u0438\u043c {{ limit }} \u0441\u0438\u043c\u0432\u043e\u043b\u0443.|\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043f\u043e\u0432\u0438\u0438\u043d\u043e \u0431\u0443\u0442\u0438 \u0440\u0456\u0432\u043d\u0438\u043c {{ limit }} \u0441\u0438\u043c\u0432\u043e\u043b\u0430\u043c.|\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043f\u043e\u0432\u0438\u0438\u043d\u043e \u0431\u0443\u0442\u0438 \u0440\u0456\u0432\u043d\u0438\u043c {{ limit }} \u0441\u0438\u043c\u0432\u043e\u043b\u0430\u043c.", "validators", "uk");
t.add("The file was only partially uploaded.", "\u0424\u0430\u0439\u043b \u0431\u0443\u0432 \u0437\u0430\u0432\u0430\u043d\u0442\u0430\u0436\u0435\u043d\u0438\u0439 \u043b\u0438\u0448\u0435 \u0447\u0430\u0441\u0442\u043a\u043e\u0432\u043e.", "validators", "uk");
t.add("No file was uploaded.", "\u0424\u0430\u0439\u043b \u043d\u0435 \u0431\u0443\u0432 \u0437\u0430\u0432\u0430\u043d\u0442\u0430\u0436\u0435\u043d\u0438\u0439.", "validators", "uk");
t.add("No temporary folder was configured in php.ini.", "\u041d\u0435 \u043d\u0430\u043b\u0430\u0448\u0442\u043e\u0432\u0430\u043d\u0430 \u0442\u0438\u043c\u0447\u0430\u0441\u043e\u0432\u0430 \u0434\u0438\u0440\u0435\u043a\u0442\u043e\u0440\u0456\u044f \u0432 php.ini.", "validators", "uk");
t.add("Cannot write temporary file to disk.", "\u041d\u0435\u043c\u043e\u0436\u043b\u0438\u0432\u043e \u0437\u0430\u043f\u0438\u0441\u0430\u0442\u0438 \u0442\u0438\u043c\u0447\u0430\u0441\u043e\u0432\u0438\u0439 \u0444\u0430\u0439\u043b \u043d\u0430 \u0434\u0438\u0441\u043a.", "validators", "uk");
t.add("A PHP extension caused the upload to fail.", "\u0420\u043e\u0437\u0448\u0438\u0440\u0435\u043d\u043d\u044f PHP \u0432\u0438\u043a\u043b\u0438\u043a\u0430\u043b\u043e \u043f\u043e\u043c\u0438\u043b\u043a\u0443 \u043f\u0440\u0438 \u0437\u0430\u0432\u0430\u043d\u0442\u0430\u0436\u0435\u043d\u043d\u0456.", "validators", "uk");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "\u0426\u044f \u043a\u043e\u043b\u0435\u043a\u0446\u0456\u044f \u043f\u043e\u0432\u0438\u043d\u043d\u0430 \u043c\u0456\u0441\u0442\u0438\u0442\u0438 {{ limit }} \u0435\u043b\u0435\u043c\u0435\u043d\u0442 \u0447\u0438 \u0431\u0456\u043b\u044c\u0448\u0435.|\u0426\u044f \u043a\u043e\u043b\u0435\u043a\u0446\u0456\u044f \u043f\u043e\u0432\u0438\u043d\u043d\u0430 \u043c\u0456\u0441\u0442\u0438\u0442\u0438 {{ limit }} \u0435\u043b\u0435\u043c\u0435\u043d\u0442\u0430 \u0447\u0438 \u0431\u0456\u043b\u044c\u0448\u0435.|\u0426\u044f \u043a\u043e\u043b\u0435\u043a\u0446\u0456\u044f \u043f\u043e\u0432\u0438\u043d\u043d\u0430 \u043c\u0456\u0441\u0442\u0438\u0442\u0438 {{ limit }} \u0435\u043b\u0435\u043c\u0435\u043d\u0442\u0456\u0432 \u0447\u0438 \u0431\u0456\u043b\u044c\u0448\u0435.", "validators", "uk");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "\u0426\u044f \u043a\u043e\u043b\u0435\u043a\u0446\u0456\u044f \u043f\u043e\u0432\u0438\u043d\u043d\u0430 \u043c\u0456\u0441\u0442\u0438\u0442\u0438 {{ limit }} \u0435\u043b\u0435\u043c\u0435\u043d\u0442 \u0447\u0438 \u043c\u0435\u043d\u0448\u0435.|\u0426\u044f \u043a\u043e\u043b\u0435\u043a\u0446\u0456\u044f \u043f\u043e\u0432\u0438\u043d\u043d\u0430 \u043c\u0456\u0441\u0442\u0438\u0442\u0438 {{ limit }} \u0435\u043b\u0435\u043c\u0435\u043d\u0442\u0430 \u0447\u0438 \u043c\u0435\u043d\u0448\u0435.|\u0426\u044f \u043a\u043e\u043b\u0435\u043a\u0446\u0456\u044f \u043f\u043e\u0432\u0438\u043d\u043d\u0430 \u043c\u0456\u0441\u0442\u0438\u0442\u0438 {{ limit }} \u0435\u043b\u0435\u043c\u0435\u043d\u0442\u043e\u0432 \u0447\u0438 \u043c\u0435\u043d\u0448\u0435.", "validators", "uk");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "\u0426\u044f \u043a\u043e\u043b\u0435\u043a\u0446\u0456\u044f \u043f\u043e\u0432\u0438\u043d\u043d\u0430 \u043c\u0456\u0441\u0442\u0438\u0442\u0438 \u0440\u0456\u0432\u043d\u043e {{ limit }} \u0435\u043b\u0435\u043c\u0435\u043d\u0442.|\u0426\u044f \u043a\u043e\u043b\u0435\u043a\u0446\u0456\u044f \u043f\u043e\u0432\u0438\u043d\u043d\u0430 \u043c\u0456\u0441\u0442\u0438\u0442\u0438 \u0440\u0456\u0432\u043d\u043e {{ limit }} \u0435\u043b\u0435\u043c\u0435\u043d\u0442\u0430.|\u0426\u044f \u043a\u043e\u043b\u0435\u043a\u0446\u0456\u044f \u043f\u043e\u0432\u0438\u043d\u043d\u0430 \u043c\u0456\u0441\u0442\u0438\u0442\u0438 \u0440\u0456\u0432\u043d\u043e {{ limit }} \u0435\u043b\u0435\u043c\u0435\u043d\u0442\u0456\u0432.", "validators", "uk");
t.add("Invalid card number.", "\u041d\u0435\u0432\u0456\u0440\u043d\u0438\u0439 \u043d\u043e\u043c\u0435\u0440 \u043a\u0430\u0440\u0442\u0438.", "validators", "uk");
t.add("Unsupported card type or invalid card number.", "\u041d\u0435\u043f\u0456\u0434\u0442\u0440\u0438\u043c\u0443\u0432\u0430\u043d\u0438\u0439 \u0442\u0438\u043f \u043a\u0430\u0440\u0442\u0438 \u0430\u0431\u043e \u043d\u0435\u0432\u0456\u0440\u043d\u0438\u0439 \u043d\u043e\u043c\u0435\u0440 \u043a\u0430\u0440\u0442\u0438.", "validators", "uk");
t.add("This is not a valid International Bank Account Number (IBAN).", "\u0426\u0435 \u043d\u0435 \u0434\u0456\u0439\u0441\u043d\u0438\u0439 \u043c\u0456\u0436\u043d\u0430\u0440\u043e\u0434\u043d\u0438\u0439 \u043d\u043e\u043c\u0435\u0440 \u0431\u0430\u043d\u043a\u0456\u0432\u0441\u044c\u043a\u043e\u0433\u043e \u0440\u0430\u0445\u0443\u043d\u043a\u0443 (IBAN).", "validators", "uk");
t.add("This value is not a valid ISBN-10.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043d\u0435 \u0443 \u0444\u043e\u0440\u043c\u0430\u0442\u0456 ISBN-10.", "validators", "uk");
t.add("This value is not a valid ISBN-13.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043d\u0435 \u0443 \u0444\u043e\u0440\u043c\u0430\u0442\u0456 ISBN-13.", "validators", "uk");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043d\u0435 \u0432\u0456\u0434\u043f\u043e\u0432\u0456\u0434\u0430\u0454 \u0444\u043e\u0440\u043c\u0430\u0442\u0430\u043c ISBN-10 \u0442\u0430 ISBN-13.", "validators", "uk");
t.add("This value is not a valid ISSN.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043c\u0430\u0454 \u043d\u0435\u0432\u0456\u0440\u043d\u0438\u0439 \u0444\u043e\u0440\u043c\u0430\u0442 ISSN.", "validators", "uk");
t.add("This value is not a valid currency.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043c\u0430\u0454 \u043d\u0435\u0432\u0456\u0440\u043d\u0438\u0439 \u0444\u043e\u0440\u043c\u0430\u0442 \u0432\u0430\u043b\u044e\u0442\u0438.", "validators", "uk");
t.add("This value should be equal to {{ compared_value }}.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043f\u043e\u0432\u0438\u043d\u043d\u043e \u0434\u043e\u0440\u0456\u0432\u043d\u044e\u0432\u0430\u0442\u0438 {{ compared_value }}.", "validators", "uk");
t.add("This value should be greater than {{ compared_value }}.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043c\u0430\u0454 \u0431\u0443\u0442\u0438 \u0431\u0456\u043b\u044c\u0448\u0435 \u043d\u0456\u0436 {{ compared_value }}.", "validators", "uk");
t.add("This value should be greater than or equal to {{ compared_value }}.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043c\u0430\u0454 \u0431\u0443\u0442\u0438 \u0431\u0456\u043b\u044c\u0448\u0435 \u0430\u0431\u043e \u0434\u043e\u0440\u0456\u0432\u043d\u044e\u0432\u0430\u0442\u0438 {{ compared_value }}.", "validators", "uk");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043c\u0430\u0454 \u0431\u0443\u0442\u0438 \u0456\u0434\u0435\u043d\u0442\u0438\u0447\u043d\u0438\u043c {{ compared_value_type }} {{ compared_value }}.", "validators", "uk");
t.add("This value should be less than {{ compared_value }}.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043f\u043e\u0432\u0438\u043d\u043d\u043e \u0431\u0443\u0442\u0438 \u043c\u0435\u043d\u0448\u0435 \u043d\u0456\u0436 {{ compared_value }}.", "validators", "uk");
t.add("This value should be less than or equal to {{ compared_value }}.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043f\u043e\u0432\u0438\u043d\u043d\u043e \u0431\u0443\u0442\u0438 \u043c\u0435\u043d\u0448\u0435 \u0430\u0431\u043e \u0434\u043e\u0440\u0456\u0432\u043d\u044e\u0432\u0430\u0442\u0438 {{ compared_value }}.", "validators", "uk");
t.add("This value should not be equal to {{ compared_value }}.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043d\u0435 \u043f\u043e\u0432\u0438\u043d\u043d\u043e \u0434\u043e\u0440\u0456\u0432\u043d\u044e\u0432\u0430\u0442\u0438  {{ compared_value }}.", "validators", "uk");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043d\u0435 \u043f\u043e\u0432\u0438\u043d\u043d\u043e \u0431\u0443\u0442\u0438 \u0456\u0434\u0435\u043d\u0442\u0438\u0447\u043d\u0438\u043c {{ compared_value_type }} {{ compared_value }}.", "validators", "uk");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "\u0421\u043f\u0456\u0432\u0432\u0456\u0434\u043d\u043e\u0448\u0435\u043d\u043d\u044f \u0441\u0442\u043e\u0440\u0456\u043d \u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u043d\u044f \u0437\u0430\u043d\u0430\u0434\u0442\u043e \u0432\u0435\u043b\u0438\u043a\u0435 ({{ ratio }}). \u041c\u0430\u043a\u0441\u0438\u043c\u0430\u043b\u044c\u043d\u0435 \u0441\u043f\u0456\u0432\u0432\u0456\u0434\u043d\u043e\u0448\u0435\u043d\u043d\u044f \u0441\u0442\u043e\u0440\u0456\u043d {{ max_ratio }}.", "validators", "uk");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "\u0421\u043f\u0456\u0432\u0432\u0456\u0434\u043d\u043e\u0448\u0435\u043d\u043d\u044f \u0441\u0442\u043e\u0440\u0456\u043d \u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u043d\u044f \u0437\u0430\u043d\u0430\u0434\u0442\u043e \u043c\u0430\u043b\u043e ({{ ratio }}). \u041c\u0456\u043d\u0456\u043c\u0430\u043b\u044c\u043d\u0435 \u0441\u043f\u0456\u0432\u0432\u0456\u0434\u043d\u043e\u0448\u0435\u043d\u043d\u044f \u0441\u0442\u043e\u0440\u0456\u043d {{ min_ratio }}.", "validators", "uk");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "\u0417\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u043d\u044f \u043a\u0432\u0430\u0434\u0440\u0430\u0442\u043d\u0435 ({{ width }}x{{ height }}px). \u041a\u0432\u0430\u0434\u0440\u0430\u0442\u043d\u0456 \u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u043d\u044f \u043d\u0435 \u0434\u043e\u0437\u0432\u043e\u043b\u0435\u043d\u0456.", "validators", "uk");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "\u0417\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u043d\u044f \u0430\u043b\u044c\u0431\u043e\u043c\u043d\u043e\u0457 \u043e\u0440\u0456\u0454\u043d\u0442\u0430\u0446\u0456\u0457 ({{ width }}x{{ height }}px). \u0417\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u043d\u044f \u0430\u043b\u044c\u0431\u043e\u043c\u043d\u043e\u0457 \u043e\u0440\u0456\u0454\u043d\u0442\u0430\u0446\u0456\u0457 \u043d\u0435 \u0434\u043e\u0437\u0432\u043e\u043b\u0435\u043d\u0456.", "validators", "uk");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "\u0417\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u043d\u044f \u0432 \u043f\u043e\u0440\u0442\u0440\u0435\u0442\u043d\u0456\u0439 \u043e\u0440\u0456\u0454\u043d\u0442\u0430\u0446\u0456\u0457 ({{ width }}x{{ height }}px). \u0417\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u043d\u044f \u0432 \u043f\u043e\u0440\u0442\u0440\u0435\u0442\u043d\u043e\u0457 \u043e\u0440\u0456\u0454\u043d\u0442\u0430\u0446\u0456\u0457 \u043d\u0435 \u0434\u043e\u0437\u0432\u043e\u043b\u0435\u043d\u0456.", "validators", "uk");
t.add("An empty file is not allowed.", "\u041f\u043e\u0440\u043e\u0436\u043d\u0456 \u0444\u0430\u0439\u043b\u0438 \u043d\u0435 \u0434\u043e\u0437\u0432\u043e\u043b\u0435\u043d\u0456.", "validators", "uk");
t.add("The host could not be resolved.", "\u0406\u043c'\u044f \u0445\u043e\u0441\u0442\u0430 \u043d\u0435 \u0437\u043d\u0430\u0439\u0434\u0435\u043d\u043e.", "validators", "uk");
t.add("This value does not match the expected {{ charset }} charset.", "\u0417\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043d\u0435 \u0437\u0431\u0456\u0433\u0430\u0454\u0442\u044c\u0441\u044f \u0437 \u043e\u0447\u0456\u043a\u0443\u0432\u0430\u043d\u0438\u043c {{ charset }} \u043a\u043e\u0434\u0443\u0432\u0430\u043d\u043d\u044f\u043c.", "validators", "uk");
t.add("This is not a valid Business Identifier Code (BIC).", "\u0426\u0435 \u043d\u0435 \u0434\u0456\u0439\u0441\u043d\u0438\u0439 \u0431\u0430\u043d\u043a\u0456\u0432\u0441\u044c\u043a\u0438\u0439 \u043a\u043e\u0434 (BIC).", "validators", "uk");
t.add("Error", "\u041f\u043e\u043c\u0438\u043b\u043a\u0430", "validators", "uk");
t.add("This form should not contain extra fields.", "\u0426\u044f \u0444\u043e\u0440\u043c\u0430 \u043d\u0435 \u043f\u043e\u0432\u0438\u043d\u043d\u0430 \u043c\u0456\u0441\u0442\u0438\u0442\u0438 \u0434\u043e\u0434\u0430\u0442\u043a\u043e\u0432\u0438\u0445 \u043f\u043e\u043b\u0456\u0432.", "validators", "uk");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "\u0417\u0430\u0432\u0430\u043d\u0442\u0430\u0436\u0435\u043d\u0438\u0439 \u0444\u0430\u0439\u043b \u0437\u0430\u043d\u0430\u0434\u0442\u043e \u0432\u0435\u043b\u0438\u043a\u0438\u0439. \u0411\u0443\u0434\u044c-\u043b\u0430\u0441\u043a\u0430, \u0441\u043f\u0440\u043e\u0431\u0443\u0439\u0442\u0435 \u0437\u0430\u0432\u0430\u043d\u0442\u0430\u0436\u0438\u0442\u0438 \u0444\u0430\u0439\u043b \u043c\u0435\u043d\u0448\u043e\u0433\u043e \u0440\u043e\u0437\u043c\u0456\u0440\u0443.", "validators", "uk");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "CSRF \u0437\u043d\u0430\u0447\u0435\u043d\u043d\u044f \u043d\u0435\u0434\u043e\u043f\u0443\u0441\u0442\u0438\u043c\u0435. \u0411\u0443\u0434\u044c-\u043b\u0430\u0441\u043a\u0430, \u0441\u043f\u0440\u043e\u0431\u0443\u0439\u0442\u0435 \u0432\u0456\u0434\u043f\u0440\u0430\u0432\u0438\u0442\u0438 \u0444\u043e\u0440\u043c\u0443 \u0437\u043d\u043e\u0432\u0443.", "validators", "uk");
})(Translator);
