(function (t) {
// ro
t.add("This value should be false.", "Aceast\u0103 valoare ar trebui s\u0103 fie fals\u0103 (false).", "validators", "ro");
t.add("This value should be true.", "Aceast\u0103 valoare ar trebui s\u0103 fie adev\u0103rat\u0103 (true).", "validators", "ro");
t.add("This value should be of type {{ type }}.", "Aceast\u0103 valoare ar trebui s\u0103 fie de tipul {{ type }}.", "validators", "ro");
t.add("This value should be blank.", "Aceast\u0103 valoare ar trebui sa fie goal\u0103.", "validators", "ro");
t.add("The value you selected is not a valid choice.", "Valoarea selectat\u0103 nu este o op\u021biune valid\u0103.", "validators", "ro");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Trebuie s\u0103 selecta\u021bi cel pu\u021bin {{ limit }} op\u021biune.|Trebuie s\u0103 selecta\u021bi cel pu\u021bin {{ limit }} op\u021biuni.|Trebuie s\u0103 selecta\u021bi cel pu\u021bin {{ limit }} de op\u021biuni", "validators", "ro");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Trebuie s\u0103 selecta\u021bi cel mult {{ limit }} op\u021biune.|Trebuie s\u0103 selecta\u021bi cel mult {{ limit }} op\u021biuni.|Trebuie s\u0103 selecta\u021bi cel mult {{ limit }} de op\u021biuni.", "validators", "ro");
t.add("One or more of the given values is invalid.", "Una sau mai multe dintre valorile furnizate sunt invalide.", "validators", "ro");
t.add("This field was not expected.", "Acest c\u00e2mp nu era de a\u015fteptat.", "validators", "ro");
t.add("This field is missing.", "Acest c\u00e2mp este lips\u0103.", "validators", "ro");
t.add("This value is not a valid date.", "Aceast\u0103 valoare nu reprezint\u0103 o dat\u0103 valid\u0103.", "validators", "ro");
t.add("This value is not a valid datetime.", "Aceast\u0103 valoare nu reprezint\u0103 o dat\u0103 \u0219i or\u0103 valid\u0103.", "validators", "ro");
t.add("This value is not a valid email address.", "Aceast\u0103 valoare nu reprezint\u0103 o adres\u0103 de e-mail valid\u0103.", "validators", "ro");
t.add("The file could not be found.", "Fi\u0219ierul nu a putut fi g\u0103sit.", "validators", "ro");
t.add("The file is not readable.", "Fi\u0219ierul nu poate fi citit.", "validators", "ro");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Fi\u0219ierul este prea mare ({{ size }} {{ suffix }}). Dimensiunea maxim\u0103 permis\u0103 este {{ limit }} {{ suffix }}.", "validators", "ro");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Tipul fi\u0219ierului este invalid ({{ type }}). Tipurile permise de fi\u0219iere sunt ({{ types }}).", "validators", "ro");
t.add("This value should be {{ limit }} or less.", "Aceast\u0103 valoare ar trebui s\u0103 fie cel mult {{ limit }}.", "validators", "ro");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Aceast\u0103 valoare este prea lung\u0103. Ar trebui s\u0103 aib\u0103 maxim {{ limit }} caracter.|Aceast\u0103 valoare este prea lung\u0103. Ar trebui s\u0103 aib\u0103 maxim {{ limit }} caractere.|Aceast\u0103 valoare este prea lung\u0103. Ar trebui s\u0103 aib\u0103 maxim {{ limit }} de caractere.", "validators", "ro");
t.add("This value should be {{ limit }} or more.", "Aceast\u0103 valoare ar trebui s\u0103 fie cel pu\u021bin {{ limit }}.", "validators", "ro");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Aceast\u0103 valoare este prea scurt\u0103. Ar trebui s\u0103 aib\u0103 minim {{ limit }} caracter.|Aceast\u0103 valoare este prea scurt\u0103. Ar trebui s\u0103 aib\u0103 minim {{ limit }} caractere.|Aceast\u0103 valoare este prea scurt\u0103. Ar trebui s\u0103 aib\u0103 minim {{ limit }} de caractere.", "validators", "ro");
t.add("This value should not be blank.", "Aceast\u0103 valoare nu ar trebui s\u0103 fie goal\u0103.", "validators", "ro");
t.add("This value should not be null.", "Aceast\u0103 valoare nu ar trebui s\u0103 fie nul\u0103 (null).", "validators", "ro");
t.add("This value should be null.", "Aceast\u0103 valoare ar trebui s\u0103 fie nul\u0103 (null).", "validators", "ro");
t.add("This value is not valid.", "Aceast\u0103 valoare nu este valid\u0103.", "validators", "ro");
t.add("This value is not a valid time.", "Aceast\u0103 valoare nu reprezint\u0103 o or\u0103 valid\u0103.", "validators", "ro");
t.add("This value is not a valid URL.", "Aceast\u0103 valoare nu reprezint\u0103 un URL (link) valid.", "validators", "ro");
t.add("The two values should be equal.", "Cele dou\u0103 valori ar trebui s\u0103 fie egale.", "validators", "ro");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Fi\u0219ierul este prea mare. M\u0103rimea maxim\u0103 permis\u0103 este {{ limit }} {{ suffix }}.", "validators", "ro");
t.add("The file is too large.", "Fi\u0219ierul este prea mare.", "validators", "ro");
t.add("The file could not be uploaded.", "Fi\u0219ierul nu a putut fi \u00eenc\u0103rcat.", "validators", "ro");
t.add("This value should be a valid number.", "Aceast\u0103 valoare nu reprezint\u0103 un num\u0103r valid.", "validators", "ro");
t.add("This file is not a valid image.", "Acest fi\u0219ier nu este o imagine valid\u0103.", "validators", "ro");
t.add("This is not a valid IP address.", "Aceast\u0103 valoare nu este o adres\u0103 IP valid\u0103.", "validators", "ro");
t.add("This value is not a valid language.", "Aceast\u0103 valoare nu reprezint\u0103 o limb\u0103 corect\u0103.", "validators", "ro");
t.add("This value is not a valid locale.", "Aceast\u0103 valoare nu reprezint\u0103 un dialect (o limb\u0103) corect.", "validators", "ro");
t.add("This value is not a valid country.", "Aceast\u0103 valoare nu este o \u021bar\u0103 valid\u0103.", "validators", "ro");
t.add("This value is already used.", "Aceast\u0103 valoare este folosit\u0103 deja.", "validators", "ro");
t.add("The size of the image could not be detected.", "M\u0103rimea imaginii nu a putut fi detectat\u0103.", "validators", "ro");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "L\u0103\u021bimea imaginii este prea mare ({{ width }}px). L\u0103\u021bimea maxim\u0103 permis\u0103 este de {{ max_width }}px.", "validators", "ro");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "L\u0103\u021bimea imaginii este prea mic\u0103 ({{ width }}px). L\u0103\u021bimea minim\u0103 permis\u0103 este de {{ min_width }}px.", "validators", "ro");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "\u00cen\u0103l\u021bimea imaginii este prea mare ({{ height }}px). \u00cen\u0103l\u021bimea maxim\u0103 permis\u0103 este de {{ max_height }}px.", "validators", "ro");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "\u00cen\u0103l\u021bimea imaginii este prea mic\u0103 ({{ height }}px). \u00cen\u0103l\u021bimea minim\u0103 permis\u0103 este de {{ min_height }}px.", "validators", "ro");
t.add("This value should be the user's current password.", "Aceast\u0103 valoare trebuie s\u0103 fie parola curent\u0103 a utilizatorului.", "validators", "ro");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Aceast\u0103 valoare trebuie s\u0103 con\u021bin\u0103 exact {{ limit }} caracter.|Aceast\u0103 valoare trebuie s\u0103 con\u021bin\u0103 exact {{ limit }} caractere.|Aceast\u0103 valoare trebuie s\u0103 con\u021bin\u0103 exact {{ limit }} de caractere.", "validators", "ro");
t.add("The file was only partially uploaded.", "Fi\u0219ierul a fost \u00eenc\u0103rcat par\u021bial.", "validators", "ro");
t.add("No file was uploaded.", "Nu a fost \u00eenc\u0103rcat nici un fi\u0219ier.", "validators", "ro");
t.add("No temporary folder was configured in php.ini.", "Nu este configurat nici un director temporar in php.ini.", "validators", "ro");
t.add("Cannot write temporary file to disk.", "Nu a fost posibil\u0103 scrierea fi\u0219ierului temporar pe disk.", "validators", "ro");
t.add("A PHP extension caused the upload to fail.", "O extensie PHP a prevenit \u00eenc\u0103rcarea cu succes a fi\u0219ierului.", "validators", "ro");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Aceast\u0103 colec\u021bie trebuie s\u0103 con\u021bin\u0103 cel pu\u021bin {{ limit }} element.|Aceast\u0103 colec\u021bie trebuie s\u0103 con\u021bin\u0103 cel pu\u021bin {{ limit }} elemente.|Aceast\u0103 colec\u021bie trebuie s\u0103 con\u021bin\u0103 cel pu\u021bin {{ limit }} de elemente.", "validators", "ro");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Aceast\u0103 colec\u021bie trebuie s\u0103 con\u021bin\u0103 cel mult {{ limit }} element.|Aceast\u0103 colec\u021bie trebuie s\u0103 con\u021bin\u0103 cel mult {{ limit }} elemente.|Aceast\u0103 colec\u021bie trebuie s\u0103 con\u021bin\u0103 cel mult {{ limit }} de elemente.", "validators", "ro");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Aceast\u0103 colec\u021bie trebuie s\u0103 con\u021bin\u0103 {{ limit }} element.|Aceast\u0103 colec\u021bie trebuie s\u0103 con\u021bin\u0103 {{ limit }} elemente.|Aceast\u0103 colec\u021bie trebuie s\u0103 con\u021bin\u0103 {{ limit }} de elemente.", "validators", "ro");
t.add("Invalid card number.", "Num\u0103rul card invalid.", "validators", "ro");
t.add("Unsupported card type or invalid card number.", "Tipul sau num\u0103rul cardului nu sunt valide.", "validators", "ro");
t.add("This is not a valid International Bank Account Number (IBAN).", "Acesta nu este un cod IBAN (International Bank Account Number) valid.", "validators", "ro");
t.add("This value is not a valid ISBN-10.", "Aceast\u0103 valoare nu este un cod ISBN-10 valid.", "validators", "ro");
t.add("This value is not a valid ISBN-13.", "Aceast\u0103 valoare nu este un cod ISBN-13 valid.", "validators", "ro");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Aceast\u0103 valoare nu este un cod ISBN-10 sau ISBN-13 valid.", "validators", "ro");
t.add("This value is not a valid ISSN.", "Aceast\u0103 valoare nu este un cod ISSN valid.", "validators", "ro");
t.add("This value is not a valid currency.", "Aceast\u0103 valoare nu este o moned\u0103 valid\u0103.", "validators", "ro");
t.add("This value should be equal to {{ compared_value }}.", "Aceast\u0103 valoare trebuie s\u0103 fie egal\u0103 cu {{ compared_value }}.", "validators", "ro");
t.add("This value should be greater than {{ compared_value }}.", "Aceast\u0103 valoare trebuie s\u0103 fie mai mare de {{ compared_value }}.", "validators", "ro");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Aceast\u0103 valoare trebuie s\u0103 fie mai mare sau egal\u0103 cu {{ compared_value }}.", "validators", "ro");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Aceast\u0103 valoare trebuie identic\u0103 cu {{ compared_value_type }} {{ compared_value }}.", "validators", "ro");
t.add("This value should be less than {{ compared_value }}.", "Aceast\u0103 valoare trebuie s\u0103 fie mai mic\u0103 de {{ compared_value }}.", "validators", "ro");
t.add("This value should be less than or equal to {{ compared_value }}.", "Aceast\u0103 valoare trebuie s\u0103 fie mai mic\u0103 sau egal\u0103 cu {{ compared_value }}.", "validators", "ro");
t.add("This value should not be equal to {{ compared_value }}.", "Aceast\u0103 valoare nu trebuie s\u0103 fie egal\u0103 cu {{ compared_value }}.", "validators", "ro");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Aceast\u0103 valoare nu trebuie s\u0103 fie identic\u0103 cu {{ compared_value_type }} {{ compared_value }}.", "validators", "ro");
t.add("Error", "Eroare", "validators", "ro");
t.add("This form should not contain extra fields.", "Aceast formular nu ar trebui s\u0103 con\u021bin\u0103 c\u00e2mpuri suplimentare.", "validators", "ro");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Fi\u0219ierul \u00eenc\u0103rcat a fost prea mare. V\u0103 rug\u0103m sa \u00eenc\u0103rca\u021bi un fi\u0219ier mai mic.", "validators", "ro");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "Token-ul CSRF este invalid. V\u0103 rug\u0103m s\u0103 trimite\u021bi formularul inc\u0103 o dat\u0103.", "validators", "ro");
})(Translator);
