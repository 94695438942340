(function (t) {
// lb
t.add("This value should be false.", "D\u00ebse W\u00e4ert sollt falsch sinn.", "validators", "lb");
t.add("This value should be true.", "D\u00ebse W\u00e4ert sollt wouer sinn.", "validators", "lb");
t.add("This value should be of type {{ type }}.", "D\u00ebse W\u00e4ert sollt vum Typ {{ type }} sinn.", "validators", "lb");
t.add("This value should be blank.", "D\u00ebse W\u00e4ert sollt eidel sinn.", "validators", "lb");
t.add("The value you selected is not a valid choice.", "D\u00ebse W\u00e4ert sollt enger vun de Wielm\u00e9iglechkeeten entspriechen.", "validators", "lb");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Et muss mindestens {{ limit }} M\u00e9iglechkeet ausgewielt ginn.|Et musse mindestens {{ limit }} M\u00e9iglechkeeten ausgewielt ginn.", "validators", "lb");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Et dierf h\u00e9chstens {{ limit }} M\u00e9iglechkeet ausgewielt ginn.|Et dierfen h\u00e9chstens {{ limit }} M\u00e9iglechkeeten ausgewielt ginn.", "validators", "lb");
t.add("One or more of the given values is invalid.", "Een oder m\u00e9i vun de W\u00e4erter ass ong\u00eblteg.", "validators", "lb");
t.add("The fields {{ fields }} were not expected.", "D'Felder {{ fields }} goufen net erwaart.", "validators", "lb");
t.add("The fields {{ fields }} are missing.", "D'Felder {{ fields }} feelen.", "validators", "lb");
t.add("This value is not a valid date.", "D\u00ebse W\u00e4ert entspr\u00e9cht kenger g\u00eblteger Datumsangab.", "validators", "lb");
t.add("This value is not a valid datetime.", "D\u00ebse W\u00e4ert entspr\u00e9cht kenger g\u00eblteger Datums- an Z\u00e4itangab.", "validators", "lb");
t.add("This value is not a valid email address.", "D\u00ebse W\u00e4ert ass keng g\u00eblteg Email-Adress.", "validators", "lb");
t.add("The file could not be found.", "De Fichier gouf net fonnt.", "validators", "lb");
t.add("The file is not readable.", "De Fichier ass net liesbar.", "validators", "lb");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "De Fichier ass ze grouss ({{ size }} {{ suffix }}). D\u00e9i zougeloosse Maximalgr\u00e9isst bedr\u00e9it {{ limit }} {{ suffix }}.", "validators", "lb");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Den Typ vum Fichier ass ong\u00eblteg ({{ type }}). Erlaabten Type sinn {{ types }}.", "validators", "lb");
t.add("This value should be {{ limit }} or less.", "D\u00ebse W\u00e4ert soll m\u00e9i kleng oder gl\u00e4ich {{ limit }} sinn.", "validators", "lb");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "D\u00ebs Zeecheketten ass ze laang. Se sollt h\u00e9chstens {{ limit }} Zeechen hunn.", "validators", "lb");
t.add("This value should be {{ limit }} or more.", "D\u00ebse W\u00e4ert sollt m\u00e9i grouss oder gl\u00e4ich {{ limit }} sinn.", "validators", "lb");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "D\u00ebs Zeecheketten ass ze kuerz. Se sollt mindestens {{ limit }} Zeechen hunn.", "validators", "lb");
t.add("This value should not be blank.", "D\u00ebse W\u00e4ert sollt net eidel sinn.", "validators", "lb");
t.add("This value should not be null.", "D\u00ebst sollt keen Null-W\u00e4ert sinn.", "validators", "lb");
t.add("This value should be null.", "D\u00ebst sollt keen Null-W\u00e4ert sinn.", "validators", "lb");
t.add("This value is not valid.", "D\u00ebse W\u00e4ert ass net g\u00eblteg.", "validators", "lb");
t.add("This value is not a valid time.", "D\u00ebse W\u00e4ert entspr\u00e9cht kenger g\u00eblteger Z\u00e4itangab.", "validators", "lb");
t.add("This value is not a valid URL.", "D\u00ebse W\u00e4ert ass keng g\u00eblteg URL.", "validators", "lb");
t.add("The two values should be equal.", "B\u00e9id W\u00e4erter sollten identesch sinn.", "validators", "lb");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "De fichier ass ze grouss. D\u00e9i maximal Gr\u00e9isst dierf {{ limit }} {{ suffix }} net depass\u00e9ieren.", "validators", "lb");
t.add("The file is too large.", "De Fichier ass ze grouss.", "validators", "lb");
t.add("The file could not be uploaded.", "De Fichier konnt net eropgeluede ginn.", "validators", "lb");
t.add("This value should be a valid number.", "D\u00ebse W\u00e4ert sollt eng g\u00eblteg Zuel sinn.", "validators", "lb");
t.add("This file is not a valid image.", "D\u00ebse Fichier ass kee g\u00ebltegt Bild.", "validators", "lb");
t.add("This is not a valid IP address.", "D\u00ebst ass keng g\u00eblteg IP-Adress.", "validators", "lb");
t.add("This value is not a valid language.", "D\u00ebse W\u00e4ert entspr\u00e9cht kenger g\u00eblteger Sprooch.", "validators", "lb");
t.add("This value is not a valid locale.", "D\u00ebse W\u00e4ert entspr\u00e9cht kengem g\u00ebltege Gebittsschema.", "validators", "lb");
t.add("This value is not a valid country.", "D\u00ebse W\u00e4ert entspr\u00e9cht kengem g\u00ebltege Land.", "validators", "lb");
t.add("This value is already used.", "D\u00ebse W\u00e4ert g\u00ebtt scho benotzt.", "validators", "lb");
t.add("The size of the image could not be detected.", "D'Gr\u00e9isst vum Bild konnt net detekt\u00e9iert ginn.", "validators", "lb");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "D'Breet vum Bild ass ze grouss ({{ width }}px). D\u00e9i erlaabte maximal Breet ass {{ max_width }}px.", "validators", "lb");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "D'Breet vum Bild ass ze kleng ({{ width }}px). D\u00e9i minimal Breet ass {{ min_width }}px.", "validators", "lb");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "D'H\u00e9icht vum Bild ass ze grouss ({{ height }}px). D\u00e9i erlaabte maximal H\u00e9icht ass {{ max_height }}px.", "validators", "lb");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "D'H\u00e9icht vum Bild ass ze kleng ({{ height }}px). D\u00e9i minimal H\u00e9icht ass {{ min_height }}px.", "validators", "lb");
t.add("This value should be the user's current password.", "D\u00ebse W\u00e4ert sollt dem aktuelle Benotzerpasswuert entspriechen.", "validators", "lb");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "D\u00ebse W\u00e4ert sollt exakt {{ limit }} Buschtaf hunn.|D\u00ebse W\u00e4ert sollt exakt {{ limit }} Buschtawen hunn.", "validators", "lb");
t.add("The file was only partially uploaded.", "De Fichier gouf just deelweis eropgelueden.", "validators", "lb");
t.add("No file was uploaded.", "Et gouf kee Fichier eropgelueden.", "validators", "lb");
t.add("No temporary folder was configured in php.ini.", "Et gouf keen tempor\u00e4ren Dossier an der php.ini konfigur\u00e9iert oder den tempor\u00e4ren Dossier exist\u00e9iert net.", "validators", "lb");
t.add("Cannot write temporary file to disk.", "Den tempor\u00e4re Fichier kann net gesp\u00e4ichert ginn.", "validators", "lb");
t.add("A PHP extension caused the upload to fail.", "Eng PHP-Erweiderung huet den Upload verh\u00ebnnert.", "validators", "lb");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "D\u00ebs Sammlung sollt {{ limit }} oder m\u00e9i Elementer hunn.", "validators", "lb");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "D\u00ebs Sammlung sollt {{ limit }} oder manner Elementer hunn.", "validators", "lb");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "D\u00ebs Sammlung sollt exakt {{ limit }} Element hunn.|D\u00ebs Sammlung sollt exakt {{ limit }} Elementer hunn.", "validators", "lb");
t.add("Invalid card number.", "Ong\u00eblteg Kaartennummer.", "validators", "lb");
t.add("Unsupported card type or invalid card number.", "Net \u00ebnnerst\u00ebtzte Kaartentyp oder ong\u00eblteg Kaartennummer.", "validators", "lb");
t.add("This is not a valid International Bank Account Number (IBAN).", "D\u00ebst ass keng g\u00eblteg IBAN-Kontonummer.", "validators", "lb");
t.add("This value is not a valid ISBN-10.", "D\u00ebse W\u00e4ert ass keng g\u00eblteg ISBN-10.", "validators", "lb");
t.add("This value is not a valid ISBN-13.", "D\u00ebse W\u00e4ert ass keng g\u00eblteg ISBN-13.", "validators", "lb");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "D\u00ebse W\u00e4ert ass weder eng g\u00eblteg ISBN-10 nach eng g\u00eblteg ISBN-13.", "validators", "lb");
t.add("This value is not a valid ISSN.", "D\u00ebse W\u00e4ert ass keng g\u00eblteg ISSN.", "validators", "lb");
t.add("This value is not a valid currency.", "D\u00ebse W\u00e4ert ass keng g\u00eblteg W\u00e4hrung.", "validators", "lb");
t.add("This value should be equal to {{ compared_value }}.", "D\u00ebse W\u00e4ert sollt {{ compared_value }} sinn.", "validators", "lb");
t.add("This value should be greater than {{ compared_value }}.", "D\u00ebse W\u00e4ert sollt m\u00e9i grouss w\u00e9i {{ compared_value }} sinn.", "validators", "lb");
t.add("This value should be greater than or equal to {{ compared_value }}.", "D\u00ebse W\u00e4ert sollt m\u00e9i grouss w\u00e9i oder gl\u00e4ich {{ compared_value }} sinn.", "validators", "lb");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "D\u00ebse W\u00e4ert sollt identesch si mat {{ compared_value_type }} {{ compared_value }}.", "validators", "lb");
t.add("This value should be less than {{ compared_value }}.", "D\u00ebse W\u00e4ert sollt m\u00e9i kleng w\u00e9i {{ compared_value }} sinn.", "validators", "lb");
t.add("This value should be less than or equal to {{ compared_value }}.", "D\u00ebse W\u00e4ert sollt m\u00e9i kleng w\u00e9i oder gl\u00e4ich {{ compared_value }} sinn.", "validators", "lb");
t.add("This value should not be equal to {{ compared_value }}.", "D\u00ebse W\u00e4ert sollt net {{ compared_value }} sinn.", "validators", "lb");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "D\u00ebse W\u00e4ert sollt net identesch si mat {{ compared_value_type }} {{ compared_value }}.", "validators", "lb");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "D'S\u00e4iteverh\u00e4ltnis vum Bild ass ze grouss ({{ ratio }}). Den erlaabte Maximalw\u00e4ert ass {{ max_ratio }}.", "validators", "lb");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "D'S\u00e4iteverh\u00e4ltnis vum Bild ass ze kleng ({{ ratio }}). Den erwaarte Minimalw\u00e4ert ass {{ min_ratio }}.", "validators", "lb");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "D'Bild ass quadratesch ({{ width }}x{{ height }}px). Quadratesch Biller sinn net erlaabt.", "validators", "lb");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "D'Bild ass am Queeschformat ({{ width }}x{{ height }}px). Biller am Queeschformat sinn net erlaabt.", "validators", "lb");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "D'Bild ass am H\u00e9ichformat ({{ width }}x{{ height }}px). Biller am H\u00e9ichformat sinn net erlaabt.", "validators", "lb");
t.add("An empty file is not allowed.", "En eidele Fichier ass net erlaabt.", "validators", "lb");
t.add("The host could not be resolved.", "Den Host-Numm konnt net opgel\u00e9ist ginn.", "validators", "lb");
t.add("This value does not match the expected {{ charset }} charset.", "D\u00ebse W\u00e4ert entspr\u00e9cht net dem erwaarten Zeechesaz {{ charset }}.", "validators", "lb");
t.add("This is not a valid Business Identifier Code (BIC).", "D\u00ebst ass kee g\u00ebltege \"Business Identifier Code\" (BIC).", "validators", "lb");
t.add("Error", "Feeler", "validators", "lb");
t.add("This is not a valid UUID.", "D\u00ebst ass keng g\u00eblteg UUID.", "validators", "lb");
t.add("This form should not contain extra fields.", "D\u00ebs Feldergrupp sollt keng zous\u00e4tzlech Felder enthalen.", "validators", "lb");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "De gesch\u00e9cktene Fichier ass ze grouss. Versicht wann ech gelift ee m\u00e9i klenge Fichier eropzelueden.", "validators", "lb");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "Den CSRF-Token ass ong\u00eblteg. Versicht wann ech gelift de Formulaire nach eng K\u00e9ier ze sch\u00e9cken.", "validators", "lb");
})(Translator);
