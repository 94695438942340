(function (t) {
// hu
t.add("This value should be false.", "Ennek az \u00e9rt\u00e9knek hamisnak kell lennie.", "validators", "hu");
t.add("This value should be true.", "Ennek az \u00e9rt\u00e9knek igaznak kell lennie.", "validators", "hu");
t.add("This value should be of type {{ type }}.", "Ennek az \u00e9rt\u00e9knek {{ type }} t\u00edpus\u00fanak kell lennie.", "validators", "hu");
t.add("This value should be blank.", "Ennek az \u00e9rt\u00e9knek \u00fcresnek kell lennie.", "validators", "hu");
t.add("The value you selected is not a valid choice.", "A v\u00e1lasztott \u00e9rt\u00e9k \u00e9rv\u00e9nytelen.", "validators", "hu");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Legal\u00e1bb {{ limit }} \u00e9rt\u00e9ket kell kiv\u00e1lasztani.|Legal\u00e1bb {{ limit }} \u00e9rt\u00e9ket kell kiv\u00e1lasztani.", "validators", "hu");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Legfeljebb {{ limit }} \u00e9rt\u00e9ket lehet kiv\u00e1lasztani.|Legfeljebb {{ limit }} \u00e9rt\u00e9ket lehet kiv\u00e1lasztani.", "validators", "hu");
t.add("One or more of the given values is invalid.", "A megadott \u00e9rt\u00e9kek k\u00f6z\u00fcl legal\u00e1bb egy \u00e9rv\u00e9nytelen.", "validators", "hu");
t.add("This field was not expected.", "Nem v\u00e1rt mez\u0151.", "validators", "hu");
t.add("This field is missing.", "Ez a mez\u0151 hi\u00e1nyzik.", "validators", "hu");
t.add("This value is not a valid date.", "Ez az \u00e9rt\u00e9k nem egy \u00e9rv\u00e9nyes d\u00e1tum.", "validators", "hu");
t.add("This value is not a valid datetime.", "Ez az \u00e9rt\u00e9k nem egy \u00e9rv\u00e9nyes id\u0151pont.", "validators", "hu");
t.add("This value is not a valid email address.", "Ez az \u00e9rt\u00e9k nem egy \u00e9rv\u00e9nyes e-mail c\u00edm.", "validators", "hu");
t.add("The file could not be found.", "A f\u00e1jl nem tal\u00e1lhat\u00f3.", "validators", "hu");
t.add("The file is not readable.", "A f\u00e1jl nem olvashat\u00f3.", "validators", "hu");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "A f\u00e1jl t\u00fal nagy ({{ size }} {{ suffix }}). A legnagyobb megengedett m\u00e9ret {{ limit }} {{ suffix }}.", "validators", "hu");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "A f\u00e1jl MIME t\u00edpusa \u00e9rv\u00e9nytelen ({{ type }}). Az enged\u00e9lyezett MIME t\u00edpusok: {{ types }}.", "validators", "hu");
t.add("This value should be {{ limit }} or less.", "Ez az \u00e9rt\u00e9k legfeljebb {{ limit }} lehet.", "validators", "hu");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Ez az \u00e9rt\u00e9k t\u00fal hossz\u00fa. Legfeljebb {{ limit }} karaktert tartalmazhat.|Ez az \u00e9rt\u00e9k t\u00fal hossz\u00fa. Legfeljebb {{ limit }} karaktert tartalmazhat.", "validators", "hu");
t.add("This value should be {{ limit }} or more.", "Ez az \u00e9rt\u00e9k legal\u00e1bb {{ limit }} kell, hogy legyen.", "validators", "hu");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Ez az \u00e9rt\u00e9k t\u00fal r\u00f6vid. Legal\u00e1bb {{ limit }} karaktert kell tartalmaznia.|Ez az \u00e9rt\u00e9k t\u00fal r\u00f6vid. Legal\u00e1bb {{ limit }} karaktert kell tartalmaznia.", "validators", "hu");
t.add("This value should not be blank.", "Ez az \u00e9rt\u00e9k nem lehet \u00fcres.", "validators", "hu");
t.add("This value should not be null.", "Ez az \u00e9rt\u00e9k nem lehet null.", "validators", "hu");
t.add("This value should be null.", "Ennek az \u00e9rt\u00e9knek nullnak kell lennie.", "validators", "hu");
t.add("This value is not valid.", "Ez az \u00e9rt\u00e9k nem \u00e9rv\u00e9nyes.", "validators", "hu");
t.add("This value is not a valid time.", "Ez az \u00e9rt\u00e9k nem egy \u00e9rv\u00e9nyes id\u0151pont.", "validators", "hu");
t.add("This value is not a valid URL.", "Ez az \u00e9rt\u00e9k nem egy \u00e9rv\u00e9nyes URL.", "validators", "hu");
t.add("The two values should be equal.", "A k\u00e9t \u00e9rt\u00e9knek azonosnak kell lennie.", "validators", "hu");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "A f\u00e1jl t\u00fal nagy. A megengedett maxim\u00e1lis m\u00e9ret: {{ limit }} {{ suffix }}.", "validators", "hu");
t.add("The file is too large.", "A f\u00e1jl t\u00fal nagy.", "validators", "hu");
t.add("The file could not be uploaded.", "A f\u00e1jl nem t\u00f6lthet\u0151 fel.", "validators", "hu");
t.add("This value should be a valid number.", "Ennek az \u00e9rt\u00e9knek \u00e9rv\u00e9nyes sz\u00e1mnak kell lennie.", "validators", "hu");
t.add("This file is not a valid image.", "Ez a f\u00e1jl nem egy \u00e9rv\u00e9nyes k\u00e9p.", "validators", "hu");
t.add("This is not a valid IP address.", "Ez az \u00e9rt\u00e9k nem egy \u00e9rv\u00e9nyes IP c\u00edm.", "validators", "hu");
t.add("This value is not a valid language.", "Ez az \u00e9rt\u00e9k nem egy \u00e9rv\u00e9nyes nyelv.", "validators", "hu");
t.add("This value is not a valid locale.", "Ez az \u00e9rt\u00e9k nem egy \u00e9rv\u00e9nyes ter\u00fcleti be\u00e1ll\u00edt\u00e1s.", "validators", "hu");
t.add("This value is not a valid country.", "Ez az \u00e9rt\u00e9k nem egy \u00e9rv\u00e9nyes orsz\u00e1g.", "validators", "hu");
t.add("This value is already used.", "Ez az \u00e9rt\u00e9k m\u00e1r haszn\u00e1latban van.", "validators", "hu");
t.add("The size of the image could not be detected.", "A k\u00e9p m\u00e9ret\u00e9t nem lehet meg\u00e1llap\u00edtani.", "validators", "hu");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "A k\u00e9p sz\u00e9less\u00e9ge t\u00fal nagy ({{ width }}px). A megengedett legnagyobb sz\u00e9less\u00e9g {{ max_width }}px.", "validators", "hu");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "A k\u00e9p sz\u00e9less\u00e9ge t\u00fal kicsi ({{ width }}px). Az elv\u00e1rt legkisebb sz\u00e9less\u00e9g {{ min_width }}px.", "validators", "hu");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "A k\u00e9p magass\u00e1ga t\u00fal nagy ({{ height }}px). A megengedett legnagyobb magass\u00e1g {{ max_height }}px.", "validators", "hu");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "A k\u00e9p magass\u00e1ga t\u00fal kicsi ({{ height }}px). Az elv\u00e1rt legkisebb magass\u00e1g {{ min_height }}px.", "validators", "hu");
t.add("This value should be the user's current password.", "Ez az \u00e9rt\u00e9k a felhaszn\u00e1l\u00f3 jelenlegi jelszav\u00e1val kell megegyezzen.", "validators", "hu");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Ennek az \u00e9rt\u00e9knek pontosan {{ limit }} karaktert kell tartalmaznia.|Ennek az \u00e9rt\u00e9knek pontosan {{ limit }} karaktert kell tartalmaznia.", "validators", "hu");
t.add("The file was only partially uploaded.", "A f\u00e1jl csak r\u00e9szben lett felt\u00f6ltve.", "validators", "hu");
t.add("No file was uploaded.", "Nem lett f\u00e1jl felt\u00f6ltve.", "validators", "hu");
t.add("No temporary folder was configured in php.ini.", "Nincs ideiglenes k\u00f6nyvt\u00e1r be\u00e1ll\u00edtva a php.ini-ben.", "validators", "hu");
t.add("Cannot write temporary file to disk.", "Az ideiglenes f\u00e1jl nem \u00edrhat\u00f3 a lemezre.", "validators", "hu");
t.add("A PHP extension caused the upload to fail.", "Egy PHP b\u0151v\u00edtm\u00e9ny miatt a felt\u00f6lt\u00e9s nem siker\u00fclt.", "validators", "hu");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Ennek a gy\u0171jtem\u00e9nynek legal\u00e1bb {{ limit }} elemet kell tartalmaznia.|Ennek a gy\u0171jtem\u00e9nynek legal\u00e1bb {{ limit }} elemet kell tartalmaznia.", "validators", "hu");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Ez a gy\u0171jtem\u00e9ny legfeljebb {{ limit }} elemet tartalmazhat.|Ez a gy\u0171jtem\u00e9ny legfeljebb {{ limit }} elemet tartalmazhat.", "validators", "hu");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Ennek a gy\u0171jtem\u00e9nynek pontosan {{ limit }} elemet kell tartalmaznia.|Ennek a gy\u0171jtem\u00e9nynek pontosan {{ limit }} elemet kell tartalmaznia.", "validators", "hu");
t.add("Invalid card number.", "\u00c9rv\u00e9nytelen k\u00e1rtyasz\u00e1m.", "validators", "hu");
t.add("Unsupported card type or invalid card number.", "Nem t\u00e1mogatott k\u00e1rtyat\u00edpus vagy \u00e9rv\u00e9nytelen k\u00e1rtyasz\u00e1m.", "validators", "hu");
t.add("This is not a valid International Bank Account Number (IBAN).", "\u00c9rv\u00e9nytelen nemzetk\u00f6zi banksz\u00e1mlasz\u00e1m (IBAN).", "validators", "hu");
t.add("This value is not a valid ISBN-10.", "Ez az \u00e9rt\u00e9k nem egy \u00e9rv\u00e9nyes ISBN-10.", "validators", "hu");
t.add("This value is not a valid ISBN-13.", "Ez az \u00e9rt\u00e9k nem egy \u00e9rv\u00e9nyes ISBN-13.", "validators", "hu");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Ez az \u00e9rt\u00e9k nem egy \u00e9rv\u00e9nyes ISBN-10 vagy ISBN-13.", "validators", "hu");
t.add("This value is not a valid ISSN.", "Ez az \u00e9rt\u00e9k nem egy \u00e9rv\u00e9nyes ISSN.", "validators", "hu");
t.add("This value is not a valid currency.", "Ez az \u00e9rt\u00e9k nem egy \u00e9rv\u00e9nyes p\u00e9nznem.", "validators", "hu");
t.add("This value should be equal to {{ compared_value }}.", "Ez az \u00e9rt\u00e9k legyen {{ compared_value }}.", "validators", "hu");
t.add("This value should be greater than {{ compared_value }}.", "Ez az \u00e9rt\u00e9k nagyobb legyen, mint {{ compared_value }}.", "validators", "hu");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Ez az \u00e9rt\u00e9k nagyobb vagy egyenl\u0151 legyen, mint {{ compared_value }}.", "validators", "hu");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Ez az \u00e9rt\u00e9k ugyanolyan legyen, mint {{ compared_value_type }} {{ compared_value }}.", "validators", "hu");
t.add("This value should be less than {{ compared_value }}.", "Ez az \u00e9rt\u00e9k kisebb legyen, mint {{ compared_value }}.", "validators", "hu");
t.add("This value should be less than or equal to {{ compared_value }}.", "Ez az \u00e9rt\u00e9k kisebb vagy egyenl\u0151 legyen, mint {{ compared_value }}.", "validators", "hu");
t.add("This value should not be equal to {{ compared_value }}.", "Ez az \u00e9rt\u00e9k ne legyen {{ compared_value }}.", "validators", "hu");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Ez az \u00e9rt\u00e9k ne legyen ugyanolyan, mint {{ compared_value_type }} {{ compared_value }}.", "validators", "hu");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "A k\u00e9par\u00e1ny t\u00fal nagy ({{ ratio }}). A megengedett legnagyobb k\u00e9par\u00e1ny {{ max_ratio }}.", "validators", "hu");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "A k\u00e9par\u00e1ny t\u00fal kicsi ({{ ratio }}). A megengedett legkisebb k\u00e9par\u00e1ny {{ min_ratio }}.", "validators", "hu");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "A k\u00e9p n\u00e9gyzet alak\u00fa ({{ width }}x{{ height }}px). A n\u00e9gyzet alak\u00fa k\u00e9pek nem enged\u00e9lyezettek.", "validators", "hu");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "A k\u00e9p fekv\u0151 t\u00e1jol\u00e1s\u00fa ({{ width }}x{{ height }}px). A fekv\u0151 t\u00e1jol\u00e1s\u00fa k\u00e9pek nem enged\u00e9lyezettek.", "validators", "hu");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "A k\u00e9p \u00e1ll\u00f3 t\u00e1jol\u00e1s\u00fa ({{ width }}x{{ height }}px). Az \u00e1ll\u00f3 t\u00e1jol\u00e1s\u00fa k\u00e9pek nem enged\u00e9lyezettek.", "validators", "hu");
t.add("An empty file is not allowed.", "\u00dcres f\u00e1jl nem megengedett.", "validators", "hu");
t.add("The host could not be resolved.", "Az \u00e1llom\u00e1snevet nem lehet feloldani.", "validators", "hu");
t.add("This value does not match the expected {{ charset }} charset.", "Ez az \u00e9rt\u00e9k nem az elv\u00e1rt {{ charset }} karakterk\u00f3dol\u00e1st haszn\u00e1lja.", "validators", "hu");
t.add("This is not a valid Business Identifier Code (BIC).", "\u00c9rv\u00e9nytelen nemzetk\u00f6zi bankazonos\u00edt\u00f3 k\u00f3d (BIC\/SWIFT).", "validators", "hu");
t.add("Error", "Hiba", "validators", "hu");
t.add("This is not a valid UUID.", "\u00c9rv\u00e9nytelen egyedi azonos\u00edt\u00f3 (UUID).", "validators", "hu");
t.add("This form should not contain extra fields.", "Ez a mez\u0151csoport nem tartalmazhat extra mez\u0151ket.", "validators", "hu");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "A felt\u00f6lt\u00f6tt f\u00e1jl t\u00fal nagy. K\u00e9rem, pr\u00f3b\u00e1ljon egy kisebb f\u00e1jlt felt\u00f6lteni.", "validators", "hu");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "\u00c9rv\u00e9nytelen CSRF token. K\u00e9rem, pr\u00f3b\u00e1lja \u00fajra elk\u00fcldeni az \u0171rlapot.", "validators", "hu");
})(Translator);
