(function (t) {
// af
t.add("This value should be false.", "Hierdie waarde moet vals wees.", "validators", "af");
t.add("This value should be true.", "Hierdie waarde moet waar wees.", "validators", "af");
t.add("This value should be of type {{ type }}.", "Hierdie waarde moet van die soort {{type}} wees.", "validators", "af");
t.add("This value should be blank.", "Hierdie waarde moet leeg wees.", "validators", "af");
t.add("The value you selected is not a valid choice.", "Die waarde wat jy gekies het is nie 'n geldige keuse nie.", "validators", "af");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Jy moet ten minste {{ limit }} kies.|Jy moet ten minste {{ limit }} keuses kies.", "validators", "af");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Jy moet by die meeste {{ limit }} keuse kies.|Jy moet by die meeste {{ limit }} keuses kies.", "validators", "af");
t.add("One or more of the given values is invalid.", "Een of meer van die gegewe waardes is ongeldig.", "validators", "af");
t.add("This field was not expected.", "Die veld is nie verwag nie.", "validators", "af");
t.add("This field is missing.", "Hierdie veld ontbreek.", "validators", "af");
t.add("This value is not a valid date.", "Hierdie waarde is nie 'n geldige datum nie.", "validators", "af");
t.add("This value is not a valid datetime.", "Hierdie waarde is nie 'n geldige datum en tyd nie.", "validators", "af");
t.add("This value is not a valid email address.", "Hierdie waarde is nie 'n geldige e-pos adres nie.", "validators", "af");
t.add("The file could not be found.", "Die l\u00eaer kon nie gevind word nie.", "validators", "af");
t.add("The file is not readable.", "Die l\u00eaer kan nie gelees word nie.", "validators", "af");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Die l\u00eaer is te groot ({{ size }} {{ suffix }}). Toegelaat maksimum grootte is {{ limit }} {{ suffix }}.", "validators", "af");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Die MIME-tipe van die l\u00eaer is ongeldig ({{ type }}). Toegelaat MIME-tipes is {{ types }}.", "validators", "af");
t.add("This value should be {{ limit }} or less.", "Hierdie waarde moet {{ limit }} of minder wees.", "validators", "af");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Hierdie waarde is te lank. Dit moet {{ limit }} karakter of minder wees.|Hierdie waarde is te lank. Dit moet {{ limit }} karakters of minder wees.", "validators", "af");
t.add("This value should be {{ limit }} or more.", "Hierdie waarde moet {{ limit }} of meer wees.", "validators", "af");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Hierdie waarde is te kort. Dit moet {{ limit }} karakter of meer wees.|Hierdie waarde is te kort. Dit moet {{ limit }} karakters of meer wees.", "validators", "af");
t.add("This value should not be blank.", "Hierdie waarde moet nie leeg wees nie.", "validators", "af");
t.add("This value should not be null.", "Hierdie waarde moet nie nul wees nie.", "validators", "af");
t.add("This value should be null.", "Hierdie waarde moet nul wees.", "validators", "af");
t.add("This value is not valid.", "Hierdie waarde is nie geldig nie.", "validators", "af");
t.add("This value is not a valid time.", "Hierdie waarde is nie 'n geldige tyd nie.", "validators", "af");
t.add("This value is not a valid URL.", "Hierdie waarde is nie 'n geldige URL nie.", "validators", "af");
t.add("The two values should be equal.", "Die twee waardes moet gelyk wees.", "validators", "af");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Die l\u00eaer is te groot. Toegelaat maksimum grootte is {{ limit }} {{ suffix }}.", "validators", "af");
t.add("The file is too large.", "Die l\u00eaer is te groot.", "validators", "af");
t.add("The file could not be uploaded.", "Die l\u00eaer kan nie opgelaai word nie.", "validators", "af");
t.add("This value should be a valid number.", "Hierdie waarde moet 'n geldige nommer wees.", "validators", "af");
t.add("This file is not a valid image.", "Hierdie l\u00eaer is nie 'n geldige beeld nie.", "validators", "af");
t.add("This is not a valid IP address.", "Hierdie is nie 'n geldige IP-adres nie.", "validators", "af");
t.add("This value is not a valid language.", "Hierdie waarde is nie 'n geldige taal nie.", "validators", "af");
t.add("This value is not a valid locale.", "Hierdie waarde is nie 'n geldige land instelling nie.", "validators", "af");
t.add("This value is not a valid country.", "Hierdie waarde is nie 'n geldige land nie.", "validators", "af");
t.add("This value is already used.", "Hierdie waarde word reeds gebruik.", "validators", "af");
t.add("The size of the image could not be detected.", "Die grootte van die beeld kon nie opgespoor word nie.", "validators", "af");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "Die beeld breedte is te groot ({{ width }}px). Toegelaat maksimum breedte is {{ max_width }}px.", "validators", "af");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "Die beeld breedte is te klein ({{ width }}px). Minimum breedte verwag is {{ min_width }}px.", "validators", "af");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "Die beeld hoogte is te groot ({{ height }}px). Toegelaat maksimum hoogte is {{ max_height }}px.", "validators", "af");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "Die beeld hoogte is te klein ({{ height }}px). Minimum hoogte verwag is {{ min_height }}px.", "validators", "af");
t.add("This value should be the user's current password.", "Hierdie waarde moet die huidige wagwoord van die gebruiker wees.", "validators", "af");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Hierdie waarde moet presies {{ limit }} karakter wees.|Hierdie waarde moet presies {{ limit }} karakters wees.", "validators", "af");
t.add("The file was only partially uploaded.", "Die l\u00eaer is slegs gedeeltelik opgelaai.", "validators", "af");
t.add("No file was uploaded.", "Geen l\u00eaer is opgelaai nie.", "validators", "af");
t.add("No temporary folder was configured in php.ini.", "Geen tydelike l\u00eaer is ingestel in php.ini nie.", "validators", "af");
t.add("Cannot write temporary file to disk.", "Kan nie tydelike l\u00eaer skryf op skyf nie.", "validators", "af");
t.add("A PHP extension caused the upload to fail.", "'n PHP-uitbreiding veroorsaak die oplaai van die l\u00eaer om te misluk.", "validators", "af");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Hierdie versameling moet {{ limit }} element of meer bevat.|Hierdie versameling moet {{ limit }} elemente of meer bevat.", "validators", "af");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Hierdie versameling moet {{ limit }} element of minder bevat.|Hierdie versameling moet {{ limit }} elemente of meer bevat.", "validators", "af");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Hierdie versameling moet presies {{ limit }} element bevat.|Hierdie versameling moet presies {{ limit }} elemente bevat.", "validators", "af");
t.add("Invalid card number.", "Ongeldige kredietkaart nommer.", "validators", "af");
t.add("Unsupported card type or invalid card number.", "Nie-ondersteunde tipe kaart of ongeldige kredietkaart nommer.", "validators", "af");
})(Translator);
