(function (t) {
// pt_BR
t.add("This value should be false.", "Este valor deve ser falso.", "validators", "pt_BR");
t.add("This value should be true.", "Este valor deve ser verdadeiro.", "validators", "pt_BR");
t.add("This value should be of type {{ type }}.", "Este valor deve ser do tipo {{ type }}.", "validators", "pt_BR");
t.add("This value should be blank.", "Este valor deve ser vazio.", "validators", "pt_BR");
t.add("The value you selected is not a valid choice.", "O valor selecionado n\u00e3o \u00e9 uma op\u00e7\u00e3o v\u00e1lida.", "validators", "pt_BR");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Voc\u00ea deve selecionar, no m\u00ednimo, {{ limit }} op\u00e7\u00e3o.|Voc\u00ea deve selecionar, no m\u00ednimo, {{ limit }} op\u00e7\u00f5es.", "validators", "pt_BR");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Voc\u00ea deve selecionar, no m\u00e1ximo, {{ limit }} op\u00e7\u00e3o.|Voc\u00ea deve selecionar, no m\u00e1ximo, {{ limit }} op\u00e7\u00f5es.", "validators", "pt_BR");
t.add("One or more of the given values is invalid.", "Um ou mais valores informados s\u00e3o inv\u00e1lidos.", "validators", "pt_BR");
t.add("This field was not expected.", "Este campo n\u00e3o era esperado.", "validators", "pt_BR");
t.add("This field is missing.", "Este campo est\u00e1 ausente.", "validators", "pt_BR");
t.add("This value is not a valid date.", "Este valor n\u00e3o \u00e9 uma data v\u00e1lida.", "validators", "pt_BR");
t.add("This value is not a valid datetime.", "Este valor n\u00e3o \u00e9 uma data e hora v\u00e1lida.", "validators", "pt_BR");
t.add("This value is not a valid email address.", "Este valor n\u00e3o \u00e9 um endere\u00e7o de e-mail v\u00e1lido.", "validators", "pt_BR");
t.add("The file could not be found.", "O arquivo n\u00e3o foi encontrado.", "validators", "pt_BR");
t.add("The file is not readable.", "O arquivo n\u00e3o pode ser lido.", "validators", "pt_BR");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "O arquivo \u00e9 muito grande ({{ size }} {{ suffix }}). O tamanho m\u00e1ximo permitido \u00e9 {{ limit }} {{ suffix }}.", "validators", "pt_BR");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "O tipo mime do arquivo \u00e9 inv\u00e1lido ({{ type }}). Os tipos mime permitidos s\u00e3o {{ types }}.", "validators", "pt_BR");
t.add("This value should be {{ limit }} or less.", "Este valor deve ser {{ limit }} ou menos.", "validators", "pt_BR");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Este valor \u00e9 muito longo. Deve ter {{ limit }} caractere ou menos.|Este valor \u00e9 muito longo. Deve ter {{ limit }} caracteres ou menos.", "validators", "pt_BR");
t.add("This value should be {{ limit }} or more.", "Este valor deve ser {{ limit }} ou mais.", "validators", "pt_BR");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Este valor \u00e9 muito curto. Deve ter {{ limit }} caractere ou mais.|Este valor \u00e9 muito curto. Deve ter {{ limit }} caracteres ou mais.", "validators", "pt_BR");
t.add("This value should not be blank.", "Este valor n\u00e3o deve ser vazio.", "validators", "pt_BR");
t.add("This value should not be null.", "Este valor n\u00e3o deve ser nulo.", "validators", "pt_BR");
t.add("This value should be null.", "Este valor deve ser nulo.", "validators", "pt_BR");
t.add("This value is not valid.", "Este valor n\u00e3o \u00e9 v\u00e1lido.", "validators", "pt_BR");
t.add("This value is not a valid time.", "Este valor n\u00e3o \u00e9 uma hora v\u00e1lida.", "validators", "pt_BR");
t.add("This value is not a valid URL.", "Este valor n\u00e3o \u00e9 uma URL v\u00e1lida.", "validators", "pt_BR");
t.add("The two values should be equal.", "Os dois valores devem ser iguais.", "validators", "pt_BR");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "O arquivo \u00e9 muito grande. O tamanho m\u00e1ximo permitido \u00e9 de {{ limit }} {{ suffix }}.", "validators", "pt_BR");
t.add("The file is too large.", "O arquivo \u00e9 muito grande.", "validators", "pt_BR");
t.add("The file could not be uploaded.", "O arquivo n\u00e3o pode ser enviado.", "validators", "pt_BR");
t.add("This value should be a valid number.", "Este valor deve ser um n\u00famero v\u00e1lido.", "validators", "pt_BR");
t.add("This file is not a valid image.", "Este arquivo n\u00e3o \u00e9 uma imagem v\u00e1lida.", "validators", "pt_BR");
t.add("This is not a valid IP address.", "Este n\u00e3o \u00e9 um endere\u00e7o de IP v\u00e1lido.", "validators", "pt_BR");
t.add("This value is not a valid language.", "Este valor n\u00e3o \u00e9 um idioma v\u00e1lido.", "validators", "pt_BR");
t.add("This value is not a valid locale.", "Este valor n\u00e3o \u00e9 uma localidade v\u00e1lida.", "validators", "pt_BR");
t.add("This value is not a valid country.", "Este valor n\u00e3o \u00e9 um pa\u00eds v\u00e1lido.", "validators", "pt_BR");
t.add("This value is already used.", "Este valor j\u00e1 est\u00e1 sendo usado.", "validators", "pt_BR");
t.add("The size of the image could not be detected.", "O tamanho da imagem n\u00e3o pode ser detectado.", "validators", "pt_BR");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "A largura da imagem \u00e9 muito grande ({{ width }}px). A largura m\u00e1xima permitida \u00e9 de {{ max_width }}px.", "validators", "pt_BR");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "A largura da imagem \u00e9 muito pequena ({{ width }}px). A largura m\u00ednima esperada \u00e9 de {{ min_width }}px.", "validators", "pt_BR");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "A altura da imagem \u00e9 muito grande ({{ height }}px). A altura m\u00e1xima permitida \u00e9 de {{ max_height }}px.", "validators", "pt_BR");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "A altura da imagem \u00e9 muito pequena ({{ height }}px). A altura m\u00ednima esperada \u00e9 de {{ min_height }}px.", "validators", "pt_BR");
t.add("This value should be the user's current password.", "Este valor deve ser a senha atual do usu\u00e1rio.", "validators", "pt_BR");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Este valor deve ter exatamente {{ limit }} caractere.|Este valor deve ter exatamente {{ limit }} caracteres.", "validators", "pt_BR");
t.add("The file was only partially uploaded.", "O arquivo foi enviado apenas parcialmente.", "validators", "pt_BR");
t.add("No file was uploaded.", "Nenhum arquivo foi enviado.", "validators", "pt_BR");
t.add("No temporary folder was configured in php.ini.", "Nenhum diret\u00f3rio tempor\u00e1rio foi configurado no php.ini.", "validators", "pt_BR");
t.add("Cannot write temporary file to disk.", "N\u00e3o foi poss\u00edvel escrever o arquivo tempor\u00e1rio no disco.", "validators", "pt_BR");
t.add("A PHP extension caused the upload to fail.", "Uma extens\u00e3o PHP fez com que o envio falhasse.", "validators", "pt_BR");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Esta cole\u00e7\u00e3o deve conter {{ limit }} elemento ou mais.|Esta cole\u00e7\u00e3o deve conter {{ limit }} elementos ou mais.", "validators", "pt_BR");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Esta cole\u00e7\u00e3o deve conter {{ limit }} elemento ou menos.|Esta cole\u00e7\u00e3o deve conter {{ limit }} elementos ou menos.", "validators", "pt_BR");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Esta cole\u00e7\u00e3o deve conter exatamente {{ limit }} elemento.|Esta cole\u00e7\u00e3o deve conter exatamente {{ limit }} elementos.", "validators", "pt_BR");
t.add("Invalid card number.", "N\u00famero de cart\u00e3o inv\u00e1lido.", "validators", "pt_BR");
t.add("Unsupported card type or invalid card number.", "Tipo de cart\u00e3o n\u00e3o suportado ou n\u00famero de cart\u00e3o inv\u00e1lido.", "validators", "pt_BR");
t.add("This is not a valid International Bank Account Number (IBAN).", "Este n\u00e3o \u00e9 um N\u00famero Internacional de Conta Banc\u00e1ria (IBAN) v\u00e1lido.", "validators", "pt_BR");
t.add("This value is not a valid ISBN-10.", "Este valor n\u00e3o \u00e9 um ISBN-10 v\u00e1lido.", "validators", "pt_BR");
t.add("This value is not a valid ISBN-13.", "Este valor n\u00e3o \u00e9 um ISBN-13 v\u00e1lido.", "validators", "pt_BR");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Este valor n\u00e3o \u00e9 um ISBN-10 e nem um ISBN-13 v\u00e1lido.", "validators", "pt_BR");
t.add("This value is not a valid ISSN.", "Este valor n\u00e3o \u00e9 um ISSN v\u00e1lido.", "validators", "pt_BR");
t.add("This value is not a valid currency.", "Este n\u00e3o \u00e9 um valor monet\u00e1rio v\u00e1lido.", "validators", "pt_BR");
t.add("This value should be equal to {{ compared_value }}.", "Este valor deve ser igual a {{ compared_value }}.", "validators", "pt_BR");
t.add("This value should be greater than {{ compared_value }}.", "Este valor deve ser maior que {{ compared_value }}.", "validators", "pt_BR");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Este valor deve ser maior ou igual a {{ compared_value }}.", "validators", "pt_BR");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Este valor deve ser id\u00eantico a {{ compared_value_type }} {{ compared_value }}.", "validators", "pt_BR");
t.add("This value should be less than {{ compared_value }}.", "Este valor deve ser menor que {{ compared_value }}.", "validators", "pt_BR");
t.add("This value should be less than or equal to {{ compared_value }}.", "Este valor deve ser menor ou igual a {{ compared_value }}.", "validators", "pt_BR");
t.add("This value should not be equal to {{ compared_value }}.", "Este valor n\u00e3o deve ser igual a {{ compared_value }}.", "validators", "pt_BR");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Este valor n\u00e3o deve ser id\u00eantico a {{ compared_value_type }} {{ compared_value }}.", "validators", "pt_BR");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "A propor\u00e7\u00e3o da imagem \u00e9 muito grande ({{ ratio }}). A propor\u00e7\u00e3o m\u00e1xima permitida \u00e9 {{ max_ratio }}.", "validators", "pt_BR");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "A propor\u00e7\u00e3o da imagem \u00e9 muito pequena ({{ ratio }}). A propor\u00e7\u00e3o m\u00ednima esperada \u00e9 {{ min_ratio }}.", "validators", "pt_BR");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "A imagem est\u00e1 num formato quadrado ({{ width }}x{{ height }}px). Imagens com formato quadrado n\u00e3o s\u00e3o permitidas.", "validators", "pt_BR");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "A imagem est\u00e1 orientada \u00e0 paisagem ({{ width }}x{{ height }}px). Imagens orientadas \u00e0 paisagem n\u00e3o s\u00e3o permitidas.", "validators", "pt_BR");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "A imagem est\u00e1 orientada ao retrato ({{ width }}x{{ height }}px). Imagens orientadas ao retrato n\u00e3o s\u00e3o permitidas.", "validators", "pt_BR");
t.add("An empty file is not allowed.", "Arquivo vazio n\u00e3o \u00e9 permitido.", "validators", "pt_BR");
t.add("The host could not be resolved.", "O host n\u00e3o p\u00f4de ser resolvido.", "validators", "pt_BR");
t.add("This value does not match the expected {{ charset }} charset.", "Este valor n\u00e3o corresponde ao charset {{ charset }} esperado.", "validators", "pt_BR");
t.add("This is not a valid Business Identifier Code (BIC).", "Este n\u00e3o \u00e9 um C\u00f3digo Identificador Banc\u00e1rio (BIC) v\u00e1lido.", "validators", "pt_BR");
t.add("This form should not contain extra fields.", "Este formul\u00e1rio n\u00e3o deve conter campos adicionais.", "validators", "pt_BR");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "O arquivo enviado \u00e9 muito grande. Por favor, tente enviar um arquivo menor.", "validators", "pt_BR");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "O token CSRF \u00e9 inv\u00e1lido. Por favor, tente reenviar o formul\u00e1rio.", "validators", "pt_BR");
})(Translator);
