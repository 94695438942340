(function (t) {
// bg
t.add("This value should be false.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043b\u044a\u0436\u0430 (false).", "validators", "bg");
t.add("This value should be true.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 \u0438\u0441\u0442\u0438\u043d\u0430 (true).", "validators", "bg");
t.add("This value should be of type {{ type }}.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043e\u0442 \u0442\u0438\u043f {{ type }}.", "validators", "bg");
t.add("This value should be blank.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043f\u0440\u0430\u0437\u043d\u0430.", "validators", "bg");
t.add("The value you selected is not a valid choice.", "\u0418\u0437\u0431\u0440\u0430\u043d\u0430\u0442\u0430 \u0441\u0442\u043e\u0439\u043d\u043e\u0441\u0442 \u0435 \u043d\u0435\u0432\u0430\u043b\u0438\u0434\u043d\u0430.", "validators", "bg");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "\u0422\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u043f\u043e\u043d\u0435 {{ limit }} \u043e\u043f\u0446\u0438\u044f.|\u0422\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u043f\u043e\u043d\u0435 {{ limit }} \u043e\u043f\u0446\u0438\u0438.", "validators", "bg");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "\u0422\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u043d\u0430\u0439-\u043c\u043d\u043e\u0433\u043e {{ limit }} \u043e\u043f\u0446\u0438\u044f.|\u0422\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0438\u0437\u0431\u0435\u0440\u0435\u0442\u0435 \u043d\u0430\u0439-\u043c\u043d\u043e\u0433\u043e {{ limit }} \u043e\u043f\u0446\u0438\u0438.", "validators", "bg");
t.add("One or more of the given values is invalid.", "\u0415\u0434\u043d\u0430 \u0438\u043b\u0438 \u043f\u043e\u0432\u0435\u0447\u0435 \u043e\u0442 \u0437\u0430\u0434\u0430\u0434\u0435\u043d\u0438\u0442\u0435 \u0441\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0438 \u0435 \u043d\u0435\u0432\u0430\u043b\u0438\u0434\u043d\u0430.", "validators", "bg");
t.add("This field was not expected.", "\u0422\u043e\u0432\u0430 \u043f\u043e\u043b\u0435 \u043d\u0435 \u0441\u0435 \u0435 \u043e\u0447\u0430\u043a\u0432\u0430\u043b\u043e.", "validators", "bg");
t.add("This field is missing.", "\u0422\u043e\u0432\u0430 \u043f\u043e\u043b\u0435 \u043b\u0438\u043f\u0441\u0432\u0430.", "validators", "bg");
t.add("This value is not a valid date.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u043d\u0435 \u0435 \u0432\u0430\u043b\u0438\u0434\u043d\u0430 \u0434\u0430\u0442\u0430 (date).", "validators", "bg");
t.add("This value is not a valid datetime.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u043d\u0435 \u0435 \u0432\u0430\u043b\u0438\u0434\u043d\u0430 \u0434\u0430\u0442\u0430 (datetime).", "validators", "bg");
t.add("This value is not a valid email address.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u043d\u0435 \u0435 \u0432\u0430\u043b\u0438\u0434\u0435\u043d email \u0430\u0434\u0440\u0435\u0441.", "validators", "bg");
t.add("The file could not be found.", "\u0424\u0430\u0439\u043b\u044a\u0442 \u043d\u0435 \u0431\u0435\u0448\u0435 \u043e\u0442\u043a\u0440\u0438\u0442.", "validators", "bg");
t.add("The file is not readable.", "\u0424\u0430\u0439\u043b\u044a\u0442 \u043d\u0435 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043f\u0440\u043e\u0447\u0435\u0442\u0435\u043d.", "validators", "bg");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "\u0424\u0430\u0439\u043b\u044a\u0442 \u0435 \u0442\u0432\u044a\u0440\u0434\u0435 \u0433\u043e\u043b\u044f\u043c ({{ size }} {{ suffix }}). \u041c\u0430\u043a\u0441\u0438\u043c\u0430\u043b\u043d\u0438\u044f\u0442 \u0440\u0430\u0437\u043c\u0435\u0440 \u0435 {{ limit }} {{ suffix }}.", "validators", "bg");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "\u041c\u0430\u0439\u043c \u0442\u0438\u043f\u0430 \u043d\u0430 \u0444\u0430\u0439\u043b\u0430 \u0435 \u043d\u0435\u0432\u0430\u043b\u0438\u0434\u0435\u043d ({{ type }}). \u0420\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u0438 \u043c\u0430\u0439\u043c \u0442\u0438\u043f\u043e\u0432\u0435 \u0441\u0430 {{ types }}.", "validators", "bg");
t.add("This value should be {{ limit }} or less.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 {{ limit }} \u0438\u043b\u0438 \u043f\u043e-\u043c\u0430\u043b\u043a\u043e.", "validators", "bg");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u0435 \u0442\u0432\u044a\u0440\u0434\u0435 \u0434\u044a\u043b\u0433\u0430. \u0422\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0441\u044a\u0434\u044a\u0440\u0436\u0430 \u043d\u0430\u0439-\u043c\u043d\u043e\u0433\u043e {{ limit }} \u0441\u0438\u043c\u0432\u043e\u043b.|\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u0435 \u0442\u0432\u044a\u0440\u0434\u0435 \u0434\u044a\u043b\u0433\u0430. \u0422\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0441\u044a\u0434\u044a\u0440\u0436\u0430 \u043d\u0430\u0439-\u043c\u043d\u043e\u0433\u043e {{ limit }} \u0441\u0438\u043c\u0432\u043e\u043b\u0430.", "validators", "bg");
t.add("This value should be {{ limit }} or more.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 {{ limit }} \u0438\u043b\u0438 \u043f\u043e\u0432\u0435\u0447\u0435.", "validators", "bg");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u0435 \u0442\u0432\u044a\u0440\u0434\u0435 \u043a\u0440\u0430\u0442\u043a\u0430. \u0422\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0441\u044a\u0434\u044a\u0440\u0436\u0430 \u043f\u043e\u043d\u0435 {{ limit }} \u0441\u0438\u043c\u0432\u043e\u043b.|\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u0435 \u0442\u0432\u044a\u0440\u0434\u0435 \u043a\u0440\u0430\u0442\u043a\u0430. \u0422\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0441\u044a\u0434\u044a\u0440\u0436\u0430 \u043f\u043e\u043d\u0435 {{ limit }} \u0441\u0438\u043c\u0432\u043e\u043b\u0430.", "validators", "bg");
t.add("This value should not be blank.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u043d\u0435 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043f\u0440\u0430\u0437\u043d\u0430.", "validators", "bg");
t.add("This value should not be null.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u043d\u0435 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 null.", "validators", "bg");
t.add("This value should be null.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 null.", "validators", "bg");
t.add("This value is not valid.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u043d\u0435 \u0435 \u0432\u0430\u043b\u0438\u0434\u043d\u0430.", "validators", "bg");
t.add("This value is not a valid time.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u043d\u0435 \u0435 \u0432\u0430\u043b\u0438\u0434\u043d\u043e \u0432\u0440\u0435\u043c\u0435 (time).", "validators", "bg");
t.add("This value is not a valid URL.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u043d\u0435 \u0435 \u0432\u0430\u043b\u0438\u0434\u0435\u043d URL.", "validators", "bg");
t.add("The two values should be equal.", "\u0414\u0432\u0435\u0442\u0435 \u0441\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0438 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0430\u0442 \u0440\u0430\u0432\u043d\u0438.", "validators", "bg");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "\u0424\u0430\u0439\u043b\u044a\u0442 \u0435 \u0442\u0432\u044a\u0440\u0434\u0435 \u0433\u043e\u043b\u044f\u043c. \u0420\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u0438\u044f\u0442 \u043c\u0430\u043a\u0441\u0438\u043c\u0430\u043b\u0435\u043d \u0440\u0430\u0437\u043c\u0435\u0440 \u0435 {{ limit }} {{ suffix }}.", "validators", "bg");
t.add("The file is too large.", "\u0424\u0430\u0439\u043b\u044a\u0442 \u0435 \u0442\u0432\u044a\u0440\u0434\u0435 \u0433\u043e\u043b\u044f\u043c.", "validators", "bg");
t.add("The file could not be uploaded.", "\u0424\u0430\u0439\u043b\u044a\u0442 \u043d\u0435 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043a\u0430\u0447\u0435\u043d.", "validators", "bg");
t.add("This value should be a valid number.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 \u0432\u0430\u043b\u0438\u0434\u0435\u043d \u043d\u043e\u043c\u0435\u0440.", "validators", "bg");
t.add("This file is not a valid image.", "\u0424\u0430\u0439\u043b\u044a\u0442 \u043d\u0435 \u0435 \u0432\u0430\u043b\u0438\u0434\u043d\u043e \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435.", "validators", "bg");
t.add("This is not a valid IP address.", "\u0422\u043e\u0432\u0430 \u043d\u0435 \u0435 \u0432\u0430\u043b\u0438\u0434\u0435\u043d IP \u0430\u0434\u0440\u0435\u0441.", "validators", "bg");
t.add("This value is not a valid language.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u043d\u0435 \u0435 \u0432\u0430\u043b\u0438\u0434\u0435\u043d \u0435\u0437\u0438\u043a.", "validators", "bg");
t.add("This value is not a valid locale.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u043d\u0435 \u0435 \u0432\u0430\u043b\u0438\u0434\u043d\u0430 \u043b\u043e\u043a\u0430\u043b\u0438\u0437\u0430\u0446\u0438\u044f.", "validators", "bg");
t.add("This value is not a valid country.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u043d\u0435 \u0435 \u0432\u0430\u043b\u0438\u0434\u043d\u0430 \u0434\u044a\u0440\u0436\u0430\u0432\u0430.", "validators", "bg");
t.add("This value is already used.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u0432\u0435\u0447\u0435 \u0435 \u0432 \u0443\u043f\u043e\u0442\u0440\u0435\u0431\u0430.", "validators", "bg");
t.add("The size of the image could not be detected.", "\u0420\u0430\u0437\u043c\u0435\u0440\u0430 \u043d\u0430 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435\u0442\u043e \u043d\u0435 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043e\u043f\u0440\u0435\u0434\u0435\u043b\u0435\u043d.", "validators", "bg");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "\u0418\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435\u0442\u043e \u0435 \u0442\u0432\u044a\u0440\u0434\u0435 \u0448\u0438\u0440\u043e\u043a\u043e ({{ width }}px). \u0428\u0438\u0440\u043e\u0447\u0438\u043d\u0430\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043c\u0430\u043a\u0441\u0438\u043c\u0443\u043c {{ max_width }}px.", "validators", "bg");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "\u0418\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435\u0442\u043e \u0435 \u0441 \u0442\u0432\u044a\u0440\u0434\u0435 \u043c\u0430\u043b\u043a\u0430 \u0448\u0438\u0440\u043e\u0447\u0438\u043d\u0430 ({{ width }}px). \u0428\u0438\u0440\u043e\u0447\u0438\u043d\u0430\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043c\u0438\u043d\u0438\u043c\u0443\u043c {{ min_width }}px.", "validators", "bg");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "\u0418\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435\u0442\u043e \u0435 \u0441 \u0442\u0432\u044a\u0440\u0434\u0435 \u0433\u043e\u043b\u044f\u043c\u0430 \u0432\u0438\u0441\u043e\u0447\u0438\u043d\u0430 ({{ height }}px). \u0412\u0438\u0441\u043e\u0447\u0438\u043d\u0430\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043c\u0430\u043a\u0441\u0438\u043c\u0443\u043c {{ max_height }}px.", "validators", "bg");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "\u0418\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435\u0442\u043e \u0435 \u0441 \u0442\u0432\u044a\u0440\u0434\u0435 \u043c\u0430\u043b\u043a\u0430 \u0432\u0438\u0441\u043e\u0447\u0438\u043d\u0430 ({{ height }}px). \u0412\u0438\u0441\u043e\u0447\u0438\u043d\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043c\u0438\u043d\u0438\u043c\u0443\u043c {{ min_height }}px.", "validators", "bg");
t.add("This value should be the user's current password.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 \u0442\u0435\u043a\u0443\u0449\u0430\u0442\u0430 \u043f\u043e\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043b\u0441\u043a\u0430 \u043f\u0430\u0440\u043e\u043b\u0430.", "validators", "bg");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 \u0442\u043e\u0447\u043d\u043e {{ limit }} \u0441\u0438\u043c\u0432\u043e\u043b.|\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 \u0442\u043e\u0447\u043d\u043e {{ limit }} \u0441\u0438\u043c\u0432\u043e\u043b\u0430.", "validators", "bg");
t.add("The file was only partially uploaded.", "\u0424\u0430\u0439\u043b\u044a\u0442 \u0435 \u043a\u0430\u0447\u0435\u043d \u0447\u0430\u0441\u0442\u0438\u0447\u043d\u043e.", "validators", "bg");
t.add("No file was uploaded.", "\u0424\u0430\u0439\u043b\u044a\u0442 \u043d\u0435 \u0431\u0435\u0448\u0435 \u043a\u0430\u0447\u0435\u043d.", "validators", "bg");
t.add("No temporary folder was configured in php.ini.", "\u041d\u0435 \u0435 \u043f\u043e\u0441\u043e\u0447\u0435\u043d\u0430 \u0434\u0438\u0440\u0435\u043a\u0442\u043e\u0440\u0438\u044f \u0437\u0430 \u0432\u0440\u0435\u043c\u0435\u043d\u043d\u0438 \u0444\u0430\u0439\u043b\u043e\u0432\u0435 \u0432 php.ini.", "validators", "bg");
t.add("Cannot write temporary file to disk.", "\u041d\u0435 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0437\u0430\u043f\u0438\u0448\u0435 \u0432\u0440\u0435\u043c\u0435\u043d\u0435\u043d \u0444\u0430\u0439\u043b \u043d\u0430 \u0434\u0438\u0441\u043a\u0430.", "validators", "bg");
t.add("A PHP extension caused the upload to fail.", "PHP \u0440\u0430\u0437\u0448\u0438\u0440\u0435\u043d\u0438\u0435 \u043f\u0440\u0435\u0434\u0438\u0437\u0432\u0438\u043a\u0430 \u043f\u0440\u0435\u043a\u044a\u0441\u0432\u0430\u043d\u0435 \u043d\u0430 \u043a\u0430\u0447\u0432\u0430\u043d\u0435\u0442\u043e.", "validators", "bg");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "\u041a\u043e\u043b\u0435\u043a\u0446\u0438\u044f\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0441\u044a\u0434\u044a\u0440\u0436\u0430 \u043f\u043e\u043d\u0435 {{ limit }} \u0435\u043b\u0435\u043c\u0435\u043d\u0442.|\u041a\u043e\u043b\u0435\u043a\u0446\u0438\u044f\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0441\u044a\u0434\u044a\u0440\u0436\u0430 \u043f\u043e\u043d\u0435 {{ limit }} \u0435\u043b\u0435\u043c\u0435\u043d\u0442\u0430.", "validators", "bg");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "\u041a\u043e\u043b\u0435\u043a\u0446\u0438\u044f\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0441\u044a\u0434\u044a\u0440\u0436\u0430 \u043d\u0430\u0439-\u043c\u043d\u043e\u0433\u043e {{ limit }} \u0435\u043b\u0435\u043c\u0435\u043d\u0442.|\u041a\u043e\u043b\u0435\u043a\u0446\u0438\u044f\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0441\u044a\u0434\u044a\u0440\u0436\u0430 \u043d\u0430\u0439-\u043c\u043d\u043e\u0433\u043e {{ limit }} \u0435\u043b\u0435\u043c\u0435\u043d\u0442\u0430.", "validators", "bg");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "\u041a\u043e\u043b\u0435\u043a\u0446\u0438\u044f\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0441\u044a\u0434\u044a\u0440\u0436\u0430 \u0442\u043e\u0447\u043d\u043e {{ limit }} \u0435\u043b\u0435\u043c\u0435\u043d\u0442.|\u041a\u043e\u043b\u0435\u043a\u0446\u0438\u044f\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0441\u044a\u0434\u044a\u0440\u0436\u0430 \u0442\u043e\u0447\u043d\u043e {{ limit }} \u0435\u043b\u0435\u043c\u0435\u043d\u0442\u0430.", "validators", "bg");
t.add("Invalid card number.", "\u041d\u0435\u0432\u0430\u043b\u0438\u0434\u0435\u043d \u043d\u043e\u043c\u0435\u0440 \u043d\u0430 \u043a\u0430\u0440\u0442\u0430\u0442\u0430.", "validators", "bg");
t.add("Unsupported card type or invalid card number.", "\u041d\u0435\u043f\u043e\u0434\u0434\u044a\u0440\u0436\u0430\u043d \u0442\u0438\u043f \u043a\u0430\u0440\u0442\u0430 \u0438\u043b\u0438 \u043d\u0435\u0432\u0430\u043b\u0438\u0434\u0435\u043d \u043d\u043e\u043c\u0435\u0440 \u043d\u0430 \u043a\u0430\u0440\u0442\u0430\u0442\u0430.", "validators", "bg");
t.add("This is not a valid International Bank Account Number (IBAN).", "\u041d\u0435\u0432\u0430\u043b\u0438\u0434\u0435\u043d \u041c\u0435\u0436\u0434\u0443\u043d\u0430\u0440\u043e\u0434\u0435\u043d \u043d\u043e\u043c\u0435\u0440 \u043d\u0430 \u0431\u0430\u043d\u043a\u043e\u0432\u0430 \u0441\u043c\u0435\u0442\u043a\u0430 (IBAN).", "validators", "bg");
t.add("This value is not a valid ISBN-10.", "\u041d\u0435\u0432\u0430\u043b\u0438\u0434\u0435\u043d ISBN-10.", "validators", "bg");
t.add("This value is not a valid ISBN-13.", "\u041d\u0435\u0432\u0430\u043b\u0438\u0434\u0435\u043d ISBN-13.", "validators", "bg");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "\u041d\u0435\u0432\u0430\u043b\u0438\u0434\u043d\u0430 \u0441\u0442\u043e\u0439\u043d\u043e\u0441\u0442 \u043a\u0430\u043a\u0442\u043e \u0437\u0430 ISBN-10, \u0442\u0430\u043a\u0430 \u0438 \u0437\u0430 ISBN-13 .", "validators", "bg");
t.add("This value is not a valid ISSN.", "\u041d\u0435\u0432\u0430\u043b\u0438\u0434\u0435\u043d \u041c\u0435\u0436\u0434\u0443\u043d\u0430\u0440\u043e\u0434\u0435\u043d \u0441\u0442\u0430\u043d\u0434\u0430\u0440\u0442\u0435\u043d \u0441\u0435\u0440\u0438\u0435\u043d \u043d\u043e\u043c\u0435\u0440 (ISSN).", "validators", "bg");
t.add("This value is not a valid currency.", "\u041d\u0435\u0432\u0430\u043b\u0438\u0434\u043d\u0430 \u0432\u0430\u043b\u0443\u0442\u0430.", "validators", "bg");
t.add("This value should be equal to {{ compared_value }}.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 \u0440\u0430\u0432\u043d\u0430 \u043d\u0430 {{ compared_value }}.", "validators", "bg");
t.add("This value should be greater than {{ compared_value }}.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043f\u043e-\u0433\u043e\u043b\u044f\u043c\u0430 \u043e\u0442 {{ compared_value }}.", "validators", "bg");
t.add("This value should be greater than or equal to {{ compared_value }}.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043f\u043e-\u0433\u043e\u043b\u044f\u043c\u0430 \u0438\u043b\u0438 \u0440\u0430\u0432\u043d\u0430 \u043d\u0430 {{ compared_value }}.", "validators", "bg");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 \u0438\u0434\u0435\u043d\u0442\u0438\u0447\u043d\u0430 \u0441 {{ compared_value_type }} {{ compared_value }}.", "validators", "bg");
t.add("This value should be less than {{ compared_value }}.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043f\u043e-\u043c\u0430\u043b\u043a\u0430 {{ compared_value }}.", "validators", "bg");
t.add("This value should be less than or equal to {{ compared_value }}.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043f\u043e-\u043c\u0430\u043b\u043a\u0430 \u0438\u043b\u0438 \u0440\u0430\u0432\u043d\u0430 \u043d\u0430 {{ compared_value }}.", "validators", "bg");
t.add("This value should not be equal to {{ compared_value }}.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u043d\u0435 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 \u0440\u0430\u0432\u043d\u0430 \u043d\u0430 {{ compared_value }}.", "validators", "bg");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u043d\u0435 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0431\u044a\u0434\u0435 \u0438\u0434\u0435\u043d\u0442\u0438\u0447\u043d\u0430 \u0441 {{ compared_value_type }} {{ compared_value }}.", "validators", "bg");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "\u0418\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435\u0442\u043e \u0435 \u0441 \u0442\u0432\u044a\u0440\u0434\u0435 \u0433\u043e\u043b\u044f\u043c\u0430 \u043f\u0440\u043e\u043f\u043e\u0440\u0446\u0438\u044f ({{ ratio }}). \u041c\u0430\u043a\u0441\u0438\u043c\u0430\u043b\u043d\u0430\u0442\u0430 \u043f\u0440\u043e\u043f\u043e\u0440\u0446\u0438\u044f \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0435 {{ max_ratio }}.", "validators", "bg");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "\u0418\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435\u0442\u043e \u0435 \u0441 \u0442\u0432\u044a\u0440\u0434\u0435 \u043c\u0430\u043b\u043a\u0430 \u043f\u0440\u043e\u043f\u043e\u0440\u0446\u0438\u044f ({{ ratio }}). \u041c\u0438\u043d\u0438\u043c\u0430\u043b\u043d\u0430\u0442\u0430 \u043f\u0440\u043e\u043f\u043e\u0440\u0446\u0438\u044f \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0435 {{ min_ratio }}.", "validators", "bg");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "\u0418\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435\u0442\u043e \u0435 \u043a\u0432\u0430\u0434\u0440\u0430\u0442 ({{ width }}x{{ height }}px). \u0422\u0430\u043a\u0438\u0432\u0430 \u0438\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u044f \u043d\u0435 \u0441\u0430 \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u0438.", "validators", "bg");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "\u0418\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435\u0442\u043e \u0435 \u0441 \u043f\u0435\u0439\u0437\u0430\u0436\u043d\u0430 \u043e\u0440\u0438\u0435\u043d\u0442\u0430\u0446\u0438\u044f ({{ width }}x{{ height }}px). \u0418\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u044f \u0441 \u0442\u0430\u043a\u0430\u0432\u0430 \u043e\u0440\u0438\u0435\u043d\u0442\u0430\u0446\u0438\u044f \u043d\u0435 \u0441\u0430 \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u0438.", "validators", "bg");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "\u0418\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u0435\u0442\u043e \u0435 \u0441 \u043f\u043e\u0440\u0442\u0440\u0435\u0442\u043d\u0430 \u043e\u0440\u0438\u0435\u043d\u0442\u0430\u0446\u0438\u044f ({{ width }}x{{ height }}px). \u0418\u0437\u043e\u0431\u0440\u0430\u0436\u0435\u043d\u0438\u044f \u0441 \u0442\u0430\u043a\u0430\u0432\u0430 \u043e\u0440\u0438\u0435\u043d\u0442\u0430\u0446\u0438\u044f \u043d\u0435 \u0441\u0430 \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u0438.", "validators", "bg");
t.add("An empty file is not allowed.", "\u041f\u0440\u0430\u0437\u043d\u0438 \u0444\u0430\u0439\u043b\u043e\u0432\u0435 \u043d\u0435 \u0441\u0430 \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u0438.", "validators", "bg");
t.add("The host could not be resolved.", "\u0425\u043e\u0441\u0442\u044a\u0442 \u0435 \u043d\u0435\u0434\u043e\u0441\u0442\u044a\u043f\u0435\u043d.", "validators", "bg");
t.add("This value does not match the expected {{ charset }} charset.", "\u0421\u0442\u043e\u0439\u043d\u043e\u0441\u0442\u0442\u0430 \u043d\u0435 \u0441\u044a\u0432\u043f\u0430\u0434\u0430 \u0441 {{ charset }}.", "validators", "bg");
t.add("This is not a valid Business Identifier Code (BIC).", "\u041d\u0435\u0432\u0430\u043b\u0438\u0434\u0435\u043d \u0431\u0438\u0437\u043d\u0435\u0441 \u0438\u0434\u0435\u043d\u0442\u0438\u0444\u0438\u043a\u0430\u0446\u0438\u043e\u043d\u0435\u043d \u043a\u043e\u0434 (BIC).", "validators", "bg");
t.add("Error", "\u0413\u0440\u0435\u0448\u043a\u0430", "validators", "bg");
t.add("This form should not contain extra fields.", "\u0422\u0430\u0437\u0438 \u0444\u043e\u0440\u043c\u0430 \u043d\u0435 \u0442\u0440\u044f\u0431\u0432\u0430 \u0434\u0430 \u0441\u044a\u0434\u044a\u0440\u0436\u0430 \u0434\u043e\u043f\u044a\u043b\u043d\u0438\u0442\u0435\u043b\u043d\u0438 \u043f\u043e\u043b\u0435\u0442\u0430.", "validators", "bg");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "\u041a\u0430\u0447\u0435\u043d\u0438\u044f\u0442 \u0444\u0430\u0439\u043b \u0435 \u0442\u0432\u044a\u0440\u0434\u0435 \u0433\u043e\u043b\u044f\u043c. \u041c\u043e\u043b\u044f, \u043e\u043f\u0438\u0442\u0430\u0439\u0442\u0435 \u0434\u0430 \u043a\u0430\u0447\u0438\u0442\u0435 \u043f\u043e-\u043c\u0430\u043b\u044a\u043a \u0444\u0430\u0439\u043b.", "validators", "bg");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "\u041d\u0435\u0432\u0430\u043b\u0438\u0434\u0435\u043d CSRF \u0442\u043e\u043a\u0435\u043d. \u041c\u043e\u043b\u044f, \u043e\u043f\u0438\u0442\u0430\u0439\u0442\u0435 \u0434\u0430 \u0438\u0437\u043f\u0440\u0430\u0442\u0438\u0442\u0435 \u0444\u043e\u0440\u043c\u0430\u0442\u0430 \u043e\u0442\u043d\u043e\u0432\u043e.", "validators", "bg");
})(Translator);
