(function (t) {
// nn
t.add("This value should be false.", "Verdien skulle ha vore tom\/nei.", "validators", "nn");
t.add("This value should be true.", "Verdien skulla ha vore satt\/ja.", "validators", "nn");
t.add("This value should be of type {{ type }}.", "Verdien m\u00e5 vere av typen {{ type }}.", "validators", "nn");
t.add("This value should be blank.", "Verdien skal vere blank.", "validators", "nn");
t.add("The value you selected is not a valid choice.", "Verdien du valde er ikkje gyldig.", "validators", "nn");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Du m\u00e5 gjere minst {{ limit }} val.", "validators", "nn");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Du kan maksimalt gjere {{ limit }} val.", "validators", "nn");
t.add("One or more of the given values is invalid.", "Ein eller fleire av dei opplyste verdiane er ugyldige.", "validators", "nn");
t.add("This field was not expected.", "Dette feltet var ikke forventa.", "validators", "nn");
t.add("This field is missing.", "Dette feltet mangler.", "validators", "nn");
t.add("This value is not a valid date.", "Verdien er ikkje ein gyldig dato.", "validators", "nn");
t.add("This value is not a valid datetime.", "Verdien er ikkje ein gyldig dato og tid.", "validators", "nn");
t.add("This value is not a valid email address.", "Verdien er ikkje ei gyldig e-postadresse.", "validators", "nn");
t.add("The file could not be found.", "Fila er ikkje funnen.", "validators", "nn");
t.add("The file is not readable.", "Fila kan ikkje lesast.", "validators", "nn");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Fila er for stor ({{ size }} {{ suffix }}). Maksimal storleik er {{ limit }} {{ suffix }}.", "validators", "nn");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Mime-typen av fila er ugyldig ({{ type }}). Tillatne mime-typar er {{ types }}.", "validators", "nn");
t.add("This value should be {{ limit }} or less.", "Verdien m\u00e5 vere {{ limit }} eller mindre.", "validators", "nn");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Verdien er for lang. Den m\u00e5 vere {{ limit }} bokstavar eller mindre.", "validators", "nn");
t.add("This value should be {{ limit }} or more.", "Verdien m\u00e5 vere {{ limit }} eller meir.", "validators", "nn");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Verdien er for kort. Den m\u00e5 ha {{ limit }} teikn eller fleire.", "validators", "nn");
t.add("This value should not be blank.", "Verdien kan ikkje vere blank.", "validators", "nn");
t.add("This value should not be null.", "Verdien kan ikkje vere tom (null).", "validators", "nn");
t.add("This value should be null.", "Verdien m\u00e5 vere tom (null).", "validators", "nn");
t.add("This value is not valid.", "Verdien er ikkje gyldig.", "validators", "nn");
t.add("This value is not a valid time.", "Verdien er ikkje ei gyldig tidseining.", "validators", "nn");
t.add("This value is not a valid URL.", "Verdien er ikkje ein gyldig URL.", "validators", "nn");
t.add("The two values should be equal.", "Dei to verdiane m\u00e5 vere like.", "validators", "nn");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Fila er for stor. Den maksimale storleiken er {{ limit }} {{ suffix }}.", "validators", "nn");
t.add("The file is too large.", "Fila er for stor.", "validators", "nn");
t.add("The file could not be uploaded.", "Fila kunne ikkje bli lasta opp.", "validators", "nn");
t.add("This value should be a valid number.", "Verdien m\u00e5 vere eit gyldig tal.", "validators", "nn");
t.add("This file is not a valid image.", "Fila er ikkje eit gyldig bilete.", "validators", "nn");
t.add("This is not a valid IP address.", "Dette er ikkje ei gyldig IP-adresse.", "validators", "nn");
t.add("This value is not a valid language.", "Verdien er ikkje eit gyldig spr\u00e5k.", "validators", "nn");
t.add("This value is not a valid locale.", "Verdien er ikkje ein gyldig lokalitet (spr\u00e5k\/region).", "validators", "nn");
t.add("This value is not a valid country.", "Verdien er ikkje eit gyldig land.", "validators", "nn");
t.add("This value is already used.", "Verdien er allereie i bruk.", "validators", "nn");
t.add("The size of the image could not be detected.", "Storleiken p\u00e5 biletet kunne ikkje oppdagast.", "validators", "nn");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "Biletbreidda er for stor, ({{ width }} pikslar). Tillaten maksimumsbreidde er {{ max_width }} pikslar.", "validators", "nn");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "Biletbreidda er for liten, ({{ width }} pikslar). Forventa minimumsbreidde er {{ min_width }} pikslar.", "validators", "nn");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "Bileth\u00f8gda er for stor, ({{ height }} pikslar). Tillaten maksimumsh\u00f8gde er {{ max_height }} pikslar.", "validators", "nn");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "Billeth\u00f8gda er for l\u00e5g, ({{ height }} pikslar). Forventa minimumsh\u00f8gde er {{ min_height }} pikslar.", "validators", "nn");
t.add("This value should be the user's current password.", "Verdien m\u00e5 vere brukaren sitt noverande passord.", "validators", "nn");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Verdien m\u00e5 vere n\u00f8yaktig {{ limit }} teikn.", "validators", "nn");
t.add("The file was only partially uploaded.", "Fila vart berre delvis lasta opp.", "validators", "nn");
t.add("No file was uploaded.", "Inga fil vart lasta opp.", "validators", "nn");
t.add("No temporary folder was configured in php.ini.", "F\u00f8rebels mappe (tmp) er ikkje konfigurert i php.ini.", "validators", "nn");
t.add("Cannot write temporary file to disk.", "Kan ikkje skrive f\u00f8rebels fil til disk.", "validators", "nn");
t.add("A PHP extension caused the upload to fail.", "Ei PHP-udviding for\u00e5rsaka feil under opplasting.", "validators", "nn");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Denne samlinga m\u00e5 innehalde {{ limit }} element eller meir.|Denne samlinga m\u00e5 innehalde {{ limit }} element eller meir.", "validators", "nn");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Denne samlinga m\u00e5 innehalde {{ limit }} element eller f\u00e6rre.|Denne samlinga m\u00e5 innehalde {{ limit }} element eller f\u00e6rre.", "validators", "nn");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Denne samlinga m\u00e5 innehalde n\u00f8yaktig {{ limit }} element.|Denne samlinga m\u00e5 innehalde n\u00f8yaktig {{ limit }} element.", "validators", "nn");
t.add("Invalid card number.", "Ugyldig kortnummer.", "validators", "nn");
t.add("Unsupported card type or invalid card number.", "Korttypen er ikkje st\u00f8tta, eller kortnummeret er ugyldig.", "validators", "nn");
t.add("This form should not contain extra fields.", "Feltgruppa m\u00e5 ikkje innehalde ekstra felt.", "validators", "nn");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Fila du lasta opp var for stor. Last opp ei mindre fil.", "validators", "nn");
t.add("The CSRF token is invalid.", "CSRF-n\u00f8kkelen er ikkje gyldig.", "validators", "nn");
})(Translator);
