(function (t) {
// fr
t.add("security.password_label", "Mot de passe", "messages", "fr");
t.add("security.confirm_password_label", "Confirmation du mot de passe", "messages", "fr");
t.add("security.create_password_title", "Je cr\u00e9e mon compte", "messages", "fr");
t.add("security.reset_password_confirm", "Cr\u00e9er mon compte", "messages", "fr");
t.add("security.create_password.invalid_link", "Sorry, your link expired.", "messages", "fr");
t.add("mail.title.reset_password", "R\u00e9initialisation de votre mot de passe", "messages", "fr");
t.add("security.request_reset_title", "R\u00e9initialiser mon mot de passe", "messages", "fr");
t.add("security.reset_password_title", "R\u00e9initialisation du mot de passe", "messages", "fr");
t.add("security.no_user_found_by_email", "Nous n'avons pas pu trouver de compte associ\u00e9 \u00e0", "messages", "fr");
t.add("security.connection_title", "Connexion", "messages", "fr");
t.add("security.forgotten_password", "Mot de passe oubli\u00e9 ?", "messages", "fr");
t.add("security.remember_me", "Se souvenir de moi", "messages", "fr");
t.add("security.connection_confirm", "Connexion", "messages", "fr");
t.add("security.mail_label", "Adresse e-mail professionnelle", "messages", "fr");
t.add("security.user_not_activated", "Votre compte n'a pas encore \u00e9t\u00e9 activ\u00e9. Consultez vos mails pour finaliser la cr\u00e9ation de votre compte.", "messages", "fr");
t.add("menu.activities", "Nos activit\u00e9s", "messages", "fr");
t.add("menu.activity", "Activit\u00e9", "messages", "fr");
t.add("security.password_placeholder", "******", "messages", "fr");
t.add("security.welcome_account", "Merci pour votre inscription ! Vous allez bient\u00f4t pouvoir acc\u00e9der \u00e0 l\u2019ensemble des exp\u00e9riences Envisport. Pour mieux r\u00e9pondre \u00e0 vos attentes et vous offrir des exp\u00e9riences personnalis\u00e9es, faisons plus ample\u00a0connaissance.", "messages", "fr");
t.add("security.gender_label", "Civilit\u00e9", "messages", "fr");
t.add("security.last_name_label", "Nom*", "messages", "fr");
t.add("security.first_name_label", "Pr\u00e9nom*", "messages", "fr");
t.add("security.continue", "Continuer", "messages", "fr");
t.add("security.last_name_placeholder", "Nom", "messages", "fr");
t.add("security.first_name_placeholder", "Pr\u00e9nom", "messages", "fr");
t.add("security.my_info_title_1", "Votre identit\u00e9 (1\/2)", "messages", "fr");
t.add("security.my_info_title_2", "Votre identit\u00e9 (2\/2)", "messages", "fr");
t.add("security.my_info_title_3", "Votre pratique", "messages", "fr");
t.add("security.my_info_title_4", "Vos habitudes", "messages", "fr");
t.add("security.my_info_title_5", "Vos envies", "messages", "fr");
t.add("security.my_info_title_6", "Veuillez patienter...", "messages", "fr");
t.add("utils.skip", "Passer cette \u00e9tape", "messages", "fr");
t.add("menu.profile", "Mon profil", "messages", "fr");
t.add("menu.logout", "Se d\u00e9connecter", "messages", "fr");
t.add("user_info.gender", "Civilit\u00e9", "messages", "fr");
t.add("user_info.gender.male", "Monsieur", "messages", "fr");
t.add("user_info.gender.female", "Madame", "messages", "fr");
t.add("user_info.birthday", "Date de naissance", "messages", "fr");
t.add("user_info.birthday_placeholder", "JJ\/MM\/AAAA", "messages", "fr");
t.add("user_info.post_code", "Code postal", "messages", "fr");
t.add("user_info.practice_level", "A quelle fr\u00e9quence pratiquez-vous une activit\u00e9 sportive ou de bien-\u00eatre ?", "messages", "fr");
t.add("user_info.availabilities", "Vous \u00eates plut\u00f4t disponible pour entretenir votre forme ?", "messages", "fr");
t.add("user_info.availabilities_placeholder", "Disponibilit\u00e9s", "messages", "fr");
t.add("user_info.availability.before_work", "Avant d\u2019aller au travail", "messages", "fr");
t.add("user_info.availability.lunch", "A la pause d\u00e9jeuner", "messages", "fr");
t.add("user_info.availability.after_work", "Apr\u00e8s le travail", "messages", "fr");
t.add("user_info.availability.weekend", "Le week-end", "messages", "fr");
t.add("user_info.transport_type", "Comment allez vous travaillez ?", "messages", "fr");
t.add("user_info.transport_placeholder", "Mobilit\u00e9", "messages", "fr");
t.add("user_info.transport.walk", "A pied", "messages", "fr");
t.add("user_info.transport.bike", "A v\u00e9lo ou en trotinette", "messages", "fr");
t.add("user_info.transport.public", "En transport en commun", "messages", "fr");
t.add("user_info.transport.car", "En v\u00e9hicule motoris\u00e9", "messages", "fr");
t.add("user_info.experience_types", "Quel(s) type(s) d\u2019exp\u00e9rience(s) recherchez-vous ?", "messages", "fr");
t.add("user_info.experiences_placeholder", "Types d'exp\u00e9rience", "messages", "fr");
t.add("user_info.experience.relax", "Se relaxer", "messages", "fr");
t.add("user_info.experience.move", "Se bouger", "messages", "fr");
t.add("user_info.experience.romp", "Se d\u00e9fouler", "messages", "fr");
t.add("user_info.interests", "Le plus important pour vous c\u2019est ?", "messages", "fr");
t.add("user_info.interests_placeholder", "Objectifs", "messages", "fr");
t.add("user_info.interest.relax", "Vous d\u00e9tendre", "messages", "fr");
t.add("user_info.interest.health", "Entretenir votre sant\u00e9", "messages", "fr");
t.add("user_info.interest.amuse", "Vous amuser", "messages", "fr");
t.add("user_info.interest.friends", "Faire des rencontres", "messages", "fr");
t.add("user_info.interest.development", "Le d\u00e9veloppement physique", "messages", "fr");
t.add("user_info.interest.colleagues", "Partager un bon moment entre coll\u00e8gues", "messages", "fr");
t.add("user_info.lunch_break_time", "Votre temps de pause d\u00e9jeuner ", "messages", "fr");
t.add("user_info.intensity_level", "Quelle est le niveau d\u2019intensit\u00e9 des activit\u00e9s pratiqu\u00e9es ?", "messages", "fr");
t.add("user_info.intensity_level_placeholder", "Niveau d'intensit\u00e9", "messages", "fr");
t.add("user_info.intensity_level.low", "Faible", "messages", "fr");
t.add("user_info.intensity_level.high", "Elev\u00e9", "messages", "fr");
t.add("user_info.club_member", "Etes-vous inscrit(e) dans un club, une salle ?", "messages", "fr");
t.add("choice.boolean.no", "Non", "messages", "fr");
t.add("choice.boolean.yes", "Oui", "messages", "fr");
t.add("user_info.practice_level.never", "Jamais", "messages", "fr");
t.add("user_info.practice_level.occasional", "Occasionnel", "messages", "fr");
t.add("user_info.practice_level.often", "Souvent", "messages", "fr");
t.add("user_info.practice_level.very_often", "Tr\u00e8s souvent", "messages", "fr");
t.add("user.first_name", "Pr\u00e9nom*", "messages", "fr");
t.add("user.last_name", "Nom*", "messages", "fr");
t.add("common.server_error", "D\u00e9faut temporaire du serveur", "messages", "fr");
t.add("security.create_account_title", "Cr\u00e9ation de votre compte", "messages", "fr");
t.add("security.my_info_title", "Votre identit\u00e9", "messages", "fr");
t.add("user_info.never", "Jamais", "messages", "fr");
t.add("user_info.never_detail", "0 \u00e0 1 fois par an", "messages", "fr");
t.add("user_info.occasionally", "Occasionnel", "messages", "fr");
t.add("user_info.occasionally_details", "1 \u00e0 2 fois par trimestre", "messages", "fr");
t.add("user_info.often", "Souvent", "messages", "fr");
t.add("user_info.often_details", "1 \u00e0 2 fois par mois", "messages", "fr");
t.add("user_info.very_often", "Tr\u00e8s souvent", "messages", "fr");
t.add("user_info.very_often_details", "1 \u00e0 2 fois par semaine", "messages", "fr");
t.add("user.edit", "Modifier", "messages", "fr");
t.add("profile.general", "Informations g\u00e9n\u00e9rales", "messages", "fr");
t.add("profile.gender", "Civilit\u00e9", "messages", "fr");
t.add("profile.postCode", "Code postal", "messages", "fr");
t.add("profile.birthday", "Anniversaire", "messages", "fr");
t.add("profile.mail", "E-mail", "messages", "fr");
t.add("profile.practice", "Pratique sportive", "messages", "fr");
t.add("profile.frequency", "Fr\u00e9quence", "messages", "fr");
t.add("profile.intensity", "Intensit\u00e9", "messages", "fr");
t.add("profile.club", "Inscrit en club", "messages", "fr");
t.add("profile.yes", "Oui", "messages", "fr");
t.add("profile.habits", "Habitudes", "messages", "fr");
t.add("profile.schedule", "Disponibilit\u00e9s", "messages", "fr");
t.add("profile.transport", "Mobilit\u00e9", "messages", "fr");
t.add("profile.lunchbreak", "Pause d\u00e9jeuner", "messages", "fr");
t.add("profile.referents", "Ambassadeurs", "messages", "fr");
t.add("profile.activity_type", "Type d'activit\u00e9", "messages", "fr");
t.add("profile.goal", "Objectif", "messages", "fr");
t.add("profile.referent", "Ambassadeur", "messages", "fr");
t.add("profile.interests", "Envies", "messages", "fr");
t.add("profile.no", "Non", "messages", "fr");
t.add("activities.view_link", "Voir", "messages", "fr");
t.add("activities.activities", "activit\u00e9s", "messages", "fr");
t.add("activity.location", "O\u00f9 ?", "messages", "fr");
t.add("activity.location_placeholder", "O\u00f9 \u00eates-vous ?", "messages", "fr");
t.add("activity.category", "Cat\u00e9gories d'activit\u00e9s", "messages", "fr");
t.add("activity.name", "Activit\u00e9", "messages", "fr");
t.add("activity.days", "Jours", "messages", "fr");
t.add("activity.slot", "Cr\u00e9neaux", "messages", "fr");
t.add("form.activity.name", "Activit\u00e9", "messages", "fr");
t.add("search_form.lundi", "Lundi", "messages", "fr");
t.add("search_form.mardi", "Mardi", "messages", "fr");
t.add("search_form.mercredi", "Mercredi", "messages", "fr");
t.add("search_form.jeudi", "Jeudi", "messages", "fr");
t.add("search_form.vendredi", "Vendredi", "messages", "fr");
t.add("search_form.week-end", "Week-End", "messages", "fr");
t.add("search_form.matin", "Matin", "messages", "fr");
t.add("search_form.midi", "Midi", "messages", "fr");
t.add("search_form.apres-midi", "Apr\u00e8s-midi", "messages", "fr");
t.add("search_form.soir", "Soir", "messages", "fr");
t.add("security.terms_of_use_label", "J'ai lu et j'accepte les conditions g\u00e9n\u00e9rales d'utilisation.", "messages", "fr");
t.add("common.file_too_big", "Le fichier est trop volumineux. Taille max. 10Mo", "messages", "fr");
t.add("activity.activity", "Activit\u00e9", "messages", "fr");
t.add("activity.price", "Prix", "messages", "fr");
t.add("profile.mailto", "Contacter vos ambassadeurs", "messages", "fr");
t.add("common.around_me", "Autour de moi", "messages", "fr");
t.add("common.no_activity_found", "Aucune activit\u00e9 ne correspond \u00e0 votre recherche.", "messages", "fr");
t.add("activity.filter_form.map_view", "VUE CARTE", "messages", "fr");
t.add("activity.filter_form.list_view", "VUE LISTE", "messages", "fr");
t.add("common.reset", "Effacer", "messages", "fr");
t.add("common.apply", "Appliquer", "messages", "fr");
t.add("mail.title.activity_share", " t\u2019invite \u00e0 d\u00e9couvrir l\u2019activit\u00e9 ", "messages", "fr");
t.add("activity.share.button", "Partager l'activit\u00e9", "messages", "fr");
t.add("activity.share", "\u00c0 qui souhaitez vous partager cette activit\u00e9 ?", "messages", "fr");
t.add("form.close", "Annuler", "messages", "fr");
t.add("form.submit", "Envoyer", "messages", "fr");
t.add("common.access_bo", "Acc\u00e9der au back-office", "messages", "fr");
t.add("common.access_client_space", "Acc\u00e8s espace Ambassadeur", "messages", "fr");
t.add("activity.category.form", "Cat\u00e9gories", "messages", "fr");
t.add("filter.toggle", "Filtres", "messages", "fr");
t.add("filter.reset", "Supprimer tous les filtres", "messages", "fr");
t.add("activity.search", "Rechercher", "messages", "fr");
t.add("common.show_more", "Voir plus", "messages", "fr");
t.add("activity.name_placeholder", "Pas de pr\u00e9f\u00e9rences", "messages", "fr");
t.add("security.request_reset_button", "R\u00e9initialiser", "messages", "fr");
t.add("activity.no_activity_title", "Activit\u00e9s en visio live", "messages", "fr");
t.add("activity.no_activity_subtitle", "Activit\u00e9s en pr\u00e9sentiel sous 3 mois sur demande", "messages", "fr");
t.add("form.contact_us", "Contactez-nous", "messages", "fr");
t.add("security.quit", "Terminer", "messages", "fr");
t.add("user.edit_profile", "Modifier les informations", "messages", "fr");
t.add("user.change_password", "Changer le mot de passe", "messages", "fr");
t.add("security.change_password_title", "Je change mon mot de passe", "messages", "fr");
t.add("security.old_password_label", "Mot de passe actuel", "messages", "fr");
t.add("security.new_password_label", "Nouveau mot de passe", "messages", "fr");
t.add("security.confirm_new_password_label", "Confirmation du nouveau mot de passe", "messages", "fr");
t.add("security.change_password_confirm", "Valider", "messages", "fr");
t.add("profile.code", "Code RegionDO", "messages", "fr");
t.add("profile.mycode", "Mon code cr\u00e9dit Envisport", "messages", "fr");
t.add("profile.stats", "Mon \u00e9chelle d'exp\u00e9rience", "messages", "fr");
t.add("profile.infos", "Mes informations", "messages", "fr");
t.add("user.code", "Mon code", "messages", "fr");
t.add("profile.button.close", "Fermer", "messages", "fr");
t.add("profile.button.copy", "Copier le code", "messages", "fr");
t.add("user.email", "Adresse mail", "messages", "fr");
})(Translator);
