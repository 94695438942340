(function (t) {
// mn
t.add("This value should be false.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0431\u0443\u0440\u0443\u0443 \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This value should be true.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u04af\u043d\u044d\u043d \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This value should be of type {{ type }}.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430  {{ type }} -\u043d \u0442\u04e9\u0440\u04e9\u043b \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This value should be blank.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0445\u043e\u043e\u0441\u043e\u043d \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("The value you selected is not a valid choice.", "\u0421\u043e\u043d\u0433\u043e\u0441\u043e\u043d \u0443\u0442\u0433\u0430 \u0431\u0443\u0440\u0443\u0443 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "\u0425\u0430\u043c\u0433\u0438\u0439\u043d \u0431\u0430\u0433\u0430\u0434\u0430\u0430 {{ limit }} \u0443\u0442\u0433\u0430 \u0441\u043e\u043d\u0433\u043e\u0433\u0434\u0441\u043e\u043d \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "\u0425\u0430\u043c\u0433\u0438\u0439\u043d \u0438\u0445\u0434\u044d\u044d {{ limit }} \u0443\u0442\u0433\u0430 \u0441\u043e\u043d\u0433\u043e\u0433\u0434\u043e\u0445 \u0431\u043e\u043b\u043e\u043c\u0436\u0442\u043e\u0439.", "validators", "mn");
t.add("One or more of the given values is invalid.", "\u04e8\u0433\u04e9\u0433\u0434\u0441\u04e9\u043d \u043d\u044d\u0433 \u044d\u0441\u0432\u044d\u043b \u043d\u044d\u0433\u044d\u044d\u0441 \u043e\u043b\u043e\u043d \u0443\u0442\u0433\u0430 \u0431\u0443\u0440\u0443\u0443 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This field was not expected.", "\u042d\u043d\u044d \u0442\u0430\u043b\u0431\u0430\u0440 \u043d\u044c \u0445\u04af\u043b\u044d\u044d\u0433\u0434\u044d\u0436 \u0431\u0430\u0439\u0441\u0430\u043d \u044e\u043c.", "validators", "mn");
t.add("This field is missing.", "\u042d\u043d\u044d \u0442\u0430\u043b\u0431\u0430\u0440 \u043d\u044c \u0430\u043b\u0433\u0430 \u0431\u043e\u043b\u0441\u043e\u043d \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is not a valid date.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0431\u0443\u0440\u0443\u0443 date \u0442\u04e9\u0440\u04e9\u043b \u0431\u0430\u0439\u043d\u0430 .", "validators", "mn");
t.add("This value is not a valid datetime.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0431\u0443\u0440\u0443\u0443 \u0446\u0430\u0433 \u0442\u04e9\u0440\u04e9\u043b \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is not a valid email address.", "\u0418-\u043c\u0430\u0439\u043b \u0445\u0430\u044f\u0433 \u0431\u0443\u0440\u0443\u0443 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("The file could not be found.", "\u0424\u0430\u0439\u043b \u043e\u043b\u0434\u0441\u043e\u043d\u0433\u04af\u0439.", "validators", "mn");
t.add("The file is not readable.", "\u0424\u0430\u0439\u043b \u0443\u043d\u0448\u0438\u0433\u0434\u0430\u0445\u0443\u0439\u0446 \u0431\u0438\u0448 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "\u0424\u0430\u0439\u043b \u0445\u044d\u0442\u044d\u0440\u0445\u0438\u0439 \u0442\u043e\u043c \u0431\u0430\u0439\u043d\u0430 ({{ size }} {{ suffix }}). \u0417\u04e9\u0432\u0448\u04e9\u04e9\u0440\u04e9\u0433\u0434\u04e9\u0445 \u0434\u044d\u044d\u0434 \u0445\u044d\u043c\u0436\u044d\u044d  {{ limit }} {{ suffix }} \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "\u0424\u0430\u0439\u043b\u044b\u043d MIME-\u0442\u04e9\u0440\u04e9\u043b \u043d\u044c \u0431\u0443\u0440\u0443\u0443 \u0431\u0430\u0439\u043d\u0430 ({{ type }}). \u0417\u04e9\u0432\u0448\u04e9\u04e9\u0440\u04e9\u0433\u0434\u04e9\u0445 MIME-\u0442\u04e9\u0440\u043b\u04af\u04af\u0434 {{ types }}.", "validators", "mn");
t.add("This value should be {{ limit }} or less.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430  {{ limit }} \u044e\u043c\u0443\u0443 \u044d\u0441\u0432\u044d\u043b \u0431\u0430\u0433\u0430 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0445\u044d\u0442\u044d\u0440\u0445\u0438\u0439 \u0443\u0440\u0442 \u0431\u0430\u0439\u043d\u0430. {{ limit }} \u0442\u044d\u043c\u0434\u044d\u0433\u0442\u0438\u0439\u043d \u0443\u0440\u0442\u0442\u0430\u0439 \u044e\u043c\u0443\u0443 \u044d\u0441\u0432\u044d\u043b \u0431\u0430\u0433\u0430 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value should be {{ limit }} or more.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 {{ limit }} \u044e\u043c\u0443\u0443 \u044d\u0441\u0432\u044d\u043b \u0438\u0445 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0445\u044d\u0442\u044d\u0440\u0445\u0438\u0439 \u0431\u043e\u0433\u0438\u043d\u043e \u0431\u0430\u0439\u043d\u0430. {{ limit }} \u0442\u044d\u043c\u0434\u044d\u0433\u0442 \u044d\u0441\u0432\u044d\u043b \u0438\u0445 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value should not be blank.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0445\u043e\u043e\u0441\u043e\u043d \u0431\u0430\u0439\u0436 \u0431\u043e\u043b\u043e\u0445\u0433\u04af\u0439.", "validators", "mn");
t.add("This value should not be null.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 null \u0431\u0430\u0439\u0436 \u0431\u043e\u043b\u043e\u0445\u0433\u04af\u0439.", "validators", "mn");
t.add("This value should be null.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 null \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is not valid.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0431\u0443\u0440\u0443\u0443 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is not a valid time.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0431\u0443\u0440\u0443\u0443 \u0446\u0430\u0433 \u0442\u04e9\u0440\u04e9\u043b \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is not a valid URL.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0431\u0443\u0440\u0443\u0443 URL \u0431\u0430\u0439\u043d\u0430 .", "validators", "mn");
t.add("The two values should be equal.", "\u0425\u043e\u0451\u0440 \u0443\u0442\u0433\u0443\u0443\u0434 \u0438\u0436\u0438\u043b \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "\u0424\u0430\u0439\u043b \u0445\u044d\u0442\u044d\u0440\u0445\u0438\u0439 \u0442\u043e\u043c \u0431\u0430\u0439\u043d\u0430. \u0417\u04e9\u0432\u0448\u04e9\u04e9\u0440\u04e9\u0433\u0434\u04e9\u0445 \u0434\u044d\u044d\u0434 \u0445\u044d\u043c\u0436\u044d\u044d \u043d\u044c {{ limit }} {{ suffix }} \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("The file is too large.", "\u0424\u0430\u0439\u043b \u0445\u044d\u0442\u044d\u0440\u0445\u0438\u0439 \u0442\u043e\u043c \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("The file could not be uploaded.", "\u0424\u0430\u0439\u043b upload \u0445\u0438\u0439\u0433\u0434\u0441\u044d\u043d\u0433\u04af\u0439.", "validators", "mn");
t.add("This value should be a valid number.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0437\u04e9\u0432\u0445\u04e9\u043d \u0442\u043e\u043e \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is not a valid country.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u04af\u043d\u044d\u043d \u0431\u043e\u0434\u0438\u0442 \u0443\u043b\u0441 \u0431\u0438\u0448 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This file is not a valid image.", "\u0424\u0430\u0439\u043b \u0437\u0443\u0440\u0430\u0433 \u0431\u0438\u0448 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This is not a valid IP address.", "IP \u0445\u0430\u044f\u0433 \u0437\u04e9\u0432 \u0431\u0438\u0448 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is not a valid language.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u04af\u043d\u044d\u043d \u0437\u04e9\u0432 \u0445\u044d\u043b \u0431\u0438\u0448 \u0431\u0430\u0439\u043d\u0430 .", "validators", "mn");
t.add("This form should not contain extra fields.", "\u0424\u043e\u0440\u043c \u043d\u044d\u043c\u044d\u043b\u0442 \u0442\u0430\u043b\u0431\u0430\u0440 \u0431\u0430\u0433\u0442\u0430\u0430\u0445 \u0431\u043e\u043b\u043e\u043c\u0436\u0433\u04af\u0439.", "validators", "mn");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Upload \u0445\u0438\u0439\u0441\u044d\u043d \u0444\u0430\u0439\u043b \u0445\u044d\u0442\u044d\u0440\u0445\u0438\u0439 \u0442\u043e\u043c \u0431\u0430\u0439\u043d\u0430. \u0411\u0430\u0433\u0430 \u0445\u044d\u043c\u0436\u044d\u044d\u0442\u044d\u0439 \u0444\u0430\u0439\u043b \u043e\u0440\u0443\u0443\u043b\u043d\u0430 \u0443\u0443.", "validators", "mn");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "CSRF token \u0431\u0443\u0440\u0443\u0443 \u0431\u0430\u0439\u043d\u0430. \u0424\u043e\u0440\u043c\u043e\u043e \u0434\u0430\u0445\u0438\u043d \u0438\u043b\u0433\u044d\u044d\u043d\u044d \u04af\u04af.", "validators", "mn");
})(Translator);
