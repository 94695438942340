(function (t) {
// fr
t.add("company.create", "Cr\u00e9ation de client", "company", "fr");
t.add("company.form.name", "Nom de l'entreprise", "company", "fr");
t.add("company.form.siret", "Num\u00e9ro de SIRET", "company", "fr");
t.add("company.form.address", "Adresse", "company", "fr");
t.add("company.form.phone", "Num\u00e9ro de t\u00e9l\u00e9phone", "company", "fr");
t.add("company.form.website", "Site internet : ", "company", "fr");
t.add("company.form.employees.number", "Nombre d'employ\u00e9s : ", "company", "fr");
t.add("company.form.logo", "Logo de l'entreprise : ", "company", "fr");
t.add("company.form.cover", "Image de couverture : ", "company", "fr");
t.add("company.form.submit", "Valider", "company", "fr");
t.add("company.form.cancel", "Annuler", "company", "fr");
t.add("company.form.employees.number.max", "Nombre d'adh\u00e9rents maximum : ", "company", "fr");
t.add("company.list", "Liste de clients : ", "company", "fr");
t.add("company.form.referents", "Utilisateurs r\u00e9f\u00e9rents : ", "company", "fr");
t.add("company.option.create.user", "Ou cr\u00e9ez un utilisateur : ", "company", "fr");
t.add("company.user.create", "Cr\u00e9ation d'utilisateur ", "company", "fr");
t.add("company.form.postCode", "Code postal", "company", "fr");
t.add("company.form.city", "Ville", "company", "fr");
t.add("company.add", "Ajouter un client", "company", "fr");
t.add("company.list.search", "Filtrer", "company", "fr");
t.add("title.company.list", "Liste des clients", "company", "fr");
t.add("titles.referent_user.add", "Ajouter un ambassadeur", "company", "fr");
t.add("company.referent.mail", "Email", "company", "fr");
t.add("common.save", "Ajouter", "company", "fr");
t.add("common.changes_saved", "Changements sauvegard\u00e9s avec succ\u00e8s", "company", "fr");
t.add("create", "Ajouter un client", "company", "fr");
t.add("edit", "\u00c9diter un client", "company", "fr");
t.add("title.company.detail", "Informations client", "company", "fr");
t.add("company.address", "Adresse", "company", "fr");
t.add("company.employe.number", "Nombre d'employ\u00e9s", "company", "fr");
t.add("company.phonenumber", "T\u00e9l\u00e9phone", "company", "fr");
t.add("company.website", "Site ", "company", "fr");
t.add("company.siret", "SIRET", "company", "fr");
t.add("company.employe.number.max", "Nombre de b\u00e9n\u00e9ficiaires Maximum", "company", "fr");
t.add("user.name", "Nom", "company", "fr");
t.add("user.mail", "Adresse e-mail", "company", "fr");
t.add("user.edit", "Modifier", "company", "fr");
t.add("user.sendmail", "Envoyer un e-mail", "company", "fr");
t.add("users.add", "Ajouter un b\u00e9n\u00e9ficiaire", "company", "fr");
t.add("users.list", "Liste des b\u00e9n\u00e9ficiaires", "company", "fr");
t.add("titles.beneficiary_user.add", "Ajouter un b\u00e9n\u00e9ficiaire", "company", "fr");
t.add("users.add.referent", "Ajouter un ambassadeur", "company", "fr");
t.add("user.delete", "Supprimer l'ambassadeur", "company", "fr");
t.add("company.documents", "Documents disponibles", "company", "fr");
t.add("document.filename", "Nom du fichier", "company", "fr");
t.add("document.download", "T\u00e9l\u00e9charger", "company", "fr");
t.add("document.add", "Ajouter un document", "company", "fr");
t.add("user.phone", "Num\u00e9ro", "company", "fr");
t.add("common.skip", "Passer cette \u00e9tape", "company", "fr");
t.add("users.import", "Importer des b\u00e9n\u00e9ficiaires", "company", "fr");
t.add("titles.import.user", "Import de b\u00e9n\u00e9ficiaires", "company", "fr");
t.add("company.level_title.champion", "Champion", "company", "fr");
t.add("company.level_title.to_become", "En devenir", "company", "fr");
t.add("company.level_title.newbie", "Apprenti", "company", "fr");
t.add("company.level_title.super", "Super", "company", "fr");
t.add("company.level_title.hero", "H\u00e9ro", "company", "fr");
t.add("company.level_no_sessions", "Inscription", "company", "fr");
t.add("company.level_session", "s\u00e9ance", "company", "fr");
t.add("company.level_sessions", "s\u00e9ances", "company", "fr");
t.add("common.back", "Retour", "company", "fr");
t.add("titles.user.add_code", "Ajouter un code RegionDo", "company", "fr");
t.add("common.add_code", "Ajouter un code RegionDo", "company", "fr");
t.add("common.edit_code", "Modifier le code RegionDo", "company", "fr");
t.add("user.export", "Exporter", "company", "fr");
t.add("titles.user.edit_code", "Editer le code RegionDo", "company", "fr");
t.add("common.edit", "Editer", "company", "fr");
t.add("user.codeRegionDo", "Code RegionDo", "company", "fr");
})(Translator);
