(function (t) {
// fr
t.add("titles.dashboard", "L'espace client", "customer", "fr");
t.add("dashboards.welcome", "Bienvenue dans l'espace client", "customer", "fr");
t.add("menu.company_page.title", "Ma soci\u00e9t\u00e9", "customer", "fr");
t.add("company.title.edit", "Modifier les informations", "customer", "fr");
t.add("menu.contact_envisport.title", "Contacter Envisport", "customer", "fr");
t.add("titles.contact", "Formulaire de contact", "customer", "fr");
t.add("mail.object", "Objet de votre demande", "customer", "fr");
t.add("mail.body", "Corps de la demande", "customer", "fr");
t.add("contact.send", "Envoyer", "customer", "fr");
t.add("menu.back_front.title", "Aller sur la plateforme", "customer", "fr");
})(Translator);
