(function (t) {
// tl
t.add("This value should be false.", "Ang halaga nito ay dapat na huwad.", "validators", "tl");
t.add("This value should be true.", "Ang halaga nito ay dapat totoo.", "validators", "tl");
t.add("This value should be of type {{ type }}.", "Ang halaga nito ay dapat sa uri {{ type }}.", "validators", "tl");
t.add("This value should be blank.", "Ang halaga nito ay dapat walang laman.", "validators", "tl");
t.add("The value you selected is not a valid choice.", "Ang halaga ng iyong pinili ay hindi balidong pagpili.", "validators", "tl");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Kailangan mong pumili ng pinakamababang {{ limit }} ng pagpilian.|Kailangan mong pumili ng pinakamababang {{ limit }} ng mga pagpipilian.", "validators", "tl");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Kailangan mong pumili ng pinakamataas {{ limit }} ng pagpipilian.|Kailangan mong pumili ng pinakamataas {{ limit }} ng mga pagpipilian.", "validators", "tl");
t.add("One or more of the given values is invalid.", "Isa o higit pang mga halaga na binigay ay hindi balido.", "validators", "tl");
t.add("This field was not expected.", "Ang larangang ito ay hindi inaasahan.", "validators", "tl");
t.add("This field is missing.", "Ang patlang na ito ay nawawala.", "validators", "tl");
t.add("This value is not a valid date.", "Ang halagang ito ay hindi balidong petsa.", "validators", "tl");
t.add("This value is not a valid datetime.", "Ang halagang ito ay hindi wastong petsa\/oras.", "validators", "tl");
t.add("This value is not a valid email address.", "Ang halagang ito ay hindi balidong address ng email.", "validators", "tl");
t.add("The file could not be found.", "Ang file na ito ay hindi makita.", "validators", "tl");
t.add("The file is not readable.", "Ang file na ito ay hindi mabasa.", "validators", "tl");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Ang file na ito ay masyadong malaki ({{ size }} {{ suffix }}). Ang pinakamalaking sukat {{ limit }} {{ suffix }}.", "validators", "tl");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Ang uri ng file ng mime ay hindi balido ({{ type }}).Ang mga pinapayagang uri ng mime ay ang  {{ types }}.", "validators", "tl");
t.add("This value should be {{ limit }} or less.", "Ang halaga nito ay dapat na {{ limit }} or maliit pa.", "validators", "tl");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Ang halaga nito ay masyadong mahaba.Ito ay dapat na {{ limit }} karakter o maliit pa.|Ang halaga nito ay masyadong mahaba. Ito ay dapat na {{ limit }} mga karakter o maliit pa.", "validators", "tl");
t.add("This value should be {{ limit }} or more.", "Ang halaga nito ay dapat na {{ limit }} o mas marami pa.", "validators", "tl");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Ang halaga nito ay masyadong maliit. Ito ay dapat na {{ limit }} karakter o marami pa.|Ang halaga nito ay masyadong maliit. Ito ay dapat na {{ limit }} mga karakter o marami pa.", "validators", "tl");
t.add("This value should not be blank.", "Ang halaga na ito ay dapat na may laman.", "validators", "tl");
t.add("This value should not be null.", "Meron dapt itong halaga.", "validators", "tl");
t.add("This value should be null.", "Wala dapat itong halaga.", "validators", "tl");
t.add("This value is not valid.", "Hindi balido ang halagang ito.", "validators", "tl");
t.add("This value is not a valid time.", "Ang halagang ito ay hindi wastong oras.", "validators", "tl");
t.add("This value is not a valid URL.", "Hindi ito isang balidong URL.", "validators", "tl");
t.add("The two values should be equal.", "Ang dalwang halaga ay dapat magkapareha.", "validators", "tl");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Ang file ay masyadong malaki. Ang pinapayagan halaga lamang ay {{ limit}} {{ suffix }}.", "validators", "tl");
t.add("The file is too large.", "Ang file na ito ay masyadong malaki.", "validators", "tl");
t.add("The file could not be uploaded.", "Ang file na ito ay hindi ma-upload.", "validators", "tl");
t.add("This value should be a valid number.", "Ang halaga nito ay dapat na wastong numero.", "validators", "tl");
t.add("This file is not a valid image.", "Ang file na ito ay hindi wastong imahe.", "validators", "tl");
t.add("This is not a valid IP address.", "Ito ay hindi wastong IP address.", "validators", "tl");
t.add("This value is not a valid language.", "Ang halaga na ito ay hindi balidong wika.", "validators", "tl");
t.add("This value is not a valid locale.", "Ito ay isang hindi wastong locale na halaga.", "validators", "tl");
t.add("This value is not a valid country.", "ng halaga na ito ay hindi wastong bansa.", "validators", "tl");
t.add("This value is already used.", "Ang halaga na ito ay ginamit na.", "validators", "tl");
t.add("The size of the image could not be detected.", "Ang sukat ng imahe ay hindi madetect.", "validators", "tl");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "Ang lapad ng imahe ay masyadong malaki ({{ width }}px). Ang pinapayagang lapay ay {{ max_width }}px.", "validators", "tl");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "Ang lapad ng imahe ay masyadong maliit ({{ width }}px). Ang pinakamaliit na pinapayagang lapad ay {{ min_width }}px.", "validators", "tl");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "Ang haba ng imahe ay masyadong mataas ({{ height }}px). Ang pinakmataas na haba ay {{ max_height }}px.", "validators", "tl");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "Ang haba ng imahe ay masyadong maliit ({{ height }}px). Ang inaasahang haba ay {{ min_height }}px.", "validators", "tl");
t.add("This value should be the user's current password.", "Ang halagang ito ay dapat na password ng kasalukuyang gumagamit.", "validators", "tl");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Ang halagang ito ay dapat na eksakto sa {{ limit}} karakter.|Ang halagang ito ay dapat na eksakto sa {{ limit }} mga karakter.", "validators", "tl");
t.add("The file was only partially uploaded.", "Ang file na ito ay kahalating na upload lamang.", "validators", "tl");
t.add("No file was uploaded.", "Walang na upload na file.", "validators", "tl");
t.add("No temporary folder was configured in php.ini.", "Walang temporaryong folder ang naayos sa php.ini.", "validators", "tl");
t.add("Cannot write temporary file to disk.", "Temporaryong hindi  makasulat ng file sa disk.", "validators", "tl");
t.add("A PHP extension caused the upload to fail.", "Ang dahilan ng pagkabigo ng pagupload ng files ay isang extension ng PHP.", "validators", "tl");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Ang koleksyon na ito ay dapat magkaroon ng {{ limit }} elemento o marami pa.|Ang koleksyon na ito ay dapat magkaroon ng {{ limit }} mga elemento o marami pa.", "validators", "tl");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Ang koleksyon na ito ay dapat magkaroon ng {{ limit }} elemento o maliit pa.|Ang koleksyon na ito ay dapat magkaroon ng {{ limit }} mga elemento o maliit pa.", "validators", "tl");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Ang koleksyong ito ay magkaroon ng eksaktong {{ limit }} elemento.|Ang koleksyong ito ay magkaroon ng eksaktong {{ limit }} mga elemento.", "validators", "tl");
t.add("Invalid card number.", "Hindi wastong numero ng kard.", "validators", "tl");
t.add("Unsupported card type or invalid card number.", "Hindi supportadong uri ng kard o hindi wastong numero ng kard.", "validators", "tl");
t.add("This is not a valid International Bank Account Number (IBAN).", "Ito ay hindi isang balidong International Bank Account Number (IBAN).", "validators", "tl");
t.add("This value is not a valid ISBN-10.", "Ang halagang ito ay hindi balidong SBN-10.", "validators", "tl");
t.add("This value is not a valid ISBN-13.", "Ang halagang ito ay hindi balidong ISBN-13.", "validators", "tl");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Ang halagang ito ay pwdeng isang balidong ISBN-10 o isang balidong ISBN-13.", "validators", "tl");
t.add("This value is not a valid ISSN.", "Ang halangang ito ay hindi isang balidong ISSN.", "validators", "tl");
t.add("This value is not a valid currency.", "Ang halagang ito ay hindi balidong pera.", "validators", "tl");
t.add("This value should be equal to {{ compared_value }}.", "Ito ay hindi dapat magkapareho sa {{ compared_value }}.", "validators", "tl");
t.add("This value should be greater than {{ compared_value }}.", "Ang halagang ito ay dapat tataas sa {{ compared_value }}.", "validators", "tl");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Ang halagang ito ay dapat mas mataas o magkapareha sa {{ compared_value }}.", "validators", "tl");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Ang halagang ito ay dapat kapareha ng {{ compared_value_type }} {{ compared_value }}.", "validators", "tl");
t.add("This value should be less than {{ compared_value }}.", "Ang halagang ito ay dapat mas maliit sa {{ compared_value }}.", "validators", "tl");
t.add("This value should be less than or equal to {{ compared_value }}.", "Ang halagang ito ay dapat mas mmaliit o magkapareha sa {{ compared_value }}.", "validators", "tl");
t.add("This value should not be equal to {{ compared_value }}.", "Ang halagang ito ay hindi dapat magkapareha sa {{ compared_value }}.", "validators", "tl");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Ang halagang ito ay hindi dapat magkapareha sa {{ compared_value_type }} {{ compared_value }}.", "validators", "tl");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "Ang ratio ng imahe ay masyadong malaki ({{ ratio }}). Ang pinakamalaking ratio ay {{ max_ratio }}.", "validators", "tl");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "ng ratio ng imahe ay masyadong maliit ({{ ratio }}). Ang pinamaliit na ratio ay {{ min_ratio }}.", "validators", "tl");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "Ang imahe ay kwadrado ({{ width }}x{{ height }}px). Ang mga kwadradong imahe ay hindi pwede.", "validators", "tl");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "Ang orientasyon ng imahe ay nakalandscape ({{ width }}x{{ height }}px). Ang mga imaheng nakalandscape ang orientasyon ay hindi pwede.", "validators", "tl");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "Ang orientasyon ng imahe ay nakaportrait ({{ width }}x{{ height }}px). PAng mga imaheng nakaportrait ang orientasyon ay hindi pwede.", "validators", "tl");
t.add("An empty file is not allowed.", "Ang file na walang laman ay hindi pwede.", "validators", "tl");
t.add("The host could not be resolved.", "Hindi maresolba ang host.", "validators", "tl");
t.add("This value does not match the expected {{ charset }} charset.", "Ang halaga ay hindi kapareha sa inaasahang {{ charset }} set ng karater.", "validators", "tl");
t.add("This is not a valid Business Identifier Code (BIC).", "Ito ay hindi isang balidong Business Identifier Code (BIC).", "validators", "tl");
t.add("This form should not contain extra fields.", "Ang pormang itong ay hindi dapat magkarron ng dagdag na mga patlang.", "validators", "tl");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Ang ini-upload na file ay masyadong malaki. Pakiulit muling mag-upload ng mas maliit na file.", "validators", "tl");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "Hindi balido ang CSRF token. Maagpasa muli ng isang pang porma.", "validators", "tl");
})(Translator);
