(function (t) {
// fr
t.add("titles.documents", "Documents", "documents", "fr");
t.add("company.list", "Liste de documents", "documents", "fr");
t.add("document.remove.success", "Le document a \u00e9t\u00e9 supprim\u00e9 avec succ\u00e8s", "documents", "fr");
t.add("document.remove.fail", "Une erreur est survenue lors de la suppression", "documents", "fr");
t.add("document.remove", "Supprimer", "documents", "fr");
t.add("documents.upload.info", "Glisser et d\u00e9poser un ou plusieurs documents", "documents", "fr");
t.add("company.return", "Retour", "documents", "fr");
})(Translator);
