(function (t) {
// lv
t.add("This value should be false.", "\u0160ai v\u0113rt\u012bbai ir j\u0101b\u016bt nepatiesai.", "validators", "lv");
t.add("This value should be true.", "\u0160ai v\u0113rt\u012bbai ir j\u0101b\u016bt patiesai.", "validators", "lv");
t.add("This value should be of type {{ type }}.", "\u0160\u012bs v\u0113rt\u012bbas tipam ir j\u0101b\u016bt {{ type }}.", "validators", "lv");
t.add("This value should be blank.", "\u0160ai v\u0113rt\u012bbai ir j\u0101b\u016bt tuk\u0161ai.", "validators", "lv");
t.add("The value you selected is not a valid choice.", "V\u0113rt\u012bba, kuru j\u016bs izv\u0113l\u0113j\u0101ties nav der\u012bga izv\u0113le.", "validators", "lv");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Jums nav j\u0101veic izv\u0113le.|Jums ir j\u0101veic vismaz {{ limit }} izv\u0113le.|Jums ir j\u0101veic vismaz {{ limit }} izv\u0113les.", "validators", "lv");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Jums nav j\u0101veic izv\u0113le.|Jums ir j\u0101veic ne vair\u0101k k\u0101 {{ limit }} izv\u0113le.|Jums ir j\u0101veic ne vair\u0101k k\u0101 {{ limit }} izv\u0113les.", "validators", "lv");
t.add("One or more of the given values is invalid.", "Viena vai vair\u0101kas no dotaj\u0101m v\u0113rt\u012bb\u0101m ir neder\u012bgas.", "validators", "lv");
t.add("This field was not expected.", "\u0160is lauks netika gaid\u012bts.", "validators", "lv");
t.add("This field is missing.", "\u0160is lauks ir pazudis.", "validators", "lv");
t.add("This value is not a valid date.", "\u0160\u012b v\u0113rt\u012bba ir neder\u012bgs datums.", "validators", "lv");
t.add("This value is not a valid datetime.", "\u0160\u012b v\u0113rt\u012bba ir neder\u012bgs datums un laiks", "validators", "lv");
t.add("This value is not a valid email address.", "\u0160\u012b v\u0113rt\u012bba ir neder\u012bga e-pasta adrese.", "validators", "lv");
t.add("The file could not be found.", "Fails nav atrasts.", "validators", "lv");
t.add("The file is not readable.", "Fails nav las\u0101ms.", "validators", "lv");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Fails ir p\u0101r\u0101k liels ({{ size }} {{ suffix }}). At\u013cautais maksim\u0101lais izm\u0113rs ir {{ limit }} {{ suffix }}.", "validators", "lv");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Faila mime tips nav der\u012bgs ({{ type }}). At\u013cautie mime tipi ir {{ types }}.", "validators", "lv");
t.add("This value should be {{ limit }} or less.", "\u0160ai v\u0113rt\u012bbai ir j\u0101b\u016bt ne vair\u0101k k\u0101 {{ limit }}.", "validators", "lv");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "\u0160\u012bs v\u0113rt\u012bbas garums ir 0 rakstz\u012bmju.|\u0160\u012b v\u0113rt\u012bba ir p\u0101r\u0101k gara. Tai b\u016btu j\u0101b\u016bt ne vair\u0101k k\u0101 {{ limit }} rakstz\u012bmei.|\u0160\u012b v\u0113rt\u012bba ir p\u0101r\u0101k gara. Tai b\u016btu j\u0101b\u016bt ne vair\u0101k k\u0101 {{ limit }} rakstz\u012bm\u0113m.", "validators", "lv");
t.add("This value should be {{ limit }} or more.", "\u0160ai v\u0113rt\u012bbai ir j\u0101b\u016bt ne maz\u0101k k\u0101 {{ limit }}.", "validators", "lv");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "\u0160\u012bs v\u0113rt\u012bbas garums ir 0 rakstz\u012bmju.|\u0160\u012b v\u0113rt\u012bba ir p\u0101r\u0101k \u012bsa. Tai b\u016btu j\u0101b\u016bt ne maz\u0101k k\u0101 {{ limit }} rakstz\u012bmei.|\u0160\u012b v\u0113rt\u012bba ir p\u0101r\u0101k \u012bsa. Tai b\u016btu j\u0101b\u016bt ne maz\u0101k k\u0101 {{ limit }} rakstz\u012bm\u0113m.", "validators", "lv");
t.add("This value should not be blank.", "\u0160ai v\u0113rt\u012bbai nav j\u0101b\u016bt tuk\u0161ai.", "validators", "lv");
t.add("This value should not be null.", "\u0160ai v\u0113rt\u012bbai nav j\u0101b\u016bt null.", "validators", "lv");
t.add("This value should be null.", "\u0160ai v\u0113rt\u012bbai ir j\u0101b\u016bt null.", "validators", "lv");
t.add("This value is not valid.", "\u0160\u012b v\u0113rt\u012bba ir neder\u012bga.", "validators", "lv");
t.add("This value is not a valid time.", "\u0160\u012b v\u0113rt\u012bba ir neder\u012bgs laiks.", "validators", "lv");
t.add("This value is not a valid URL.", "\u0160\u012b v\u0113rt\u012bba ir neder\u012bgs URL.", "validators", "lv");
t.add("The two values should be equal.", "Ab\u0101m v\u0113rt\u012bb\u0101m j\u0101b\u016bt vien\u0101dam.", "validators", "lv");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Fails ir p\u0101r\u0101k liels. At\u013cautais maksim\u0101lais izm\u0113rs ir {{ limit }} {{ suffix }}.", "validators", "lv");
t.add("The file is too large.", "Fails ir p\u0101r\u0101k liels.", "validators", "lv");
t.add("The file could not be uploaded.", "Failu nevar\u0113ja aug\u0161upiel\u0101d\u0113t.", "validators", "lv");
t.add("This value should be a valid number.", "\u0160ai v\u0113rt\u012bbai ir j\u0101b\u016bt der\u012bgam skaitlim.", "validators", "lv");
t.add("This file is not a valid image.", "\u0160is fails nav der\u012bgs att\u0113ls.", "validators", "lv");
t.add("This is not a valid IP address.", "\u0160\u012b nav der\u012bga IP adrese.", "validators", "lv");
t.add("This value is not a valid language.", "\u0160\u012b v\u0113rt\u012bba nav der\u012bga valoda.", "validators", "lv");
t.add("This value is not a valid locale.", "\u0160\u012b v\u0113rt\u012bba nav der\u012bga lokaliz\u0101cija.", "validators", "lv");
t.add("This value is not a valid country.", "\u0160\u012b v\u0113rt\u012bba nav der\u012bga valsts.", "validators", "lv");
t.add("This value is already used.", "\u0160\u012b v\u0113rt\u012bba jau tiek izmantota.", "validators", "lv");
t.add("The size of the image could not be detected.", "Nevar noteikt att\u0113la izm\u0113ru.", "validators", "lv");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "Att\u0113la platums ir p\u0101r\u0101k liels ({{ width }}px). At\u013cautais maksim\u0101lais platums ir {{ max_width }}px.", "validators", "lv");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "Att\u0113la platums ir p\u0101r\u0101k mazs ({{ width }}px). Minim\u0101lais sagaid\u0101mais platums ir {{ min_width }}px.", "validators", "lv");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "Att\u0113la augstums ir p\u0101r\u0101k liels ({{ height }}px). At\u013cautais maksim\u0101lais augstums ir {{ max_height }}px.", "validators", "lv");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "Att\u0113la augstums ir p\u0101r\u0101k mazs ({{ height }}px). Minim\u0101lais sagaid\u0101mais augstums ir {{ min_height }}px.", "validators", "lv");
t.add("This value should be the user's current password.", "\u0160ai v\u0113rt\u012bbai ir j\u0101b\u016bt lietot\u0101ja pa\u0161reiz\u0113jai parolei.", "validators", "lv");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "\u0160\u012bs v\u0113rt\u012bbas garums ir 0 rakstz\u012bmju.|\u0160ai v\u0113rt\u012bbai ir j\u0101b\u016bt tie\u0161i {{ limit }} rakstz\u012bmei.|\u0160ai v\u0113rt\u012bbai ir j\u0101b\u016bt tie\u0161i {{ limit }} rakstz\u012bm\u0113m.", "validators", "lv");
t.add("The file was only partially uploaded.", "Fails bija tikai da\u013c\u0113ji aug\u0161upiel\u0101d\u0113ts.", "validators", "lv");
t.add("No file was uploaded.", "Fails netika aug\u0161upiel\u0101d\u0113ts.", "validators", "lv");
t.add("No temporary folder was configured in php.ini.", "Pagaidu mape php.ini fail\u0101 nav nokonfigur\u0113ta.", "validators", "lv");
t.add("Cannot write temporary file to disk.", "Nevar ierakst\u012bt pagaidu failu uz diska.", "validators", "lv");
t.add("A PHP extension caused the upload to fail.", "PHP papla\u0161in\u0101jums izrais\u012bja aug\u0161upiel\u0101des neizdo\u0161anos.", "validators", "lv");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "\u0160is kr\u0101jums satur 0 elementu.|\u0160im kr\u0101jumam j\u0101satur vismaz {{ limit }} elementu.|\u0160im kr\u0101jumam j\u0101satur vismaz {{ limit }} elementus.", "validators", "lv");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "\u0160is kr\u0101jums satur 0 elementu.|\u0160im kr\u0101jumam j\u0101satur ne vair\u0101k k\u0101 {{ limit }} elementu.|\u0160im kr\u0101jumam j\u0101satur ne vair\u0101k k\u0101 {{ limit }} elementus.", "validators", "lv");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "\u0160is kr\u0101jums satur 0 elementu.|\u0160im kr\u0101jumam j\u0101satur tie\u0161i {{ limit }} elementu.|\u0160im kr\u0101jumam j\u0101satur tie\u0161i {{ limit }} elementus.", "validators", "lv");
t.add("Invalid card number.", "Neder\u012bgs kartes numurs.", "validators", "lv");
t.add("Unsupported card type or invalid card number.", "Neatbalst\u012bts kartes tips vai neder\u012bgs kartes numurs.", "validators", "lv");
t.add("This is not a valid International Bank Account Number (IBAN).", "\u0160is nav der\u012bgs starptautisks banku konta numurs (IBAN).", "validators", "lv");
t.add("This value is not a valid ISBN-10.", "\u0160\u012b v\u0113rt\u012bba nav der\u012bgs ISBN-10 numurs.", "validators", "lv");
t.add("This value is not a valid ISBN-13.", "\u0160\u012b v\u0113rt\u012bba nav der\u012bgs ISBN-13 numurs", "validators", "lv");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "\u0160\u012b v\u0113rt\u012bba neatbilst ne der\u012bgam ISBN-10 numuram, ne der\u012bgm ISBN-13 numuram.", "validators", "lv");
t.add("This value is not a valid ISSN.", "\u0160\u012b v\u0113rt\u012bba nav der\u012bgs ISSN numurs", "validators", "lv");
t.add("This value is not a valid currency.", "\u0160\u012b v\u0113rt\u012bba nav der\u012bga val\u016bta", "validators", "lv");
t.add("This value should be equal to {{ compared_value }}.", "\u0160ai v\u0113rt\u012bbai ir j\u0101b\u016bt vien\u0101dai ar {{ compared_value }}.", "validators", "lv");
t.add("This value should be greater than {{ compared_value }}.", "\u0160ai v\u0113rt\u012bbai ir j\u0101b\u016bt liel\u0101kai par {{ compared_value }}.", "validators", "lv");
t.add("This value should be greater than or equal to {{ compared_value }}.", "\u0160ai v\u0113rt\u012bbai ir j\u0101b\u016bt liel\u0101kai vai vien\u0101dai ar {{ compared_value }}.", "validators", "lv");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "\u0160ai v\u0113rt\u012bbai ir j\u0101b\u016bt identiskai ar {{ compared_value_type }} {{ compared_value }}.", "validators", "lv");
t.add("This value should be less than {{ compared_value }}.", "\u0160ai v\u0113rt\u012bbai ir j\u0101b\u016bt maz\u0101kai par {{ compared_value }}.", "validators", "lv");
t.add("This value should be less than or equal to {{ compared_value }}.", "T\u0160ai v\u0113rt\u012bbai ir j\u0101b\u016bt maz\u0101kai vai vien\u0101dai ar {{ compared_value }}.", "validators", "lv");
t.add("This value should not be equal to {{ compared_value }}.", "\u0160ai v\u0113rt\u012bbai ir j\u0101b\u016bt vien\u0101dai ar {{ compared_value }}.", "validators", "lv");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "\u0160ai v\u0113rt\u012bbai nav j\u0101b\u016bt identiskai ar {{ compared_value_type }} {{ compared_value }}.", "validators", "lv");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "Att\u0113la attiec\u012bba ir p\u0101r\u0101k liela ({{ ratio }}). At\u013caut\u0101 maksim\u0101l\u0101 attiec\u012bba ir {{ max_ratio }}.", "validators", "lv");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "Att\u0113la attiec\u012bba ir p\u0101r\u0101k maza ({{ ratio }}). Minim\u0101l\u0101 sagaid\u0101m\u0101 attiec\u012bba ir {{ min_ratio }}.", "validators", "lv");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "\u0160is att\u0113ls ir kvadr\u0101ts ({{ width }}x{{ height }}px). Kvadr\u0101tveida att\u0113li nav at\u013cauti.", "validators", "lv");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "Att\u0113ls ir orient\u0113ts k\u0101 ainava ({{ width }}x{{ height }}px). Att\u0113li, kas ir orient\u0113ti k\u0101 ainavas nav at\u013cauti.", "validators", "lv");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "Att\u0113ls ir orient\u0113ts k\u0101 portrets ({{ width }}x{{ height }}px). Att\u0113li, kas ir orient\u0113ti k\u0101 portreti nav at\u013cauti.", "validators", "lv");
t.add("An empty file is not allowed.", "Tuk\u0161s fails nav at\u013cauts.", "validators", "lv");
t.add("The host could not be resolved.", "Resursdatora nosaukumu nevar atrisin\u0101t.", "validators", "lv");
t.add("This value does not match the expected {{ charset }} charset.", "\u0160\u012b v\u0113rt\u012bba neatbilst sagaid\u0101majai rakstz\u012bmju kopai {{ charset }}.", "validators", "lv");
t.add("This form should not contain extra fields.", "\u0160aj\u0101 veidlap\u0101 nevajadz\u0113tu b\u016bt papildus ievades laukiem.", "validators", "lv");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Aug\u0161upiel\u0101d\u0113t\u0101 faila izm\u0113rs bija par lielu. L\u016bdzu m\u0113\u0123iniet aug\u0161upiel\u0101d\u0113t maz\u0101ka izm\u0113ra failu.", "validators", "lv");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "Dotais CSRF talons nav der\u012bgs. L\u016bdzu m\u0113\u0123iniet v\u0113lreiz iesniegt veidlapu.", "validators", "lv");
})(Translator);
