(function (t) {
// el
t.add("An authentication exception occurred.", "\u03a3\u03c5\u03bd\u03ad\u03b2\u03b7 \u03ad\u03bd\u03b1 \u03c3\u03c6\u03ac\u03bb\u03bc\u03b1 \u03c0\u03b9\u03c3\u03c4\u03bf\u03c0\u03bf\u03af\u03b7\u03c3\u03b7\u03c2.", "security", "el");
t.add("Authentication credentials could not be found.", "\u03a4\u03b1 \u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03af\u03b1 \u03c0\u03b9\u03c3\u03c4\u03bf\u03c0\u03bf\u03af\u03b7\u03c3\u03b7\u03c2 \u03b4\u03b5 \u03b2\u03c1\u03ad\u03b8\u03b7\u03ba\u03b1\u03bd.", "security", "el");
t.add("Authentication request could not be processed due to a system problem.", "\u03a4\u03bf \u03b1\u03af\u03c4\u03b7\u03bc\u03b1 \u03c0\u03b9\u03c3\u03c4\u03bf\u03c0\u03bf\u03af\u03b7\u03c3\u03b7\u03c2 \u03b4\u03b5 \u03bc\u03c0\u03bf\u03c1\u03b5\u03af \u03bd\u03b1 \u03b5\u03c0\u03b5\u03be\u03b5\u03c1\u03b3\u03b1\u03c3\u03c4\u03b5\u03af \u03bb\u03cc\u03b3\u03c9 \u03c3\u03c6\u03ac\u03bb\u03bc\u03b1\u03c4\u03bf\u03c2 \u03c4\u03bf\u03c5 \u03c3\u03c5\u03c3\u03c4\u03ae\u03bc\u03b1\u03c4\u03bf\u03c2.", "security", "el");
t.add("Invalid credentials.", "\u039b\u03b1\u03bd\u03b8\u03b1\u03c3\u03bc\u03ad\u03bd\u03b1 \u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03af\u03b1 \u03c3\u03cd\u03bd\u03b4\u03b5\u03c3\u03b7\u03c2.", "security", "el");
t.add("Cookie has already been used by someone else.", "\u03a4\u03bf Cookie \u03ad\u03c7\u03b5\u03b9 \u03ae\u03b4\u03b7 \u03c7\u03c1\u03b7\u03c3\u03b9\u03bc\u03bf\u03c0\u03bf\u03b9\u03b7\u03b8\u03b5\u03af \u03b1\u03c0\u03cc \u03ba\u03ac\u03c0\u03bf\u03b9\u03bf\u03bd \u03ac\u03bb\u03bb\u03bf.", "security", "el");
t.add("Not privileged to request the resource.", "\u0394\u03b5\u03bd \u03b5\u03af\u03c3\u03c4\u03b5 \u03b5\u03be\u03bf\u03c5\u03c3\u03b9\u03bf\u03b4\u03bf\u03c4\u03b7\u03bc\u03ad\u03bd\u03bf\u03c2 \u03b3\u03b9\u03b1 \u03c0\u03c1\u03cc\u03c3\u03b2\u03b1\u03c3\u03b7 \u03c3\u03c4\u03bf \u03c3\u03c5\u03b3\u03ba\u03b5\u03ba\u03c1\u03b9\u03bc\u03ad\u03bd\u03bf \u03c0\u03b5\u03c1\u03b9\u03b5\u03c7\u03cc\u03bc\u03b5\u03bd\u03bf.", "security", "el");
t.add("Invalid CSRF token.", "\u039c\u03b7 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf CSRF token.", "security", "el");
t.add("No authentication provider found to support the authentication token.", "\u0394\u03b5 \u03b2\u03c1\u03ad\u03b8\u03b7\u03ba\u03b5 \u03ba\u03ac\u03c0\u03bf\u03b9\u03bf\u03c2 \u03c0\u03ac\u03c1\u03bf\u03c7\u03bf\u03c2 \u03c0\u03b9\u03c3\u03c4\u03bf\u03c0\u03bf\u03af\u03b7\u03c3\u03b7\u03c2 \u03c0\u03bf\u03c5 \u03bd\u03b1 \u03c5\u03c0\u03bf\u03c3\u03c4\u03b7\u03c1\u03af\u03b6\u03b5\u03b9 \u03c4\u03bf token \u03c0\u03b9\u03c3\u03c4\u03bf\u03c0\u03bf\u03af\u03b7\u03c3\u03b7\u03c2.", "security", "el");
t.add("No session available, it either timed out or cookies are not enabled.", "\u0394\u03b5\u03bd \u03c5\u03c0\u03ac\u03c1\u03c7\u03b5\u03b9 \u03b5\u03bd\u03b5\u03c1\u03b3\u03ae \u03c3\u03cd\u03bd\u03bf\u03b4\u03bf\u03c2 (session), \u03b5\u03af\u03c4\u03b5 \u03ad\u03c7\u03b5\u03b9 \u03bb\u03ae\u03be\u03b5\u03b9 \u03ae \u03c4\u03b1 cookies \u03b4\u03b5\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03b5\u03bd\u03b5\u03c1\u03b3\u03bf\u03c0\u03bf\u03b9\u03b7\u03bc\u03ad\u03bd\u03b1.", "security", "el");
t.add("No token could be found.", "\u0394\u03b5\u03bd \u03ae\u03c4\u03b1\u03bd \u03b4\u03c5\u03bd\u03b1\u03c4\u03cc\u03bd \u03bd\u03b1 \u03b2\u03c1\u03b5\u03b8\u03b5\u03af \u03ba\u03ac\u03c0\u03bf\u03b9\u03bf token.", "security", "el");
t.add("Username could not be found.", "\u03a4\u03bf Username \u03b4\u03b5 \u03b2\u03c1\u03ad\u03b8\u03b7\u03ba\u03b5.", "security", "el");
t.add("Account has expired.", "\u039f \u03bb\u03bf\u03b3\u03b1\u03c1\u03b9\u03b1\u03c3\u03bc\u03cc\u03c2 \u03ad\u03c7\u03b5\u03b9 \u03bb\u03ae\u03be\u03b5\u03b9.", "security", "el");
t.add("Credentials have expired.", "\u03a4\u03b1 \u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03af\u03b1 \u03c3\u03cd\u03bd\u03b4\u03b5\u03c3\u03b7\u03c2 \u03ad\u03c7\u03bf\u03c5\u03bd \u03bb\u03ae\u03be\u03b5\u03b9.", "security", "el");
t.add("Account is disabled.", "\u039f \u03bb\u03bf\u03b3\u03b1\u03c1\u03b9\u03b1\u03c3\u03bc\u03cc\u03c2 \u03b5\u03af\u03bd\u03b1\u03b9 \u03b1\u03c0\u03b5\u03bd\u03b5\u03c1\u03b3\u03bf\u03c0\u03bf\u03b9\u03b7\u03bc\u03ad\u03bd\u03bf\u03c2.", "security", "el");
t.add("Account is locked.", "\u039f \u03bb\u03bf\u03b3\u03b1\u03c1\u03b9\u03b1\u03c3\u03bc\u03cc\u03c2 \u03b5\u03af\u03bd\u03b1\u03b9 \u03ba\u03bb\u03b5\u03b9\u03b4\u03c9\u03bc\u03ad\u03bd\u03bf\u03c2.", "security", "el");
})(Translator);
