(function (t) {
// fr
t.add("menu.settings", "Param\u00e8tres", "admin", "fr");
t.add("menu.admin_users", "Administrateurs", "admin", "fr");
t.add("admin_users.name", "Nom", "admin", "fr");
t.add("admin_users.phone", "Contact", "admin", "fr");
t.add("common.datatable_actions", "Action", "admin", "fr");
t.add("common.remove_btn", "Supprimer", "admin", "fr");
t.add("common.promote_btn", "Promouvoir ambassadeur", "admin", "fr");
t.add("menu.activities.title", "Activit\u00e9s", "admin", "fr");
t.add("menu.activities.list", "Liste des activit\u00e9s", "admin", "fr");
t.add("menu.activities.promotion", "Promouvoir", "admin", "fr");
t.add("menu.activities.map", "Carte des activit\u00e9s", "admin", "fr");
t.add("menu.companies.title", "Clients", "admin", "fr");
t.add("menu.companies.list", "Liste des clients", "admin", "fr");
t.add("menu.companies.add_new", "Ajouter un client", "admin", "fr");
t.add("menu.companies.archive", "Clients archiv\u00e9s", "admin", "fr");
t.add("modal.confirmation", "\u00cates-vous s\u00fbr de vouloir r\u00e9aliser cette action ?", "admin", "fr");
t.add("modal.action.cancel", "Annuler", "admin", "fr");
t.add("modal.action.confirm", "Confirmer", "admin", "fr");
t.add("common.saved_successfully", "Vos informations ont \u00e9t\u00e9 sauvegard\u00e9es.", "admin", "fr");
t.add("common.error_saving", "Une erreur est survenue. Vos informations n'ont pas \u00e9t\u00e9 sauvegard\u00e9es.", "admin", "fr");
t.add("menu.add_admin", "Ajouter un administrateur", "admin", "fr");
t.add("titles.admin_user.add", "Ajouter un administrateur", "admin", "fr");
t.add("titles.admin_user.list", "Administrateurs", "admin", "fr");
t.add("user.first_name", "Pr\u00e9nom", "admin", "fr");
t.add("user.last_name", "Nom", "admin", "fr");
t.add("user.email", "Adresse e-mail", "admin", "fr");
t.add("common.save", "Sauvegarder", "admin", "fr");
t.add("mail.title.admin_creation", "Activation de votre compte Envisport", "admin", "fr");
t.add("menu.activity_sync", "Mettre \u00e0 jour les activit\u00e9s", "admin", "fr");
t.add("menu.activities_was_synnchronized", "activit\u00e9s ont \u00e9t\u00e9 synchronis\u00e9es", "admin", "fr");
t.add("titles.dashboard", "L'espace administrateur", "admin", "fr");
t.add("dashboards.welcome", "Bienvenue dans l'espace administrateur", "admin", "fr");
t.add("menu.logout", "Se d\u00e9connecter", "admin", "fr");
t.add("titles.activities.list", "Liste des activit\u00e9s", "admin", "fr");
t.add("activities.name", "Nom", "admin", "fr");
t.add("activities.address", "Lieu", "admin", "fr");
t.add("activities.category", "Type d'activit\u00e9", "admin", "fr");
t.add("activities.due_date", "Date d'\u00e9ch\u00e9ance", "admin", "fr");
t.add("activities.status", "Statut", "admin", "fr");
t.add("activities.privatisation", "Privatisation", "admin", "fr");
t.add("activities.statuses.active", "Active", "admin", "fr");
t.add("activities.statuses.inactive", "Non active", "admin", "fr");
t.add("common.select_all", "Tout", "admin", "fr");
t.add("activities.private", "Priv\u00e9", "admin", "fr");
t.add("activities.public", "Publique", "admin", "fr");
t.add("common.search", "Rechercher", "admin", "fr");
t.add("activities.privatisation_modal.title", "Pour privatiser cette activit\u00e9 veuillez rentrer le(s) nom(s) du(des) clients concern\u00e9s", "admin", "fr");
t.add("modal.action.validate", "Valider", "admin", "fr");
t.add("titles.activity_promotion", "Promouvoir les activit\u00e9s", "admin", "fr");
t.add("activity_promotion.add_promotion", "Ajouter une mise en avant", "admin", "fr");
t.add("activity_group.name", "Nom de la mise en avant", "admin", "fr");
t.add("activity_group.activities", "Activit\u00e9s (5 maximum)", "admin", "fr");
t.add("activity_group.companies", "Qui peut voir \u00e7a ?", "admin", "fr");
t.add("activity_group.name_placeholder", "Nom", "admin", "fr");
t.add("activity_group.title", "Mise en avant", "admin", "fr");
t.add("common.register", "Enregistrer", "admin", "fr");
t.add("common.delete", "Supprimer", "admin", "fr");
t.add("common.server_error", "D\u00e9faut temporaire du serveur", "admin", "fr");
t.add("common.deleted_successfully", "Supprim\u00e9 avec succ\u00e8s", "admin", "fr");
t.add("menu.documents", "Documents", "admin", "fr");
t.add("users.mail.list", "Mails des b\u00e9n\u00e9ficiaires", "admin", "fr");
t.add("beneficiary.mail", "Mail du b\u00e9n\u00e9ficiaire", "admin", "fr");
t.add("beneficiary.selector", "Nom des b\u00e9n\u00e9ficiaires", "admin", "fr");
t.add("user.csv", "Fichier csv", "admin", "fr");
t.add("common.mail_sent", "Mail envoy\u00e9 aux administrateurs", "admin", "fr");
t.add("user.add_code", "Ajouter un code RegionDo", "admin", "fr");
})(Translator);
