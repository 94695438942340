(function (t) {
// sr_Latn
t.add("This value should be false.", "Vrednost treba da bude neta\u010dna.", "validators", "sr_Latn");
t.add("This value should be true.", "Vrednost treba da bude ta\u010dna.", "validators", "sr_Latn");
t.add("This value should be of type {{ type }}.", "Vrednost treba da bude tipa {{ type }}.", "validators", "sr_Latn");
t.add("This value should be blank.", "Vrednost treba da bude prazna.", "validators", "sr_Latn");
t.add("The value you selected is not a valid choice.", "Vrednost treba da bude jedna od ponu\u0111enih.", "validators", "sr_Latn");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Izaberite bar {{ limit }} mogu\u0107nost.|Izaberite bar {{ limit }} mogu\u0107nosti.|Izaberite bar {{ limit }} mogu\u0107nosti.", "validators", "sr_Latn");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Izaberite najvi\u0161e {{ limit }} mogu\u0107nost.|Izaberite najvi\u0161e {{ limit }} mogu\u0107nosti.|Izaberite najvi\u0161e {{ limit }} mogu\u0107nosti.", "validators", "sr_Latn");
t.add("One or more of the given values is invalid.", "Jedna ili vi\u0161e vrednosti je nevalidna.", "validators", "sr_Latn");
t.add("This field was not expected.", "Ovo polje ne o\u010dekuje.", "validators", "sr_Latn");
t.add("This field is missing.", "Ovo polje nedostaje.", "validators", "sr_Latn");
t.add("This value is not a valid date.", "Vrednost nije validan datum.", "validators", "sr_Latn");
t.add("This value is not a valid datetime.", "Vrednost nije validan datum-vreme.", "validators", "sr_Latn");
t.add("This value is not a valid email address.", "Vrednost nije validna adresa elektronske po\u0161te.", "validators", "sr_Latn");
t.add("The file could not be found.", "Datoteka ne mo\u017ee biti prona\u0111ena.", "validators", "sr_Latn");
t.add("The file is not readable.", "Datoteka nije \u010ditljiva.", "validators", "sr_Latn");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Datoteka je prevelika ({{ size }} {{ suffix }}). Najve\u0107a dozvoljena veli\u010dina je {{ limit }} {{ suffix }}.", "validators", "sr_Latn");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Mime tip datoteke nije validan ({{ type }}). Dozvoljeni mime tipovi su {{ types }}.", "validators", "sr_Latn");
t.add("This value should be {{ limit }} or less.", "Vrednost treba da bude {{ limit }} ili manje.", "validators", "sr_Latn");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Vrednost je preduga\u010dka. Treba da ima {{ limit }} karakter ili manje.|Vrednost je preduga\u010dka. Treba da ima {{ limit }} karaktera ili manje.|Vrednost je preduga\u010dka. Treba da ima {{ limit }} karaktera ili manje.", "validators", "sr_Latn");
t.add("This value should be {{ limit }} or more.", "Vrednost treba da bude {{ limit }} ili vi\u0161e.", "validators", "sr_Latn");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Vrednost je prekratka. Treba da ima {{ limit }} karakter ili vi\u0161e.|Vrednost je prekratka. Treba da ima {{ limit }} karaktera ili vi\u0161e.|Vrednost je prekratka. Treba da ima {{ limit }} karaktera ili vi\u0161e.", "validators", "sr_Latn");
t.add("This value should not be blank.", "Vrednost ne treba da bude prazna.", "validators", "sr_Latn");
t.add("This value should not be null.", "Vrednost ne treba da bude null.", "validators", "sr_Latn");
t.add("This value should be null.", "Vrednost treba da bude null.", "validators", "sr_Latn");
t.add("This value is not valid.", "Vrednost je nevalidna.", "validators", "sr_Latn");
t.add("This value is not a valid time.", "Vrednost nije validno vreme.", "validators", "sr_Latn");
t.add("This value is not a valid URL.", "Vrednost nije validan URL.", "validators", "sr_Latn");
t.add("The two values should be equal.", "Obe vrednosti treba da budu jednake.", "validators", "sr_Latn");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Datoteka je prevelika. Najve\u0107a dozvoljena veli\u010dina je {{ limit }} {{ suffix }}.", "validators", "sr_Latn");
t.add("The file is too large.", "Datoteka je prevelika.", "validators", "sr_Latn");
t.add("The file could not be uploaded.", "Datoteka ne mo\u017ee biti otpremljena.", "validators", "sr_Latn");
t.add("This value should be a valid number.", "Vrednost treba da bude validan broj.", "validators", "sr_Latn");
t.add("This file is not a valid image.", "Ova datoteka nije validna slika.", "validators", "sr_Latn");
t.add("This is not a valid IP address.", "Ovo nije validna IP adresa.", "validators", "sr_Latn");
t.add("This value is not a valid language.", "Vrednost nije validan jezik.", "validators", "sr_Latn");
t.add("This value is not a valid locale.", "Vrednost nije validan lokal.", "validators", "sr_Latn");
t.add("This value is not a valid country.", "Vrednost nije validna zemlja.", "validators", "sr_Latn");
t.add("This value is already used.", "Vrednost je ve\u0107 iskori\u0161\u0107ena.", "validators", "sr_Latn");
t.add("The size of the image could not be detected.", "Veli\u010dina slike ne mo\u017ee biti odre\u0111ena.", "validators", "sr_Latn");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "\u0160irina slike je prevelika ({{ width }}px). Naje\u0107a dozvoljena \u0161irina je {{ max_width }}px.", "validators", "sr_Latn");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "\u0160irina slike je premala ({{ width }}px). Najmanja dozvoljena \u0161irina je {{ min_width }}px.", "validators", "sr_Latn");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "Visina slike je prevelika ({{ height }}px). Naje\u0107a dozvoljena visina je {{ max_height }}px.", "validators", "sr_Latn");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "Visina slike je premala ({{ height }}px). Najmanja dozvoljena visina je {{ min_height }}px.", "validators", "sr_Latn");
t.add("This value should be the user's current password.", "Vrednost treba da bude trenutna korisni\u010dka lozinka.", "validators", "sr_Latn");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Vrednost treba da ima ta\u010dno {{ limit }} karakter.|Vrednost treba da ima ta\u010dno {{ limit }} karaktera.|Vrednost treba da ima ta\u010dno {{ limit }} karaktera.", "validators", "sr_Latn");
t.add("The file was only partially uploaded.", "Datoteka je samo parcijalno otpremljena.", "validators", "sr_Latn");
t.add("No file was uploaded.", "Datoteka nije otpremljena.", "validators", "sr_Latn");
t.add("No temporary folder was configured in php.ini.", "Privremeni direktorijum nije konfigurisan u php.ini.", "validators", "sr_Latn");
t.add("Cannot write temporary file to disk.", "Nemogu\u0107e pisanje privremene datoteke na disk.", "validators", "sr_Latn");
t.add("A PHP extension caused the upload to fail.", "PHP ekstenzija je prouzrokovala neuspeh otpremanja datoteke.", "validators", "sr_Latn");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Ova kolekcija treba da sadr\u017ei {{ limit }} ili vi\u0161e elemenata.|Ova kolekcija treba da sadr\u017ei {{ limit }} ili vi\u0161e elemenata.|Ova kolekcija treba da sadr\u017ei {{ limit }} ili vi\u0161e elemenata.", "validators", "sr_Latn");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Ova kolekcija treba da sadr\u017ei {{ limit }} ili manje elemenata.|Ova kolekcija treba da sadr\u017ei {{ limit }} ili manje elemenata.|Ova kolekcija treba da sadr\u017ei {{ limit }} ili manje elemenata.", "validators", "sr_Latn");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Ova kolekcija treba da sadr\u017ei ta\u010dno {{ limit }} element.|Ova kolekcija treba da sadr\u017ei ta\u010dno {{ limit }} elementa.|Ova kolekcija treba da sadr\u017ei ta\u010dno {{ limit }} elemenata.", "validators", "sr_Latn");
t.add("Invalid card number.", "Nevalidan broj kartice.", "validators", "sr_Latn");
t.add("Unsupported card type or invalid card number.", "Nevalidan broj kartice ili tip kartice nije podr\u017ean.", "validators", "sr_Latn");
t.add("This is not a valid International Bank Account Number (IBAN).", "Ovo nije validan me\u0111unarodni broj bankovnog ra\u010duna (IBAN).", "validators", "sr_Latn");
t.add("This value is not a valid ISBN-10.", "Ovo nije validan ISBN-10.", "validators", "sr_Latn");
t.add("This value is not a valid ISBN-13.", "Ovo nije validan ISBN-13.", "validators", "sr_Latn");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Ovo nije validan ISBN-10 ili ISBN-13.", "validators", "sr_Latn");
t.add("This value is not a valid ISSN.", "Ovo nije validan ISSN.", "validators", "sr_Latn");
t.add("This value is not a valid currency.", "Ovo nije validna valuta.", "validators", "sr_Latn");
t.add("This value should be equal to {{ compared_value }}.", "Ova vrednost treba da bude {{ compared_value }}.", "validators", "sr_Latn");
t.add("This value should be greater than {{ compared_value }}.", "Ova vrednost treba da bude ve\u0107a od {{ compared_value }}.", "validators", "sr_Latn");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Ova vrednost treba da bude ve\u0107a ili jednaka {{ compared_value }}.", "validators", "sr_Latn");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Ova vrednost treba da bude identi\u010dna sa {{ compared_value_type }} {{ compared_value }}.", "validators", "sr_Latn");
t.add("This value should be less than {{ compared_value }}.", "Ova vrednost treba da bude manja od {{ compared_value }}.", "validators", "sr_Latn");
t.add("This value should be less than or equal to {{ compared_value }}.", "Ova vrednost treba da bude manja ili jednaka {{ compared_value }}.", "validators", "sr_Latn");
t.add("This value should not be equal to {{ compared_value }}.", "Ova vrednost ne treba da bude jednaka {{ compared_value }}.", "validators", "sr_Latn");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Ova vrednost ne treba da bude identi\u010dna sa {{ compared_value_type }} {{ compared_value }}.", "validators", "sr_Latn");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "Razmera ove slike je prevelika ({{ ratio }}). Maksimalna dozvoljena razmera je {{ max_ratio }}.", "validators", "sr_Latn");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "Razmera ove slike je premala ({{ ratio }}). Minimalna o\u010dekivana razmera je {{ min_ratio }}.", "validators", "sr_Latn");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "Slika je kvadratna ({{ width }}x{{ height }}px). Kvadratne slike nisu dozvoljene.", "validators", "sr_Latn");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "Slika je orijentacije pejza\u017ea ({{ width }}x{{ height }}px). Pejza\u017ena orijentacija slika nije dozvoljena.", "validators", "sr_Latn");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "Slika je orijantacije portreta ({{ width }}x{{ height }}px). Portretna orijentacija slika nije dozvoljena.", "validators", "sr_Latn");
t.add("This form should not contain extra fields.", "Ovaj formular ne treba da sadr\u017ei dodatna polja.", "validators", "sr_Latn");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Otpremljena datoteka je bila prevelika. Molim poku\u0161ajte otpremanje manje datoteke.", "validators", "sr_Latn");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "CSRF vrednost je nevalidna. Poku\u0161ajte ponovo.", "validators", "sr_Latn");
})(Translator);
