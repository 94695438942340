(function (t) {
// vi
t.add("This value should be false.", "Gi\u00e1 tr\u1ecb n\u00e0y ph\u1ea3i l\u00e0 sai.", "validators", "vi");
t.add("This value should be true.", "Gi\u00e1 tr\u1ecb n\u00e0y ph\u1ea3i l\u00e0 \u0111\u00fang.", "validators", "vi");
t.add("This value should be of type {{ type }}.", "Gi\u00e1 tr\u1ecb n\u00e0y ph\u1ea3i l\u00e0 ki\u1ec3u  {{ type }}.", "validators", "vi");
t.add("This value should be blank.", "Gi\u00e1 tr\u1ecb n\u00e0y ph\u1ea3i r\u1ed7ng.", "validators", "vi");
t.add("The value you selected is not a valid choice.", "Gi\u00e1 tr\u1ecb b\u1ea1n v\u1eeba ch\u1ecdn kh\u00f4ng h\u1ee3p l\u1ec7.", "validators", "vi");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "B\u1ea1n ph\u1ea3i ch\u1ecdn \u00edt nh\u1ea5t {{ limit }} l\u1ef1a ch\u1ecdn.|B\u1ea1n ph\u1ea3i ch\u1ecdn \u00edt nh\u1ea5t {{ limit }} l\u1ef1a ch\u1ecdn.", "validators", "vi");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "B\u1ea1n ph\u1ea3i ch\u1ecdn nhi\u1ec1u nh\u1ea5t {{ limit }} l\u1ef1a ch\u1ecdn.|B\u1ea1n ph\u1ea3i ch\u1ecdn nhi\u1ec1u  nh\u1ea5t {{ limit }} l\u1ef1a ch\u1ecdn.", "validators", "vi");
t.add("One or more of the given values is invalid.", "M\u1ed9t ho\u1eb7c nhi\u1ec1u gi\u00e1 tr\u1ecb \u0111\u01b0\u1ee3c ch\u1ecdn kh\u00f4ng h\u1ee3p l\u1ec7.", "validators", "vi");
t.add("This field was not expected.", "L\u0129nh v\u1ef1c n\u00e0y kh\u00f4ng \u0111\u01b0\u1ee3c d\u1ef1 ki\u1ebfn.", "validators", "vi");
t.add("This field is missing.", "L\u0129nh v\u1ef1c n\u00e0y l\u00e0 m\u1ea5t t\u00edch.", "validators", "vi");
t.add("This value is not a valid date.", "Gi\u00e1 tr\u1ecb kh\u00f4ng ph\u1ea3i l\u00e0 ng\u00e0y h\u1ee3p l\u1ec7.", "validators", "vi");
t.add("This value is not a valid datetime.", "Gi\u00e1 tr\u1ecb kh\u00f4ng ph\u1ea3i l\u00e0 ng\u00e0y th\u00e1ng h\u1ee3p l\u1ec7.", "validators", "vi");
t.add("This value is not a valid email address.", "Gi\u00e1 tr\u1ecb n\u00e0y kh\u00f4ng ph\u1ea3i l\u00e0 email h\u1ee3p l\u1ec7.", "validators", "vi");
t.add("The file could not be found.", "T\u1eadp tin kh\u00f4ng t\u00ecm th\u1ea5y.", "validators", "vi");
t.add("The file is not readable.", "T\u1eadp tin kh\u00f4ng th\u1ec3 \u0111\u1ecdc \u0111\u01b0\u1ee3c.", "validators", "vi");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "T\u1eadp tin qu\u00e1 l\u1edbn ({{ size }} {{ suffix }}). K\u00edch th\u01b0\u1edbc t\u1ed1i \u0111a cho ph\u00e9p {{ limit }} {{ suffix }}.", "validators", "vi");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Ki\u1ec3u mime c\u1ee7a t\u1eadp tin kh\u00f4ng h\u1ee3p l\u1ec7 ({{ type }}). Ki\u1ec3u h\u1ee3p l\u1ec7 l\u00e0 {{ types }}.", "validators", "vi");
t.add("This value should be {{ limit }} or less.", "Gi\u00e1 tr\u1ecb ph\u1ea3i b\u1eb1ng ho\u1eb7c nh\u1ecf h\u01a1n {{ limit }}.", "validators", "vi");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Gi\u00e1 tr\u1ecb qu\u00e1 d\u00e0i. Ph\u1ea3i b\u1eb1ng ho\u1eb7c \u00edt h\u01a1n {{ limit }} k\u00ed t\u1ef1.|Gi\u00e1 tr\u1ecb qu\u00e1 d\u00e0i. Ph\u1ea3i b\u1eb1ng ho\u1eb7c \u00edt h\u01a1n {{ limit }} k\u00ed t\u1ef1.", "validators", "vi");
t.add("This value should be {{ limit }} or more.", "Gi\u00e1 tr\u1ecb ph\u1ea3i l\u1edbn h\u01a1n ho\u1eb7c b\u1eb1ng {{ limit }}.", "validators", "vi");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Gi\u00e1 tr\u1ecb qu\u00e1 ng\u1eafn. Ph\u1ea3i h\u01a1n ho\u1eb7c b\u1eb1ng {{ limit }} k\u00ed t\u1ef1.|Gi\u00e1 tr\u1ecb qu\u00e1 ng\u1eafn. Ph\u1ea3i h\u01a1n ho\u1eb7c b\u1eb1ng {{ limit }} k\u00ed t\u1ef1.", "validators", "vi");
t.add("This value should not be blank.", "Gi\u00e1 tr\u1ecb kh\u00f4ng \u0111\u01b0\u1ee3c ph\u00e9p \u0111\u1ec3 tr\u1ed1ng.", "validators", "vi");
t.add("This value should not be null.", "Gi\u00e1 tr\u1ecb kh\u00f4ng \u0111\u01b0\u1ee3c ph\u00e9p r\u1ed7ng.", "validators", "vi");
t.add("This value should be null.", "Gi\u00e1 tr\u1ecb ph\u1ea3i r\u1ed7ng.", "validators", "vi");
t.add("This value is not valid.", "Gi\u00e1 tr\u1ecb kh\u00f4ng h\u1ee3p l\u1ec7.", "validators", "vi");
t.add("This value is not a valid time.", "Gi\u00e1 tr\u1ecb kh\u00f4ng ph\u1ea3i l\u00e0 th\u1eddi gian h\u1ee3p l\u1ec7.", "validators", "vi");
t.add("This value is not a valid URL.", "Gi\u00e1 tr\u1ecb kh\u00f4ng ph\u1ea3i l\u00e0 \u0111\u1ecba ch\u1ec9 URL h\u1ee3p l\u1ec7.", "validators", "vi");
t.add("The two values should be equal.", "Hai gi\u00e1 tr\u1ecb ph\u1ea3i b\u1eb1ng nhau.", "validators", "vi");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "T\u1eadp tin qu\u00e1 l\u1edbn. K\u00edch th\u01b0\u1edbc t\u1ed1i \u0111a cho ph\u00e9p l\u00e0 {{ limit }} {{ suffix }}.", "validators", "vi");
t.add("The file is too large.", "T\u1eadp tin qu\u00e1 l\u1edbn.", "validators", "vi");
t.add("The file could not be uploaded.", "T\u1eadp tin kh\u00f4ng th\u1ec3 t\u1ea3i l\u00ean.", "validators", "vi");
t.add("This value should be a valid number.", "Gi\u00e1 tr\u1ecb ph\u1ea3i l\u00e0 con s\u1ed1.", "validators", "vi");
t.add("This file is not a valid image.", "T\u1eadp tin kh\u00f4ng ph\u1ea3i l\u00e0 h\u00ecnh \u1ea3nh.", "validators", "vi");
t.add("This is not a valid IP address.", "\u0110\u1ecba ch\u1ec9 IP kh\u00f4ng h\u1ee3p l\u1ec7.", "validators", "vi");
t.add("This value is not a valid language.", "Gi\u00e1 tr\u1ecb kh\u00f4ng ph\u1ea3i l\u00e0 ng\u00f4n ng\u1eef h\u1ee3p l\u1ec7.", "validators", "vi");
t.add("This value is not a valid locale.", "Gi\u00e1 tr\u1ecb kh\u00f4ng ph\u1ea3i l\u00e0 m\u1ed9t b\u1ea3n \u0111\u1ecba \u0111\u1ecba ph\u01b0\u01a1ng h\u1ee3p l\u1ec7.", "validators", "vi");
t.add("This value is not a valid country.", "Gi\u00e1 tr\u1ecb kh\u00f4ng ph\u1ea3i l\u00e0 n\u01b0\u1edbc h\u1ee3p l\u1ec7.", "validators", "vi");
t.add("This value is already used.", "Gi\u00e1 tr\u1ecb \u0111\u00e3 \u0111\u01b0\u1ee3c s\u1eed d\u1ee5ng.", "validators", "vi");
t.add("The size of the image could not be detected.", "K\u00edch th\u01b0\u1edbc c\u1ee7a h\u00ecnh \u1ea3nh kh\u00f4ng th\u1ec3 x\u00e1c \u0111\u1ecbnh.", "validators", "vi");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "Chi\u1ec1u r\u1ed9ng c\u1ee7a h\u00ecnh qu\u00e1 l\u1edbn ({{ width }}px). Chi\u1ec1u r\u1ed9ng t\u1ed1i \u0111a ph\u1ea3i l\u00e0 {{ max_width }}px.", "validators", "vi");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "Chi\u1ec1u r\u1ed9ng c\u1ee7a h\u00ecnh qu\u00e1 th\u1ea5p ({{ width }}px). Chi\u1ec1u r\u1ed9ng t\u1ed1i thi\u1ec3u ph\u1ea3i l\u00e0 {{ min_width }}px.", "validators", "vi");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "Chi\u1ec1u cao c\u1ee7a h\u00ecnh qu\u00e1 cao ({{ height }}px). Chi\u1ec1u cao t\u1ed1i \u0111a ph\u1ea3i l\u00e0 {{ max_height }}px.", "validators", "vi");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "Chi\u1ec1u cao c\u1ee7a h\u00ecnh qu\u00e1 th\u1ea5p ({{ height }}px). Chi\u1ec1u cao t\u1ed1i thi\u1ec3u ph\u1ea3i l\u00e0 {{ min_height }}px.", "validators", "vi");
t.add("This value should be the user's current password.", "Gi\u00e1 tr\u1ecb n\u00e0y ph\u1ea3i l\u00e0 m\u1eadt kh\u1ea9u hi\u1ec7n t\u1ea1i c\u1ee7a ng\u01b0\u1eddi d\u00f9ng.", "validators", "vi");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Gi\u00e1 tr\u1ecb ph\u1ea3i c\u00f3 ch\u00ednh x\u00e1c {{ limit }} k\u00ed t\u1ef1.|Gi\u00e1 tr\u1ecb ph\u1ea3i c\u00f3 ch\u00ednh x\u00e1c {{ limit }} k\u00ed t\u1ef1.", "validators", "vi");
t.add("The file was only partially uploaded.", "T\u1eadp tin ch\u1ec9 \u0111\u01b0\u1ee3c t\u1ea3i l\u00ean m\u1ed9t ph\u1ea7n.", "validators", "vi");
t.add("No file was uploaded.", "T\u1eadp tin kh\u00f4ng \u0111\u01b0\u1ee3c t\u1ea3i l\u00ean.", "validators", "vi");
t.add("No temporary folder was configured in php.ini.", "Th\u01b0 m\u1ee5c t\u1ea1m kh\u00f4ng \u0111\u01b0\u1ee3c \u0111\u1ecbnh ngh\u0129a trong php.ini.", "validators", "vi");
t.add("Cannot write temporary file to disk.", "Kh\u00f4ng th\u1ec3 ghi t\u1eadp tin t\u1ea1m ra \u0111\u0129a.", "validators", "vi");
t.add("A PHP extension caused the upload to fail.", "M\u1ed9t PHP extension \u0111\u00e3 ph\u00e1 h\u1ecfng qu\u00e1 tr\u00ecnh t\u1ea3i l\u00ean c\u1ee7a t\u1eadp tin.", "validators", "vi");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Danh s\u00e1ch ph\u1ea3i ch\u1ee9a {{ limit }} ho\u1eb7c nhi\u1ec1u h\u01a1n th\u00e0nh ph\u1ea7n.|Danh s\u00e1ch ph\u1ea3i ch\u1ee9a {{ limit }} ho\u1eb7c nhi\u1ec1u h\u01a1n th\u00e0nh ph\u1ea7n.", "validators", "vi");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Danh s\u00e1ch ph\u1ea3i ch\u1ee9a {{ limit }} ho\u1eb7c \u00edt h\u01a1n th\u00e0nh ph\u1ea7n.|Danh s\u00e1ch ph\u1ea3i ch\u1ee9a {{ limit }} ho\u1eb7c \u00edt h\u01a1n th\u00e0nh ph\u1ea7n.", "validators", "vi");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Danh s\u00e1ch ph\u1ea3i ch\u1ee9a ch\u00ednh x\u00e1c {{ limit }} th\u00e0nh ph\u1ea7n.|Danh s\u00e1ch ph\u1ea3i ch\u1ee9a ch\u00ednh x\u00e1c {{ limit }} th\u00e0nh ph\u1ea7n.", "validators", "vi");
t.add("Invalid card number.", "S\u1ed1 th\u1ebb kh\u00f4ng h\u1ee3p l\u1ec7.", "validators", "vi");
t.add("Unsupported card type or invalid card number.", "Th\u1ebb kh\u00f4ng \u0111\u01b0\u1ee3c h\u1ed7 tr\u1ee3 ho\u1eb7c s\u1ed1 th\u1ebb kh\u00f4ng h\u1ee3p l\u1ec7.", "validators", "vi");
t.add("This is not a valid International Bank Account Number (IBAN).", "Gi\u00e1 tr\u1ecb kh\u00f4ng ph\u1ea3i l\u00e0 International Bank Account Number (IBAN) h\u1ee3p l\u1ec7.", "validators", "vi");
t.add("This value is not a valid ISBN-10.", "Gi\u00e1 tr\u1ecb kh\u00f4ng ph\u1ea3i l\u00e0 ISBN-10 h\u1ee3p l\u1ec7.", "validators", "vi");
t.add("This value is not a valid ISBN-13.", "Gi\u00e1 tr\u1ecb kh\u00f4ng ph\u1ea3i l\u00e0 ISBN-13 h\u1ee3p l\u1ec7.", "validators", "vi");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Gi\u00e1 tr\u1ecb kh\u00f4ng ph\u1ea3i l\u00e0 ISBN-10 ho\u1eb7c ISBN-13 h\u1ee3p l\u1ec7.", "validators", "vi");
t.add("This value is not a valid ISSN.", "Gi\u00e1 tr\u1ecb kh\u00f4ng l\u00e0 ISSN h\u1ee3p l\u1ec7.", "validators", "vi");
t.add("This value is not a valid currency.", "Gi\u00e1 tr\u1ecb kh\u00f4ng ph\u1ea3i l\u00e0 \u0111\u01a1n vi ti\u1ec1n t\u1ec7 h\u1ee3p l\u1ec7.", "validators", "vi");
t.add("This value should be equal to {{ compared_value }}.", "Gi\u00e1 tr\u1ecb ph\u1ea3i b\u1eb1ng {{ compared_value }}.", "validators", "vi");
t.add("This value should be greater than {{ compared_value }}.", "Gi\u00e1 tr\u1ecb ph\u1ea3i l\u1edbn h\u01a1n {{ compared_value }}.", "validators", "vi");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Gi\u00e1 tr\u1ecb ph\u1ea3i l\u1edbn h\u01a1n ho\u1eb7c b\u1eb1ng {{ compared_value }}.", "validators", "vi");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Gi\u00e1 tr\u1ecb ph\u1ea3i gi\u1ed1ng  {{ compared_value_type }} {{ compared_value }}.", "validators", "vi");
t.add("This value should be less than {{ compared_value }}.", "Gi\u00e1 tr\u1ecb ph\u1ea3i b\u00e9 h\u01a1n {{ compared_value }}.", "validators", "vi");
t.add("This value should be less than or equal to {{ compared_value }}.", "Gi\u00e1 tr\u1ecb kh\u00f4ng \u0111\u01b0\u1ee3c ph\u00e9p nh\u1ecf h\u01a1n ho\u1eb7c b\u1eb1ng {{ compared_value }}.", "validators", "vi");
t.add("This value should not be equal to {{ compared_value }}.", "Gi\u00e1 tr\u1ecb kh\u00f4ng \u0111\u01b0\u1ee3c ph\u00e9p b\u1eb1ng {{ compared_value }}.", "validators", "vi");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Gi\u00e1 tr\u1ecb kh\u00f4ng \u0111\u01b0\u1ee3c ph\u00e9p gi\u1ed1ng nh\u01b0 {{ compared_value_type }} {{ compared_value }}.", "validators", "vi");
})(Translator);
