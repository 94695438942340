(function (t) {
// tr
t.add("This value should be false.", "Bu de\u011fer olumsuz olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value should be true.", "Bu de\u011fer olumlu olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value should be of type {{ type }}.", "Bu de\u011ferin tipi {{ type }} olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value should be blank.", "Bu de\u011fer bo\u015f olmal\u0131d\u0131r.", "validators", "tr");
t.add("The value you selected is not a valid choice.", "Se\u00e7ti\u011finiz de\u011fer ge\u00e7erli bir se\u00e7enek de\u011fil.", "validators", "tr");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "En az {{ limit }} se\u00e7enek belirtmelisiniz.", "validators", "tr");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "En \u00e7ok {{ limit }} se\u00e7enek belirtmelisiniz.", "validators", "tr");
t.add("One or more of the given values is invalid.", "Verilen de\u011ferlerden bir veya daha fazlas\u0131 ge\u00e7ersiz.", "validators", "tr");
t.add("This field was not expected.", "Bu alan beklenen olmad\u0131.", "validators", "tr");
t.add("This field is missing.", "Bu alan, eksik", "validators", "tr");
t.add("This value is not a valid date.", "Bu de\u011fer do\u011fru bir tarih bi\u00e7imi de\u011fildir.", "validators", "tr");
t.add("This value is not a valid datetime.", "Bu de\u011fer do\u011fru bir tarihsaat bi\u00e7imi de\u011fildir.", "validators", "tr");
t.add("This value is not a valid email address.", "Bu de\u011fer do\u011fru bir e-mail adresi de\u011fildir.", "validators", "tr");
t.add("The file could not be found.", "Dosya bulunamad\u0131.", "validators", "tr");
t.add("The file is not readable.", "Dosya okunabilir de\u011fil.", "validators", "tr");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Dosya \u00e7ok b\u00fcy\u00fck ({{ size }} {{ suffix }}). \u0130zin verilen en b\u00fcy\u00fck dosya boyutu {{ limit }} {{ suffix }}.", "validators", "tr");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Dosyan\u0131n mime tipi ge\u00e7ersiz ({{ type }}). \u0130zin verilen mime tipleri {{ types }}.", "validators", "tr");
t.add("This value should be {{ limit }} or less.", "Bu de\u011fer {{ limit }} ve alt\u0131nda olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Bu de\u011fer \u00e7ok uzun. {{ limit }} karakter veya daha az olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value should be {{ limit }} or more.", "Bu de\u011fer {{ limit }} veya daha fazla olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Bu de\u011fer \u00e7ok k\u0131sa. {{ limit }} karakter veya daha fazla olmald\u0131r.", "validators", "tr");
t.add("This value should not be blank.", "Bu de\u011fer bo\u015f b\u0131rak\u0131lmamal\u0131d\u0131r.", "validators", "tr");
t.add("This value should not be null.", "Bu de\u011fer bo\u015f b\u0131rak\u0131lmamal\u0131d\u0131r.", "validators", "tr");
t.add("This value should be null.", "Bu de\u011fer bo\u015f b\u0131rak\u0131lmal\u0131d\u0131r.", "validators", "tr");
t.add("This value is not valid.", "Bu de\u011fer ge\u00e7erli de\u011fil.", "validators", "tr");
t.add("This value is not a valid time.", "Bu de\u011fer do\u011fru bir saat de\u011fil.", "validators", "tr");
t.add("This value is not a valid URL.", "Bu de\u011fer do\u011fru bir URL de\u011fil.", "validators", "tr");
t.add("The two values should be equal.", "\u0130ki de\u011fer e\u015fit olmal\u0131d\u0131r.", "validators", "tr");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Dosya \u00e7ok b\u00fcy\u00fck. \u0130zin verilen en b\u00fcy\u00fck dosya boyutu {{ limit }} {{ suffix }}.", "validators", "tr");
t.add("The file is too large.", "Dosya \u00e7ok b\u00fcy\u00fck.", "validators", "tr");
t.add("The file could not be uploaded.", "Dosya y\u00fcklenemiyor.", "validators", "tr");
t.add("This value should be a valid number.", "Bu de\u011fer ge\u00e7erli bir rakam olmal\u0131d\u0131r.", "validators", "tr");
t.add("This file is not a valid image.", "Bu dosya ge\u00e7erli bir resim de\u011fildir.", "validators", "tr");
t.add("This is not a valid IP address.", "Bu ge\u00e7erli bir IP adresi de\u011fildir.", "validators", "tr");
t.add("This value is not a valid language.", "Bu de\u011fer ge\u00e7erli bir lisan de\u011fil.", "validators", "tr");
t.add("This value is not a valid locale.", "Bu de\u011fer ge\u00e7erli bir yer de\u011fildir.", "validators", "tr");
t.add("This value is not a valid country.", "Bu de\u011fer ge\u00e7erli bir \u00fclke de\u011fildir.", "validators", "tr");
t.add("This value is already used.", "Bu de\u011fer \u015fu anda kullan\u0131mda.", "validators", "tr");
t.add("The size of the image could not be detected.", "Resmin boyutu saptanam\u0131yor.", "validators", "tr");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "Resmin geni\u015fli\u011fi \u00e7ok b\u00fcy\u00fck ({{ width }}px). \u0130zin verilen en b\u00fcy\u00fck geni\u015flik {{ max_width }}px.", "validators", "tr");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "Resmin geni\u015fli\u011fi \u00e7ok k\u00fc\u00e7\u00fck ({{ width }}px). En az {{ min_width }}px olmal\u0131d\u0131r.", "validators", "tr");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "Resmin y\u00fcksekli\u011fi \u00e7ok b\u00fcy\u00fck ({{ height }}px). \u0130zin verilen en b\u00fcy\u00fck y\u00fckseklik {{ max_height }}px.", "validators", "tr");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "Resmin y\u00fcksekli\u011fi \u00e7ok k\u00fc\u00e7\u00fck ({{ height }}px). En az {{ min_height }}px olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value should be the user's current password.", "Bu de\u011fer kullan\u0131c\u0131n\u0131n \u015fu anki \u015fifresi olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Bu de\u011fer tam olarak {{ limit }} karakter olmald\u0131r.", "validators", "tr");
t.add("The file was only partially uploaded.", "Dosya sadece k\u0131smen y\u00fcklendi.", "validators", "tr");
t.add("No file was uploaded.", "Hi\u00e7bir dosya y\u00fcklenmedi.", "validators", "tr");
t.add("No temporary folder was configured in php.ini.", "php.ini i\u00e7erisinde ge\u00e7ici dizin tan\u0131mlanmad\u0131.", "validators", "tr");
t.add("Cannot write temporary file to disk.", "Ge\u00e7ici dosya diske yaz\u0131lam\u0131yor.", "validators", "tr");
t.add("A PHP extension caused the upload to fail.", "Bir PHP eklentisi dosyan\u0131n y\u00fcklemesini ba\u015far\u0131s\u0131z k\u0131ld\u0131.", "validators", "tr");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Bu derlem {{ limit }} veya daha \u00e7ok eleman i\u00e7ermelidir.", "validators", "tr");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Bu derlem {{ limit }} veya daha az eleman i\u00e7ermelidir.", "validators", "tr");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Bu derlem {{ limit }} eleman i\u00e7ermelidir.", "validators", "tr");
t.add("Invalid card number.", "Ge\u00e7ersiz kart numaras\u0131.", "validators", "tr");
t.add("Unsupported card type or invalid card number.", "Desteklenmeyen kart tipi veya ge\u00e7ersiz kart numaras\u0131.", "validators", "tr");
t.add("Error", "Hata", "validators", "tr");
})(Translator);
