(function (t) {
// pl
t.add("This value should be false.", "Ta warto\u015b\u0107 powinna by\u0107 fa\u0142szem.", "validators", "pl");
t.add("This value should be true.", "Ta warto\u015b\u0107 powinna by\u0107 prawd\u0105.", "validators", "pl");
t.add("This value should be of type {{ type }}.", "Ta warto\u015b\u0107 powinna by\u0107 typu {{ type }}.", "validators", "pl");
t.add("This value should be blank.", "Ta warto\u015b\u0107 powinna by\u0107 pusta.", "validators", "pl");
t.add("The value you selected is not a valid choice.", "Ta warto\u015b\u0107 powinna by\u0107 jedn\u0105 z podanych opcji.", "validators", "pl");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Powiniene\u015b wybra\u0107 co najmniej {{ limit }} opcj\u0119.|Powiniene\u015b wybra\u0107 co najmniej {{ limit }} opcje.|Powiniene\u015b wybra\u0107 co najmniej {{ limit }} opcji.", "validators", "pl");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Powiniene\u015b wybra\u0107 maksymalnie {{ limit }} opcj\u0119.|Powiniene\u015b wybra\u0107 maksymalnie {{ limit }} opcje.|Powiniene\u015b wybra\u0107 maksymalnie {{ limit }} opcji.", "validators", "pl");
t.add("One or more of the given values is invalid.", "Jedna lub wi\u0119cej z podanych warto\u015bci jest nieprawid\u0142owa.", "validators", "pl");
t.add("This field was not expected.", "Tego pola si\u0119 nie spodziewano.", "validators", "pl");
t.add("This field is missing.", "Tego pola brakuje.", "validators", "pl");
t.add("This value is not a valid date.", "Ta warto\u015b\u0107 nie jest prawid\u0142ow\u0105 dat\u0105.", "validators", "pl");
t.add("This value is not a valid datetime.", "Ta warto\u015b\u0107 nie jest prawid\u0142ow\u0105 dat\u0105 i czasem.", "validators", "pl");
t.add("This value is not a valid email address.", "Ta warto\u015b\u0107 nie jest prawid\u0142owym adresem email.", "validators", "pl");
t.add("The file could not be found.", "Plik nie m\u00f3g\u0142 zosta\u0107 odnaleziony.", "validators", "pl");
t.add("The file is not readable.", "Nie mo\u017cna odczyta\u0107 pliku.", "validators", "pl");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Plik jest za du\u017cy ({{ size }} {{ suffix }}). Maksymalny dozwolony rozmiar to {{ limit }} {{ suffix }}.", "validators", "pl");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Nieprawid\u0142owy typ mime pliku ({{ type }}). Dozwolone typy mime to {{ types }}.", "validators", "pl");
t.add("This value should be {{ limit }} or less.", "Ta warto\u015b\u0107 powinna wynosi\u0107 {{ limit }} lub mniej.", "validators", "pl");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Ta warto\u015b\u0107 jest zbyt d\u0142uga. Powinna mie\u0107 {{ limit }} lub mniej znak\u00f3w.|Ta warto\u015b\u0107 jest zbyt d\u0142uga. Powinna mie\u0107 {{ limit }} lub mniej znak\u00f3w.|Ta warto\u015b\u0107 jest zbyt d\u0142uga. Powinna mie\u0107 {{ limit }} lub mniej znak\u00f3w.", "validators", "pl");
t.add("This value should be {{ limit }} or more.", "Ta warto\u015b\u0107 powinna wynosi\u0107 {{ limit }} lub wi\u0119cej.", "validators", "pl");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Ta warto\u015b\u0107 jest zbyt kr\u00f3tka. Powinna mie\u0107 {{ limit }} lub wi\u0119cej znak\u00f3w.|Ta warto\u015b\u0107 jest zbyt kr\u00f3tka. Powinna mie\u0107 {{ limit }} lub wi\u0119cej znak\u00f3w.|Ta warto\u015b\u0107 jest zbyt kr\u00f3tka. Powinna mie\u0107 {{ limit }} lub wi\u0119cej znak\u00f3w.", "validators", "pl");
t.add("This value should not be blank.", "Ta warto\u015b\u0107 nie powinna by\u0107 pusta.", "validators", "pl");
t.add("This value should not be null.", "Ta warto\u015b\u0107 nie powinna by\u0107 nullem.", "validators", "pl");
t.add("This value should be null.", "Ta warto\u015b\u0107 powinna by\u0107 nullem.", "validators", "pl");
t.add("This value is not valid.", "Ta warto\u015b\u0107 jest nieprawid\u0142owa.", "validators", "pl");
t.add("This value is not a valid time.", "Ta warto\u015b\u0107 nie jest prawid\u0142owym czasem.", "validators", "pl");
t.add("This value is not a valid URL.", "Ta warto\u015b\u0107 nie jest prawid\u0142owym adresem URL.", "validators", "pl");
t.add("The two values should be equal.", "Obie warto\u015bci powinny by\u0107 r\u00f3wne.", "validators", "pl");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Plik jest za du\u017cy. Maksymalny dozwolony rozmiar to {{ limit }} {{ suffix }}.", "validators", "pl");
t.add("The file is too large.", "Plik jest za du\u017cy.", "validators", "pl");
t.add("The file could not be uploaded.", "Plik nie m\u00f3g\u0142 by\u0107 wgrany.", "validators", "pl");
t.add("This value should be a valid number.", "Ta warto\u015b\u0107 powinna by\u0107 prawid\u0142ow\u0105 liczb\u0105.", "validators", "pl");
t.add("This file is not a valid image.", "Ten plik nie jest obrazem.", "validators", "pl");
t.add("This is not a valid IP address.", "To nie jest prawid\u0142owy adres IP.", "validators", "pl");
t.add("This value is not a valid language.", "Ta warto\u015b\u0107 nie jest prawid\u0142owym j\u0119zykiem.", "validators", "pl");
t.add("This value is not a valid locale.", "Ta warto\u015b\u0107 nie jest prawid\u0142ow\u0105 lokalizacj\u0105.", "validators", "pl");
t.add("This value is not a valid country.", "Ta warto\u015b\u0107 nie jest prawid\u0142ow\u0105 nazw\u0105 kraju.", "validators", "pl");
t.add("This value is already used.", "Ta warto\u015b\u0107 jest ju\u017c wykorzystywana.", "validators", "pl");
t.add("The size of the image could not be detected.", "Nie mo\u017cna wykry\u0107 rozmiaru obrazka.", "validators", "pl");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "Szeroko\u015b\u0107 obrazka jest zbyt du\u017ca ({{ width }}px). Maksymalna dopuszczalna szeroko\u015b\u0107 to {{ max_width }}px.", "validators", "pl");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "Szeroko\u015b\u0107 obrazka jest zbyt ma\u0142a ({{ width }}px). Oczekiwana minimalna szeroko\u015b\u0107 to {{ min_width }}px.", "validators", "pl");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "Wysoko\u015b\u0107 obrazka jest zbyt du\u017ca ({{ height }}px). Maksymalna dopuszczalna wysoko\u015b\u0107 to {{ max_height }}px.", "validators", "pl");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "Wysoko\u015b\u0107 obrazka jest zbyt ma\u0142a ({{ height }}px). Oczekiwana minimalna wysoko\u015b\u0107 to {{ min_height }}px.", "validators", "pl");
t.add("This value should be the user's current password.", "Ta warto\u015b\u0107 powinna by\u0107 aktualnym has\u0142em u\u017cytkownika.", "validators", "pl");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Ta warto\u015b\u0107 powinna mie\u0107 dok\u0142adnie {{ limit }} znak.|Ta warto\u015b\u0107 powinna mie\u0107 dok\u0142adnie {{ limit }} znaki.|Ta warto\u015b\u0107 powinna mie\u0107 dok\u0142adnie {{ limit }} znak\u00f3w.", "validators", "pl");
t.add("The file was only partially uploaded.", "Plik zosta\u0142 wgrany tylko cz\u0119\u015bciowo.", "validators", "pl");
t.add("No file was uploaded.", "\u017baden plik nie zosta\u0142 wgrany.", "validators", "pl");
t.add("No temporary folder was configured in php.ini.", "Nie skonfigurowano folderu tymczasowego w php.ini, lub skonfigurowany folder nie istnieje.", "validators", "pl");
t.add("Cannot write temporary file to disk.", "Nie mo\u017cna zapisa\u0107 pliku tymczasowego na dysku.", "validators", "pl");
t.add("A PHP extension caused the upload to fail.", "Rozszerzenie PHP spowodowa\u0142o b\u0142\u0105d podczas wgrywania.", "validators", "pl");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Ten zbi\u00f3r powinien zawiera\u0107 {{ limit }} lub wi\u0119cej element\u00f3w.", "validators", "pl");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Ten zbi\u00f3r powinien zawiera\u0107 {{ limit }} lub mniej element\u00f3w.", "validators", "pl");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Ten zbi\u00f3r powinien zawiera\u0107 dok\u0142adnie {{ limit }} element.|Ten zbi\u00f3r powinien zawiera\u0107 dok\u0142adnie {{ limit }} elementy.|Ten zbi\u00f3r powinien zawiera\u0107 dok\u0142adnie {{ limit }} element\u00f3w.", "validators", "pl");
t.add("Invalid card number.", "Nieprawid\u0142owy numer karty.", "validators", "pl");
t.add("Unsupported card type or invalid card number.", "Nieobs\u0142ugiwany rodzaj karty lub nieprawid\u0142owy numer karty.", "validators", "pl");
t.add("This is not a valid International Bank Account Number (IBAN).", "Nieprawid\u0142owy mi\u0119dzynarodowy numer rachunku bankowego (IBAN).", "validators", "pl");
t.add("This value is not a valid ISBN-10.", "Ta warto\u015b\u0107 nie jest prawid\u0142owym numerem ISBN-10.", "validators", "pl");
t.add("This value is not a valid ISBN-13.", "Ta warto\u015b\u0107 nie jest prawid\u0142owym numerem ISBN-13.", "validators", "pl");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Ta warto\u015b\u0107 nie jest prawid\u0142owym numerem ISBN-10 ani ISBN-13.", "validators", "pl");
t.add("This value is not a valid ISSN.", "Ta warto\u015b\u0107 nie jest prawid\u0142owym numerem ISSN.", "validators", "pl");
t.add("This value is not a valid currency.", "Ta warto\u015b\u0107 nie jest prawid\u0142ow\u0105 walut\u0105.", "validators", "pl");
t.add("This value should be equal to {{ compared_value }}.", "Ta warto\u015b\u0107 powinna by\u0107 r\u00f3wna {{ compared_value }}.", "validators", "pl");
t.add("This value should be greater than {{ compared_value }}.", "Ta warto\u015b\u0107 powinna by\u0107 wi\u0119ksza ni\u017c {{ compared_value }}.", "validators", "pl");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Ta warto\u015b\u0107 powinna by\u0107 wi\u0119ksza b\u0105d\u017a r\u00f3wna {{ compared_value }}.", "validators", "pl");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Ta warto\u015b\u0107 powinna by\u0107 identycznego typu {{ compared_value_type }} oraz warto\u015bci {{ compared_value }}.", "validators", "pl");
t.add("This value should be less than {{ compared_value }}.", "Ta warto\u015b\u0107 powinna by\u0107 mniejsza ni\u017c {{ compared_value }}.", "validators", "pl");
t.add("This value should be less than or equal to {{ compared_value }}.", "Ta warto\u015b\u0107 powinna by\u0107 mniejsza b\u0105d\u017a r\u00f3wna {{ compared_value }}.", "validators", "pl");
t.add("This value should not be equal to {{ compared_value }}.", "Ta warto\u015b\u0107 nie powinna by\u0107 r\u00f3wna {{ compared_value }}.", "validators", "pl");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Ta warto\u015b\u0107 nie powinna by\u0107 identycznego typu {{ compared_value_type }} oraz warto\u015bci {{ compared_value }}.", "validators", "pl");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "Proporcje obrazu s\u0105 zbyt du\u017ce ({{ ratio }}). Maksymalne proporcje to {{ max_ratio }}.", "validators", "pl");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "Proporcje obrazu s\u0105 zbyt ma\u0142e ({{ ratio }}). Minimalne proporcje to {{ min_ratio }}.", "validators", "pl");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "Obraz jest kwadratem ({{ width }}x{{ height }}px). Kwadratowe obrazy nie s\u0105 akceptowane.", "validators", "pl");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "Obraz jest panoramiczny ({{ width }}x{{ height }}px). Panoramiczne zdj\u0119cia nie s\u0105 akceptowane.", "validators", "pl");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "Obraz jest portretowy ({{ width }}x{{ height }}px). Portretowe zdj\u0119cia nie s\u0105 akceptowane.", "validators", "pl");
t.add("An empty file is not allowed.", "Plik nie mo\u017ce by\u0107 pusty.", "validators", "pl");
t.add("The host could not be resolved.", "Nazwa hosta nie zosta\u0142a rozpoznana.", "validators", "pl");
t.add("This value does not match the expected {{ charset }} charset.", "Ta warto\u015b\u0107 nie pasuje do oczekiwanego zestawu znak\u00f3w {{ charset }}.", "validators", "pl");
t.add("This is not a valid Business Identifier Code (BIC).", "Ta warto\u015b\u0107 nie jest poprawnym kodem BIC (Business Identifier Code).", "validators", "pl");
t.add("Error", "B\u0142\u0105d", "validators", "pl");
t.add("This is not a valid UUID.", "To nie jest poprawne UUID.", "validators", "pl");
t.add("This value should be a multiple of {{ compared_value }}.", "Ta warto\u015b\u0107 powinna by\u0107 wielokrotno\u015bci\u0105 {{ compared_value }}.", "validators", "pl");
t.add("This form should not contain extra fields.", "Ten formularz nie powinien zawiera\u0107 dodatkowych p\u00f3l.", "validators", "pl");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Wgrany plik by\u0142 za du\u017cy. Prosz\u0119 spr\u00f3bowa\u0107 wgra\u0107 mniejszy plik.", "validators", "pl");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "Token CSRF jest nieprawid\u0142owy. Prosz\u0119 spr\u00f3bowa\u0107 wys\u0142a\u0107 formularz ponownie.", "validators", "pl");
})(Translator);
