(function (t) {
// az
t.add("This value should be false.", "Bu d\u0259y\u0259r false olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should be true.", "Bu d\u0259y\u0259r true olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should be of type {{ type }}.", "Bu d\u0259y\u0259rin tipi {{ type }} olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should be blank.", "Bu d\u0259y\u0259r bo\u015f olmal\u0131d\u0131r.", "validators", "az");
t.add("The value you selected is not a valid choice.", "Se\u00e7diyiniz d\u0259y\u0259r d\u00fczg\u00fcn bir se\u00e7im de\u011fil.", "validators", "az");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "\u018fn az {{ limit }} se\u00e7im qeyd edilm\u0259lidir.", "validators", "az");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "\u018fn \u00e7ox {{ limit }} se\u00e7im qeyd edilm\u0259lidir.", "validators", "az");
t.add("One or more of the given values is invalid.", "T\u0259qdim edil\u0259n d\u0259y\u0259rl\u0259rd\u0259n bir v\u0259 ya bir ne\u00e7\u0259si yanl\u0131\u015fd\u0131r.", "validators", "az");
t.add("This field was not expected.", "Bu sah\u0259 g\u00f6zl\u0259nilmirdi.", "validators", "az");
t.add("This field is missing.", "Bu sah\u0259 \u0259ksikdir.", "validators", "az");
t.add("This value is not a valid date.", "Bu d\u0259y\u0259r d\u00fczg\u00fcn bir tarix deyil.", "validators", "az");
t.add("This value is not a valid datetime.", "Bu d\u0259y\u0259r d\u00fczg\u00fcn bir tarixsaat deyil.", "validators", "az");
t.add("This value is not a valid email address.", "Bu d\u0259y\u0259r d\u00fczg\u00fcn bir e-po\u00e7t adresi deyil.", "validators", "az");
t.add("The file could not be found.", "Fayl tap\u0131lmad\u0131.", "validators", "az");
t.add("The file is not readable.", "Fayl oxunabil\u0259n deyil.", "validators", "az");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Fayl \u00e7ox b\u00f6y\u00fckd\u00fcr ({{ size }} {{ suffix }}). \u0130caz\u0259 veril\u0259n maksimum fayl \u00f6l\u00e7\u00fcs\u00fc {{ limit }} {{ suffix }}.", "validators", "az");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Fayl\u0131n mime tipi yanl\u0131\u015fdr ({{ type }}). \u0130caz\u0259 veril\u0259n mime tipl\u0259ri {{ types }}.", "validators", "az");
t.add("This value should be {{ limit }} or less.", "Bu d\u0259y\u0259r {{ limit }} v\u0259 ya alt\u0131nda olmal\u0131d\u0131r.", "validators", "az");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Bu d\u0259y\u0259r \u00e7ox uzundur. {{ limit }} v\u0259 ya daha az simvol olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should be {{ limit }} or more.", "Bu d\u0259y\u0259r {{ limit }} veya daha fazla olmal\u0131d\u0131r.", "validators", "az");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Bu d\u0259y\u0259r \u00e7ox q\u0131sad\u0131r. {{ limit }} v\u0259 ya daha \u00e7ox simvol olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should not be blank.", "Bu d\u0259y\u0259r bo\u015f olmamal\u0131d\u0131r.", "validators", "az");
t.add("This value should not be null.", "Bu d\u0259y\u0259r bo\u015f olmamal\u0131d\u0131r.", "validators", "az");
t.add("This value should be null.", "Bu d\u0259y\u0259r bo\u015f olmamal\u0131d\u0131r.", "validators", "az");
t.add("This value is not valid.", "Bu d\u0259y\u0259r do\u011fru deyil.", "validators", "az");
t.add("This value is not a valid time.", "Bu d\u0259y\u0259r do\u011fru bir saat deyil.", "validators", "az");
t.add("This value is not a valid URL.", "Bu d\u0259y\u0259r do\u011fru bir URL de\u011fil.", "validators", "az");
t.add("The two values should be equal.", "\u0130ki d\u0259y\u0259r eyni olmal\u0131d\u0131r.", "validators", "az");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Fayl \u00e7ox b\u00f6y\u00fckd\u00fcr. \u0130caz\u0259 veril\u0259n \u0259n b\u00f6y\u00fck fayl \u00f6l\u00e7\u00fcs\u00fc {{ limit }} {{ suffix }}.", "validators", "az");
t.add("The file is too large.", "Fayl \u00e7ox b\u00f6y\u00fckd\u00fcr.", "validators", "az");
t.add("The file could not be uploaded.", "Fayl y\u00fckl\u0259n\u0259bilmir.", "validators", "az");
t.add("This value should be a valid number.", "Bu d\u0259y\u0259r r\u0259q\u0259m olmal\u0131d\u0131r.", "validators", "az");
t.add("This file is not a valid image.", "Bu fayl d\u00fczg\u00fcn bir \u015f\u0259kil deyil.", "validators", "az");
t.add("This is not a valid IP address.", "Bu d\u00fczg\u00fcn bir IP adresi deyil.", "validators", "az");
t.add("This value is not a valid language.", "Bu d\u0259y\u0259r d\u00fczg\u00fcn bir dil deyil.", "validators", "az");
t.add("This value is not a valid locale.", "Bu d\u0259y\u0259r d\u00fczg\u00fcn bir dil deyil.", "validators", "az");
t.add("This value is not a valid country.", "Bu d\u0259y\u0259r d\u00fczg\u00fcn bir \u00f6lk\u0259 deyil.", "validators", "az");
t.add("This value is already used.", "Bu d\u0259y\u0259r hal-haz\u0131rda istifad\u0259d\u0259dir.", "validators", "az");
t.add("The size of the image could not be detected.", "\u015e\u0259klin \u00f6l\u00e7\u00fcs\u00fc hesablana bilmir.", "validators", "az");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "\u015e\u0259klin geni\u015fliyi \u00e7ox b\u00f6y\u00fckd\u00fcr ({{ width }}px). \u0130caz\u0259 veril\u0259n \u0259n b\u00f6y\u00fck geni\u015flik {{ max_width }}px.", "validators", "az");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "\u015e\u0259klin geni\u015fliyi \u00e7ox ki\u00e7ikdir ({{ width }}px). \u018fn az {{ min_width }}px olmal\u0131d\u0131r.", "validators", "az");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "\u015e\u0259klin y\u00fcks\u0259kliyi \u00e7ox b\u00f6y\u00fckd\u00fcr ({{ height }}px). \u0130caz\u0259 veril\u0259n \u0259n b\u00f6y\u00fck y\u00fcks\u0259klik {{ max_height }}px.", "validators", "az");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "\u015e\u0259klin y\u00fcks\u0259kliyi \u00e7ox ki\u00e7ikdir ({{ height }}px). \u018fn az {{ min_height }}px olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should be the user's current password.", "Bu d\u0259y\u0259r istifad\u0259\u00e7inin haz\u0131rk\u0131 parolu olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Bu d\u0259y\u0259r tam olaraq {{ limit }} simvol olmald\u0131r.", "validators", "az");
t.add("The file was only partially uploaded.", "Fayl qism\u0259n y\u00fckl\u0259ndi.", "validators", "az");
t.add("No file was uploaded.", "Fayl y\u00fckl\u0259nm\u0259di.", "validators", "az");
t.add("No temporary folder was configured in php.ini.", "php.ini'd\u0259 m\u00fcv\u0259qq\u0259ti qovluq qura\u015fd\u0131r\u0131lmay\u0131b.", "validators", "az");
t.add("Cannot write temporary file to disk.", "M\u00fcv\u0259qq\u0259ti fayl disk\u0259 yaz\u0131la bilmir.", "validators", "az");
t.add("A PHP extension caused the upload to fail.", "Bir PHP \u0259lav\u0259si fayl\u0131n y\u00fckl\u0259nm\u0259sin\u0259 mane oldu.", "validators", "az");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Bu kolleksiyada {{ limit }} v\u0259 ya daha \u00e7ox element olmal\u0131d\u0131r.", "validators", "az");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Bu kolleksiyada {{ limit }} v\u0259 ya daha az element olmal\u0131d\u0131r.", "validators", "az");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Bu kolleksiyada tam olaraq {{ limit }} element olmal\u0131d\u0131r.", "validators", "az");
t.add("Invalid card number.", "Yanl\u0131\u015f kart n\u00f6mr\u0259si.", "validators", "az");
t.add("Unsupported card type or invalid card number.", "D\u0259st\u0259kl\u0259nm\u0259y\u0259n kart tipi v\u0259 ya yanl\u0131\u015f kart n\u00f6mr\u0259si.", "validators", "az");
t.add("This form should not contain extra fields.", "Bu formada \u0259lav\u0259 sah\u0259 olmamal\u0131d\u0131r.", "validators", "az");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Y\u00fckl\u0259n\u0259n fayl \u00e7ox b\u00f6y\u00fckd\u00fcr. L\u00fctf\u0259n daha ki\u00e7ik fayl y\u00fckl\u0259yin.", "validators", "az");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "CSRF ni\u015fan\u0131 yanl\u0131\u015fd\u0131r. L\u00fctfen forman\u0131 yenid\u0259n g\u00f6nd\u0259rin.", "validators", "az");
})(Translator);
